import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { ConfigurationOverviewContainer } from './overview/ConfigurationOverviewContainer';
import { NotFound } from '../errorScreens';
import { GatewayCreateContainer } from './gateway/create/GatewayCreateContainer';

const Icon = Pages.DEPLOYMENTS.icon;

export const ConfigurationRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute element={<ConfigurationOverviewContainer />} />
        }
      />
      <Route
        path="/newGateway"
        element={<ProtectedRoute element={<GatewayCreateContainer />} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
