import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DeviceAttributeField } from './DeviceAttributeField';
import { FancyCard } from '../FancyCard';
import { DataTypes } from '../../__generated__/types';

const useStyles = makeStyles({
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  card: {
    height: '100%',
  },
});

export interface AttributeSet {
  id: string;
  name: string;
  attributes: Array<{
    id: string;
    dataType: DataTypes | null;
    name: string;
    required: boolean;
    value?: string;
    enabled?: boolean;
  }>;
}

type DeviceAttributesFormProps = {
  attributeSets: Array<AttributeSet>;
  onChange: (field: string, value: string | boolean) => void;
  loading?: boolean;
  touched: FormikTouched<{ attributeSets: AttributeSet[] }>;
  errors: FormikErrors<{ attributeSets: AttributeSet[] }>;
};

export const getDeviceAttributesFormSchema = (
  t: TFunction,
): Yup.SchemaOf<{ attributeSets: Array<AttributeSet> }> =>
  Yup.object().shape({
    attributeSets: Yup.array().of(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
        attributes: Yup.array().of(
          Yup.object({
            id: Yup.string().required(),
            dataType: Yup.mixed().required(),
            name: Yup.string().required(),
            required: Yup.bool().required(),
            value: Yup.string().when('enabled', {
              is: true,
              then: Yup.string().required(t('general:errors.required')),
            }),
            enabled: Yup.boolean(),
          }),
        ),
      }),
    ),
  });

export const DeviceAttributesForm: React.FC<DeviceAttributesFormProps> = ({
  attributeSets,
  onChange,
  loading,
  touched,
  errors,
}) => {
  const classes = useStyles();

  const setWithTouchedAndError = attributeSets.map(
    (attributeSet, setIndex) => ({
      ...attributeSet,
      attributes: attributeSet.attributes.map((attribute, attributeIndex) => {
        const errorContainer = errors.attributeSets?.[setIndex] as
          | { attributes: Array<{ value: string }> }
          | undefined;
        const errorValue = errorContainer?.attributes?.[attributeIndex]?.value;

        const touchedValue =
          touched.attributeSets?.[setIndex]?.attributes?.[attributeIndex]
            ?.value;

        return {
          ...attribute,
          fieldId: `attributeSets.${setIndex}.attributes.${attributeIndex}`,
          error: errorValue,
          touched: touchedValue,
        };
      }),
    }),
  );

  return (
    <Grid container spacing={3} item alignItems="stretch" direction="row">
      {setWithTouchedAndError.map((attributeSet) => (
        <Grid item xs={12} md={4} key={attributeSet.id}>
          <FancyCard
            title={attributeSet.name}
            loading={loading}
            className={classes.card}
          >
            <Grid container spacing={1} className={classes.cardContent}>
              {attributeSet.attributes.map((attribute) => (
                <Grid item xs={12} md={12} key={attribute.id}>
                  <DeviceAttributeField
                    {...attribute}
                    onChange={onChange}
                    loading={loading}
                  />
                </Grid>
              ))}
            </Grid>
          </FancyCard>
        </Grid>
      ))}
    </Grid>
  );
};
