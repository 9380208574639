import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    margin: '14px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    minHeight: '52px',
    alignItems: 'center',
  },
  actions: {},
  title: {},
});

interface ActionsBarProps {
  title?: string;
  className?: string;
}

export const ActionsBar: React.FC<ActionsBarProps> = ({
  title,
  className,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        {title && <Typography variant="h5">{title}</Typography>}
      </div>
      <div className={classes.actions}>{children}</div>
    </div>
  );
};
