import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { GatewayListContainer } from '../../../components/GatewayList';
import { ErrorAlert } from '../../../components/Alerts';
import { DeploymentCardContainer } from './DeploymentCardContainer';
import { WorkbenchCardContainer } from './WorkbenchCardContainer';
import { useGatewayStatus } from '../../../hooks/useGatewayStatus';

export const DeploymentsOverviewContainer: React.FC = () => {
  const { t } = useTranslation(['deployments', 'general']);
  const { setTitle, setIcon } = useHeader();

  const [gatewayId, setGatewayId] = useState<string>();
  const {
    details: gatewayDetails,
    loading: loadingGetGatewayDetails,
    error: errorGetGatewayDetails,
    refresh: refreshGetGatewayDetails,
  } = useGatewayStatus(gatewayId);

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.DEPLOYMENTS, t),
    location: Entities.DEPLOYMENTS.path,
  });
  useEffect(() => {
    setTitle({ main: t('deployments:overview.title') });
    setIcon(Pages.DEPLOYMENTS.icon);
  }, [setTitle, setIcon, t]);

  const handleSelectGateway = (id?: string) => {
    setGatewayId(id);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GatewayListContainer
            selectedId={gatewayId}
            onSelect={handleSelectGateway}
          />
        </Grid>
        <Grid item xs={12}>
          <DeploymentCardContainer
            loading={loadingGetGatewayDetails}
            gatewayStatus={gatewayDetails?.status}
            gatewayId={gatewayId}
            refreshGetGatewayDetails={refreshGetGatewayDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <WorkbenchCardContainer
            loading={loadingGetGatewayDetails}
            gatewayDetails={gatewayDetails}
            gatewayId={gatewayId}
            refreshGetGatewayDetails={refreshGetGatewayDetails}
          />
        </Grid>
      </Grid>

      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorGetGatewayDetails}
      />
    </>
  );
};
