import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FancyCard } from '../../../components/FancyCard';
import { translateScanStatus } from '../../deployments/common/statusTranslation';

const useStyles = makeStyles({
  card: {
    minHeight: 208,
  },
});

type ScanResultCardProps = {
  loading?: boolean;
  status?: 'started' | 'completed' | 'failed' | 'timedout';
  startedAt?: string;
  finishedAt?: string;
  numOfObjects?: number;
};

export const ScanResultCard: React.FC<ScanResultCardProps> = ({
  loading,
  status,
  startedAt,
  finishedAt,
  numOfObjects,
}) => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const classes = useStyles();

  const scanStartedAt = startedAt ? new Date(startedAt).toLocaleString() : '-';
  const scanFinishedAt = finishedAt
    ? new Date(finishedAt).toLocaleString()
    : '-';
  const scanNumOfObjects = !finishedAt ? '-' : numOfObjects;

  return (
    <FancyCard
      title={t('modeleditor:overview.scanResultCard.title')}
      className={classes.card}
      loading={loading}
    >
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              {t('modeleditor:overview.scanResultCard.status')}
            </TableCell>
            <TableCell align="right">
              {translateScanStatus(status, t)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('modeleditor:overview.scanResultCard.numOfObjects')}
            </TableCell>
            <TableCell align="right">{scanNumOfObjects}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('modeleditor:overview.scanResultCard.startedAt')}
            </TableCell>
            <TableCell align="right">{scanStartedAt}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t('modeleditor:overview.scanResultCard.finishedAt')}
            </TableCell>
            <TableCell align="right">{scanFinishedAt}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </FancyCard>
  );
};
