import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { getUserSettings } from '../api/user';

interface Settings {
  language?: string;
}

export const SettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<Settings>({});
  const { isAuthenticated } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (settings?.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings, i18n]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserSettings()
        .then((response) => setSettings(response?.data?.data || {}))
        .catch(() => setSettings({}));
    }
  }, [isAuthenticated]);

  return <>{children}</>;
};
