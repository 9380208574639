import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  GridRowModel,
  GridFooterContainer,
  GridToolbarFilterButton,
  GridFooter,
  GridFilterModel,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FancyCard } from '../../../components/FancyCard';
import { Color } from '../../../constants/Colors';
import { Capability } from './DeviceCapabilities';
import { DataGrid } from '../../../components/DataGrid';
import { AssignButton } from './AssignButton';
import { DeviceDropdown, ScanDataDevice } from './DeviceDropdown';
import { BACnetDevice } from '../../../api/bacnet/common';
import { byName } from '../../../utils/sort';

type Mapping = {
  bacnetDeviceId: string;
  bacnetObjectId: string;
  mappedCapabilityId: string;
};
type Mappings = Record<string, Mapping>;

const useStyles = makeStyles({
  card: {
    height: 440,
  },
  dataGrid: {
    '& .assigned': {
      backgroundColor: Color.assignedRow,
    },
    '& .current': {
      backgroundColor: Color.selectedRow,
    },
  },
});

const isMappableFilterModel = {
  items: [
    {
      columnField: 'isMappable',
      id: 2,
      operatorValue: 'is',
      value: 'true',
    },
  ],
};

type ScanData = {
  deviceIdentifier: string;
  deviceName?: string;
  objectTypeName?: string;
  objectIdentifier: string;
  objectName?: string;
  description?: string;
  unitName?: string;
  lowLimit?: string;
  highLimit?: string;
  units?: string;
  isMappable?: boolean;
};

export type BACnetTarget = {
  bacnetDeviceId: string;
  bacnetObjectId: string;
};

type Device = {
  id: string;
  capabilities: Capability[];
};
type ScanResultProps = {
  loading?: boolean;
  scanResult?: BACnetDevice[];
  filterCapability?: Capability;
  filterDevice?: Device;
  mappings: Mappings;
  onSelectScanDate?: (capability: Capability, target: BACnetTarget) => void;
  showDeviceFilter?: boolean;
};
export const ScanResult: React.FC<ScanResultProps> = ({
  loading,
  scanResult,
  filterCapability,
  filterDevice,
  mappings,
  onSelectScanDate,
  showDeviceFilter,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);
  const [activeFilterModel, setActiveFilterModel] = useState<GridFilterModel>(
    isMappableFilterModel,
  );
  const [scanData, setScanData] = useState<ScanData[]>([]);
  const [scanDataDevices, setScanDataDevices] = useState<ScanDataDevice[]>([]);
  const [filterDeviceIds, setFilterDeviceIds] = useState<string[]>([]);

  useEffect(() => {
    const baseItems = (activeFilterModel?.items || []).filter(
      (item) => item.id !== 1,
    );
    const filterModel = filterCapability?.unitName
      ? {
          items: [
            ...baseItems,
            {
              id: 1,
              columnField: 'unitName',
              operatorValue: 'contains',
              value: filterCapability.unitName,
            },
          ],
        }
      : { items: baseItems };
    setActiveFilterModel(filterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCapability]);

  useEffect(() => {
    const scanResultObjects = scanResult
      ? scanResult
          .map((d) => {
            return (d.object || []).map((o) => ({
              deviceIdentifier: d.objectIdentifier,
              deviceName: d.objectName,
              unitName: o['X-unit']?.name,
              objectTypeName: o['X-object-type'],
              isMappable: o['X-object-type-is-mappable'],
              ...o,
            }));
          })
          .flat()
      : [];
    const scanResultDevices = scanResult
      ? scanResult
          .map((d) => ({
            id: d.objectIdentifier,
            name: d.objectName,
          }))
          .sort(byName)
      : [];
    setScanDataDevices(scanResultDevices);
    setScanData(scanResultObjects);
  }, [scanResult]);

  const handleSelectCapability =
    (target: BACnetTarget) => (capability: Capability) => {
      onSelectScanDate && onSelectScanDate(capability, target);
    };

  const handleBACnetDeviceFilter = (deviceIds: string[]) => {
    setFilterDeviceIds(deviceIds);
  };
  const columns: GridColumns = [
    {
      field: 'assign',
      headerName: t('deployments:common.scanResult.headers.assign'),
      description: t('deployments:common.scanResult.headers.assignDescription'),
      renderCell: (params: GridRenderCellParams) => (
        <AssignButton
          assignableTo={params.row.assignableTo}
          assignedTo={params.row.assignedTo}
          onSelectCapability={params.row.onSelectCapability}
        />
      ),
      width: 50,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
    },
    {
      field: 'description',
      headerName: t('deployments:common.scanResult.headers.description'),
      description: t(
        'deployments:common.scanResult.headers.descriptionDescription',
      ),
      flex: 1,
    },
    {
      field: 'deviceIdentifier',
      headerName: t('deployments:common.scanResult.headers.deviceIdentifier'),
      description: t(
        'deployments:common.scanResult.headers.deviceIdentifierDescription',
      ),
      hide: true,
      flex: 1,
    },
    {
      field: 'deviceName',
      headerName: t('deployments:common.scanResult.headers.deviceName'),
      description: t(
        'deployments:common.scanResult.headers.deviceNameDescription',
      ),
      flex: 1,
    },
    {
      field: 'objectTypeName',
      headerName: t('deployments:common.scanResult.headers.objectTypeName'),
      description: t(
        'deployments:common.scanResult.headers.objectTypeNameDescription',
      ),
      flex: 1,
    },
    {
      field: 'objectName',
      headerName: t('deployments:common.scanResult.headers.objectName'),
      description: t(
        'deployments:common.scanResult.headers.objectNameDescription',
      ),
      flex: 1,
    },
    {
      field: 'objectIdentifier',
      headerName: t('deployments:common.scanResult.headers.objectIdentifier'),
      description: t(
        'deployments:common.scanResult.headers.objectIdentifierDescription',
      ),
      hide: true,
      flex: 1,
    },
    {
      field: 'presentValue',
      headerName: t('deployments:common.scanResult.headers.presentValue'),
      description: t(
        'deployments:common.scanResult.headers.presentValueDescription',
      ),
      align: 'right',
      flex: 1,
    },
    {
      field: 'unitName',
      headerName: t('deployments:common.scanResult.headers.unitName'),
      description: t(
        'deployments:common.scanResult.headers.unitNameDescription',
      ),
      flex: 1,
    },
    {
      field: 'lowLimit',
      headerName: t('deployments:common.scanResult.headers.lowLimit'),
      description: t(
        'deployments:common.scanResult.headers.lowLimitDescription',
      ),
      type: 'number',
      hide: true,
      flex: 1,
    },
    {
      field: 'highLimit',
      headerName: t('deployments:common.scanResult.headers.highLimit'),
      description: t(
        'deployments:common.scanResult.headers.highLimitDescription',
      ),
      type: 'number',
      hide: true,
      flex: 1,
    },
    {
      field: 'isMappable',
      headerName: t('deployments:common.scanResult.headers.isMappable'),
      description: t(
        'deployments:common.scanResult.headers.isMappableDescription',
      ),
      type: 'boolean',
      hide: true,
      flex: 1,
    },
  ];

  const activeMapping =
    filterCapability && filterDevice
      ? mappings[filterCapability.id + filterDevice.id]
      : undefined;

  const rows = useMemo(
    () =>
      scanData
        .filter(
          (device) =>
            !filterDeviceIds.length ||
            filterDeviceIds.includes(device.deviceIdentifier),
        )
        .map((el, id) => {
          const canEdit = !!onSelectScanDate;

          const capabilities = filterDevice?.capabilities || [];
          const unassignedCapabilities = capabilities.filter(
            (c) =>
              // eslint-disable-next-line no-unsafe-optional-chaining
              !mappings[c.id + filterDevice?.id] && c.unitName === el.unitName,
          );
          const assignedMapping = Object.values(mappings).find(
            (m) =>
              m &&
              m.bacnetDeviceId === el.deviceIdentifier &&
              m.bacnetObjectId === el.objectIdentifier,
          );
          const assignedCapability =
            assignedMapping &&
            capabilities.find(
              (c) => c.id === assignedMapping.mappedCapabilityId,
            );

          return {
            id,
            ...el,
            unitName: el.unitName || el.units || '-',
            current:
              activeMapping &&
              activeMapping.bacnetDeviceId === el.deviceIdentifier &&
              activeMapping.bacnetObjectId === el.objectIdentifier,
            assignedTo: assignedCapability,
            assignableTo: canEdit ? unassignedCapabilities : null,
            onSelectCapability: handleSelectCapability({
              bacnetDeviceId: el.deviceIdentifier,
              bacnetObjectId: el.objectIdentifier,
            }),
          } as GridRowModel;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeMapping, filterDevice, filterDeviceIds, mappings, scanData],
  );

  return (
    <FancyCard
      title={t('deployments:common.scanResult.title')}
      className={classes.card}
    >
      <DataGrid
        loading={loading}
        className={classes.dataGrid}
        height={360}
        filterModel={activeFilterModel}
        components={{
          Toolbar: () => (
            <GridFooterContainer>
              <GridToolbarFilterButton />
              {showDeviceFilter && (
                <DeviceDropdown
                  disabled={loading}
                  devices={scanDataDevices}
                  selectedDeviceIds={filterDeviceIds}
                  onChange={handleBACnetDeviceFilter}
                />
              )}
              <GridFooter />
            </GridFooterContainer>
          ),
        }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        onFilterModelChange={(model) => setActiveFilterModel(model)}
        getRowClassName={({ row: { current, assignedTo } }) =>
          classNames(assignedTo && 'assigned', current && 'current')
        }
        hideFooter
      />
    </FancyCard>
  );
};
