import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import {
  DeviceModelCreateType,
  useDeviceModelCreateGatewayAndDeviceTypesQuery,
  useDeviceModelCreateMutation,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import { DeviceModelForm, DeviceModelData } from './DeviceModelForm';

export const DeviceModelCreateContainer: React.FC = () => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeviceModelCreateGatewayAndDeviceTypesQuery({
    variables: { gatewayId },
  });

  const gateway = dataGateway?.device || undefined;
  const deviceTypes = dataGateway?.deviceTypes || [];
  const fieldDeviceTypeId = deviceTypes[0]?.id;
  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.MODELEDITOR,
            id: `edit/${gatewayId}`,
            name: gateway?.name,
          },
          {
            type: Entities.MODELEDITOR,
            id: '/new',
            name: t('modeleditor:deviceModel.create.breadcrumb'),
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('modeleditor:deviceModel.create.breadcrumb') });
    setLoading(loadingGateway);
  }, [loadingGateway, setLoading, setTitle, t]);

  const navigateBack = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };

  const [createDeviceModel, { loading: loadingCreate, error: errorCreate }] =
    useDeviceModelCreateMutation({ onCompleted: navigateBack });

  const handleCreate = async (inputData: DeviceModelData) => {
    const deviceModel: DeviceModelCreateType = {
      name: inputData.deviceModelName,
      deviceTypeId: inputData.deviceTypeId,
    };

    return createDeviceModel({
      variables: { deviceModel },
    });
  };

  const emptyDeviceModel = {
    id: '0',
    deviceModelName: '',
    deviceTypeId: fieldDeviceTypeId,
  };

  return (
    <>
      <DeviceModelForm
        loading={loadingGateway || loadingCreate}
        {...emptyDeviceModel}
        onSubmit={handleCreate}
        onDiscard={navigateBack}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorGateway || errorCreate}
      />
    </>
  );
};
