import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridFooter,
  GridFooterContainer,
  GridSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { DataGrid } from '../../../components/DataGrid';

const useStyles = makeStyles({
  tableContainer: {
    height: 270,
  },
  loading: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  banner: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

export enum Type {
  gateway = 'gateway',
  fieldDevice = 'field-device',
}
export type FieldDevice = {
  name: string;
  id: string;
  model: string;
  capabilities: Array<{
    id: string;
    name: string;
    unitSymbol: string;
  }>;
  parentDeviceName: string;
  fieldDeviceId: string;
  mappingCount?: number;
};
type MonitoringOverviewFieldDeviceListProps = {
  loading?: boolean;
  devices?: FieldDevice[];
  onSelect: (id?: FieldDevice) => void;
};

export const MonitoringOverviewFieldDeviceList: React.FC<
  MonitoringOverviewFieldDeviceListProps
> = ({ loading, devices, onSelect }) => {
  const { t } = useTranslation(['monitoring', 'general']);
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => {
    setSelectionModel([]);
  }, [devices]);

  const handleSelect = ([currentId]: GridSelectionModel) => {
    const [previousId] = selectionModel;
    const selectedId = previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    const selectedDevice = devices?.find((d) => d.id === selectedId);
    onSelect(selectedDevice);
    setSelectionModel(newSelection);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('monitoring:overview.fieldDeviceList.headers.name'),
      description: t(
        'monitoring:overview.fieldDeviceList.headers.nameDescription',
      ),
      flex: 1,
    },
    {
      field: 'model',
      headerName: t('monitoring:overview.fieldDeviceList.headers.deviceModel'),
      description: t(
        'monitoring:overview.fieldDeviceList.headers.deviceModelDescription',
      ),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('monitoring:overview.fieldDeviceList.headers.description'),
      description: t(
        'monitoring:overview.fieldDeviceList.headers.descriptionDescription',
      ),
      flex: 1,
    },
    {
      field: 'mappedCapabilities',
      headerName: t(
        'monitoring:overview.fieldDeviceList.headers.mappedCapabilities',
      ),
      description: t(
        'monitoring:overview.fieldDeviceList.headers.mappedCapabilitiesDescription',
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mappingCount ?? '-'}/${params.row.capabilities.length}`,
    },
  ];

  return (
    <FancyCard
      title={t('monitoring:overview.fieldDeviceList.title')}
      loading={loading}
    >
      <DataGrid
        loading={loading}
        height={270}
        components={{
          NoRowsOverlay: () =>
            devices ? (
              <EmptyBanner
                className={classes.banner}
                description={t('monitoring:overview.noDevices')}
              />
            ) : (
              <EmptyBanner
                className={classes.banner}
                description={t('monitoring:overview.noGateway')}
              />
            ),
          Toolbar: () => (
            <GridFooterContainer>
              <GridToolbarFilterButton />
              <GridFooter />
            </GridFooterContainer>
          ),
        }}
        rows={devices || []}
        columns={columns}
        onSelectionModelChange={handleSelect}
        selectionModel={selectionModel}
        disableMultipleSelection
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
