import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { translateScanStatus } from './statusTranslation';

const useStyles = makeStyles({
  tooltipContent: {
    padding: 0,
    fontSize: '14px',
    lineHeight: '16px',
  },
  tooltipItem: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
});

export type ObjectScansStats = Array<{
  status: 'started' | 'completed' | 'failed' | 'timedout';
  numOfObjects?: number;
}>;
export type ObjectScanStatsTableProps = {
  objectScans?: ObjectScansStats;
};

export const ObjectScanStatsTable: React.FC<ObjectScanStatsTableProps> = ({
  objectScans,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const classes = useStyles();

  const objectScanResult = (objectScans || []).reduce(
    (result, scan) => ({
      status: {
        started: result.status.started + (scan.status === 'started' ? 1 : 0),
        completed:
          result.status.completed + (scan.status === 'completed' ? 1 : 0),
        failed: result.status.failed + (scan.status === 'failed' ? 1 : 0),
        timedout: result.status.timedout + (scan.status === 'timedout' ? 1 : 0),
      },
      numOfObjects: result.numOfObjects + (scan.numOfObjects || 0),
    }),
    {
      status: {
        started: 0,
        completed: 0,
        failed: 0,
        timedout: 0,
      },
      numOfObjects: 0,
    },
  );
  const { status, numOfObjects } = objectScanResult;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {t('deployments:common.objectScanStatsTable.header')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {t('deployments:common.objectScanStatsTable.status')}
          </TableCell>
          <TableCell align="right">
            {objectScans ? (
              <Tooltip
                arrow
                title={
                  <div className={classes.tooltipContent}>
                    {Object.keys(status).map((s) => (
                      <div key={s} className={classes.row}>
                        <div className={classes.tooltipItem}>
                          {translateScanStatus(s as keyof typeof status, t)}:
                        </div>
                        <div className={classes.tooltipItem}>
                          {status[s as keyof typeof status]}
                        </div>
                      </div>
                    ))}
                  </div>
                }
                placement="top"
              >
                <div>{`${status.started}/${status.completed}/${status.failed}/${status.timedout}`}</div>
              </Tooltip>
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {t('deployments:common.objectScanStatsTable.numOfObjects')}
          </TableCell>
          <TableCell align="right">
            {objectScans ? numOfObjects : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
