import React, { MouseEventHandler } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import classNames from 'classnames';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  image: {
    flex: '0 0 auto',
    width: 200,
    height: 'calc(100% - 20px)',
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
  content: {
    flex: '1 1 auto',
    marginLeft: 10,
    marginRight: 10,
  },
  headerRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 64,
  },
  headerAction: {
    alignSelf: 'center',
    marginTop: 0,
  },
  loader: {
    marginRight: 10,
    display: 'block',
  },
  fullHeight: {
    height: '100%',
  },
});

interface FancyCardPropsWithHandlers extends FancyCardProps {
  onEdit?: MouseEventHandler<HTMLButtonElement>;
}

interface FancyCardPropsWithActions extends FancyCardProps {
  actions: React.ReactNode;
}

interface FancyCardProps {
  className?: string;
  image?: { url: string; name: string };
  title: React.ReactNode;
  loading?: boolean;
  fullHeight?: boolean;
}

const isWithHandlers = (
  props: FancyCardPropsWithActions | FancyCardPropsWithHandlers,
): props is FancyCardPropsWithHandlers => {
  return (props as FancyCardPropsWithActions).actions === undefined;
};

const isWithActions = (
  props: FancyCardPropsWithActions | FancyCardPropsWithHandlers,
): props is FancyCardPropsWithActions => {
  return (props as FancyCardPropsWithActions).actions !== undefined;
};

export const FancyCard: React.FC<
  FancyCardPropsWithActions | FancyCardPropsWithHandlers
> = (props) => {
  const classes = useStyles();
  const { children, className, image, title, loading, fullHeight } = props;

  const renderActions = () => {
    if (isWithActions(props)) {
      const { actions } = props;
      return actions;
    }
    if (isWithHandlers(props)) {
      const { onEdit } = props;
      return (
        <>
          {onEdit && (
            <IconButton
              aria-label="edit"
              data-testid="card-edit"
              onClick={onEdit}
              size="medium"
              color="primary"
            >
              <Icons.Edit fontSize="small" />
            </IconButton>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <Card
      className={classNames(
        classes.root,
        fullHeight && classes.fullHeight,
        className,
      )}
    >
      {image && (
        <CardMedia
          className={classes.image}
          image={image.url}
          title={image.name}
        />
      )}
      <div className={classes.content}>
        <CardHeader
          title={title}
          classes={{
            root: classes.headerRoot,
            action: classes.headerAction,
          }}
          action={
            loading ? (
              <CircularProgress
                className={classes.loader}
                size="20px"
                color="primary"
              />
            ) : (
              renderActions()
            )
          }
        />
        <Divider />
        {children}
      </div>
    </Card>
  );
};
