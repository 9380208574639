import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridEnrichedColDef,
  GridFooter,
  GridFooterContainer,
  GridSelectionModel,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { DataGrid } from '../../../components/DataGrid';

export enum Type {
  gateway = 'gateway',
  fieldDevice = 'field-device',
}
export type DeviceModel = {
  id: string;
  name: string;
  isGlobal: boolean;
  fieldDeviceIdFieldSelector: string;
  capabilities: Array<{
    id: string;
    name: string;
    fieldSelector: string;
    technicalMin?: number;
    technicalMax?: number;
    unitName: string;
    unitSymbol: string;
  }>;
};
type ModelEditorDeviceModelsListProps = {
  loading?: boolean;
  deviceModels: DeviceModel[];
  onSelect: (model?: DeviceModel) => void;
  onAdd: () => void;
};

const columnDetails: Partial<GridEnrichedColDef> = {
  flex: 1,
};

export const ModelEditorDeviceModelsList: React.FC<
  ModelEditorDeviceModelsListProps
> = ({ loading, deviceModels, onSelect, onAdd }) => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelect = ([currentId]: GridSelectionModel) => {
    const [previousId] = selectionModel;
    const selectedId = previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    const selectedModel = deviceModels.find((d) => d.id === selectedId);
    onSelect(selectedModel);
    setSelectionModel(newSelection);
  };

  const handleAdd = () => {
    onAdd();
    setSelectionModel(['-1']);
  };
  const columns = [
    {
      field: 'name',
      headerName: t('modeleditor:edit.deviceModelsList.headers.name'),
      ...columnDetails,
    },

    {
      field: 'capabilitiesCount',
      headerName: t(
        'modeleditor:edit.deviceModelsList.headers.capabilitiesCount',
      ),
      ...columnDetails,
    },
  ];

  return (
    <FancyCard
      title={t('modeleditor:edit.deviceModelsList.title')}
      loading={loading}
      actions={
        <IconButton
          aria-label="assign-button"
          data-testid="assign-button"
          onClick={handleAdd}
        >
          <Icons.Add fontSize="medium" color="primary" />
        </IconButton>
      }
    >
      <DataGrid
        loading={loading}
        components={{
          Toolbar: () => (
            <GridFooterContainer>
              <GridToolbarFilterButton />
              <GridFooter />
            </GridFooterContainer>
          ),
        }}
        rows={deviceModels}
        columns={columns}
        onSelectionModelChange={handleSelect}
        selectionModel={selectionModel}
        disableMultipleSelection
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
