import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Grid } from '@mui/material';
import slugify from 'react-slugify';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import { ErrorMessage } from '../../../components/Errors';
import {
  DataTypes,
  useCapabilitiesAndUnitsQuery,
  useDeviceModelCapabilityCreateMutation,
  useDeviceModelCapabilityDeleteMutation,
  useDeviceModelDetailsLazyQuery,
  useDeviceModelsListQuery,
  useModelEditorGatewayDetailsQuery,
} from '../../../__generated__/types';
import {
  Capability as ModelCapability,
  ModelEditorEditModelCapabilities,
} from './ModelEditorEditModelCapabilities';
import { Capability, ModelEditorScan, ScanData } from './ModelEditorScan';
import { useRequest } from '../../../hooks/useRequest';
import { getLatestObjectScan } from '../../../api/bacnet';
import { NA_UNIT_NAME } from '../../../constants/Misc';
import {
  ModelEditorDeviceModelsList,
  DeviceModel,
} from './ModelEditorDeviceModelsList';
import { BACnetDevice } from '../../../api/bacnet/common';

type ModelCapabilityType = {
  id: string;
  capability: {
    name: string;
  };
  fieldSelector?: string | null;
  technicalMin?: number | null;
  technicalMax?: number | null;
  unit?: { name: string; unitSymbol?: string | null };
};
const mapCapability = (capability: ModelCapabilityType) => ({
  id: capability?.id || '-1',
  name: capability?.capability?.name || '',
  fieldSelector: capability?.fieldSelector || '',
  technicalMin: capability?.technicalMin ?? undefined,
  technicalMax: capability?.technicalMax ?? undefined,
  unitName: capability?.unit?.name || '',
  unitSymbol: capability?.unit?.unitSymbol || '',
});

export const ModelEditorEditContainer: React.FC = () => {
  const { t } = useTranslation(['modeleditor', 'general', 'errors']);
  const navigate = useNavigate();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();

  const [selectedCapability, setSelectedCapability] =
    useState<ModelCapability>();
  const [selectedModel, setSelectedModel] = useState<DeviceModel>();
  const [scanData, setScanData] = useState<ScanData[]>([]);
  const [deviceModels, setDeviceModels] = useState<DeviceModel[]>([]);

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useModelEditorGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.MODELEDITOR,
            id: gateway.id,
            name: gateway?.name,
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('modeleditor:edit.title'),
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t]);

  const {
    loading: loadingDeviceModelList,
    error: errorDeviceModelList,
    data: dataDeviceModelList,
  } = useDeviceModelsListQuery();
  useEffect(() => {
    const models = (dataDeviceModelList?.deviceModels || [])
      .map((model) => ({
        id: model?.id || '-1',
        name: model?.name || '-',
        isGlobal: model?.isGlobal ?? false,
        fieldDeviceIdFieldSelector: model?.fieldDeviceIdFieldSelector || '',
        capabilities: (model?.deviceModelCapabilities || [])
          .map(mapCapability)
          .filter((capability) => capability.id !== '-1'),
        capabilitiesCount: (model?.deviceModelCapabilities || []).length,
      }))
      // In case `device` from the query is undefined or null
      .filter((device) => device.id !== '-1');
    setDeviceModels(models);
  }, [setDeviceModels, dataDeviceModelList]);

  const [
    callDeviceModelDetails,
    {
      loading: loadingDeviceModelDetails,
      error: errorDeviceModelDetails,
      data: dataDeviceModelDetails,
    },
  ] = useDeviceModelDetailsLazyQuery();
  useEffect(() => {
    const dm = dataDeviceModelDetails?.deviceModel;
    if (!dm) return;
    const deviceModel = {
      id: dm.id || '-1',
      name: dm.name || '-',
      isGlobal: dm.isGlobal ?? false,
      fieldDeviceIdFieldSelector: dm.fieldDeviceIdFieldSelector || '',
      capabilities: (dm.deviceModelCapabilities || [])
        .map(mapCapability)
        .filter((capability) => capability.id !== '-1'),
      capabilitiesCount: (dm.deviceModelCapabilities || []).length,
    };

    const index = deviceModels.findIndex((m) => m.id === deviceModel.id);
    const newDeviceModels = [...deviceModels];
    newDeviceModels[index] = deviceModel;
    setDeviceModels(newDeviceModels);
    if (selectedModel?.id === deviceModel.id) {
      setSelectedModel(deviceModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDeviceModelDetails]);

  const handleAddModel = () => {
    navigate(`/model-editor/edit/${gatewayId}/new`);
  };

  const {
    loading: loadingCapabilities,
    error: errorCapabilities,
    data: dataCapabilities,
  } = useCapabilitiesAndUnitsQuery();
  const allCapabilities = (dataCapabilities?.capabilities || []).map((c) => ({
    ...c,
    id: c.id,
    description: c.description || undefined,
  }));
  const allUnits = dataCapabilities?.units || [];

  const {
    response: responseScanResultDetails,
    loading: loadingScanResultDetails,
    error: errorScanResultDetails,
  } = useRequest(getLatestObjectScan, gatewayId);

  useEffect(() => {
    const rawScanResult = responseScanResultDetails?.data.reduce(
      (combinedResult, data) => [
        ...combinedResult,
        ...data.scanResult.unit.device,
      ],
      [] as BACnetDevice[],
    );
    const mappedScanResult = rawScanResult
      ? rawScanResult
          .map((d) => {
            return (d.object || []).map((o) => ({
              deviceIdentifier: d.objectIdentifier,
              deviceName: d.objectName,
              unitName: o['X-unit']?.name,
              objectTypeName: o['X-object-type'],
              isMappable: o['X-object-type-is-mappable'],
              ...o,
            }));
          })
          .flat()
      : [];
    setScanData(mappedScanResult);
  }, [responseScanResultDetails]);

  const handleSelectModel = (model?: DeviceModel) => {
    setSelectedCapability(undefined);
    setSelectedModel(model);
  };

  const handleSelectCapability = (capability?: ModelCapability) => {
    setSelectedCapability(capability);
  };

  const [
    deleteDeviceModelCapability,
    { loading: loadingDeleteCapbility, error: errorDeleteCapbility },
  ] = useDeviceModelCapabilityDeleteMutation();

  const handleDeleteCapability = (capability: ModelCapability) => {
    deleteDeviceModelCapability({
      variables: { deviceModelCapabilityId: capability.id },
    })
      .then(() => {
        setSelectedCapability(undefined);
        if (selectedModel?.id) {
          callDeviceModelDetails({
            variables: { deviceModelId: selectedModel?.id },
          });
        }
        return null;
      })
      .catch(console.warn);
  };

  const [
    createDeviceModelCapability,
    { loading: loadingCreate, error: errorCreate },
  ] = useDeviceModelCapabilityCreateMutation();

  const handleSelectScanDate = (
    capability: Capability,
    selectedData: ScanData,
  ) => {
    if (!selectedModel) return null;
    const { unitName, highLimit, lowLimit } = selectedData;
    const isBoolean = capability?.dataType === DataTypes.Bool;
    const notApplicableUnit = allUnits.find(
      ({ name }) => name === NA_UNIT_NAME,
    );
    const selectedUnit = allUnits.find(({ name }) => name === unitName);
    const unitId =
      (isBoolean ? notApplicableUnit?.id : selectedUnit?.id) ||
      notApplicableUnit?.id;

    if (!unitId) {
      console.error(`No unitId found for ${selectedUnit?.id}`);
      return null;
    }

    const { capabilities } = selectedModel;
    let fieldSelector = slugify(capability.name);
    let i = 0;
    const findSelector = (fs: string) => (c: { fieldSelector: string }) =>
      c.fieldSelector === fs;
    while (capabilities.find(findSelector(fieldSelector))) {
      i += 1;
      fieldSelector = slugify(`${capability.name}-${i}`);
    }

    return createDeviceModelCapability({
      variables: {
        deviceModelCapabilityInput: {
          capabilityId: capability.id,
          deviceModelId: selectedModel?.id,
          fieldSelector,
          technicalMax: isBoolean ? undefined : Number(highLimit),
          technicalMin: isBoolean ? undefined : Number(lowLimit),
          unitId,
        },
      },
    }).then(() =>
      callDeviceModelDetails({
        variables: { deviceModelId: selectedModel?.id },
      }),
    );
  };

  const editableModel = selectedModel && !selectedModel.isGlobal;

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return (
      <ErrorMessage
        error={errorGateway}
        message={t('errors:notFound.gateway')}
      />
    );
  }
  if (errorScanResultDetails) {
    return (
      <ErrorMessage
        error={errorScanResultDetails}
        message={t('errors:notFound.scanResult')}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ModelEditorDeviceModelsList
            onSelect={handleSelectModel}
            onAdd={handleAddModel}
            loading={loadingDeviceModelList || loadingDeviceModelDetails}
            deviceModels={deviceModels}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ModelEditorEditModelCapabilities
            loading={loadingGateway || loadingCreate || loadingDeleteCapbility}
            capabilities={selectedModel?.capabilities}
            selectedCapabilityId={selectedCapability?.id}
            onSelectCapability={handleSelectCapability}
            onDeleteCapability={
              editableModel ? handleDeleteCapability : undefined
            }
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <ModelEditorScan
            loading={
              loadingGateway ||
              loadingCapabilities ||
              loadingScanResultDetails ||
              loadingCreate
            }
            scanData={scanData}
            filterCapability={selectedCapability}
            allCapabilities={allCapabilities}
            onSelectScanDate={editableModel ? handleSelectScanDate : undefined}
          />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          errorCapabilities ||
          errorCreate ||
          errorDeviceModelList ||
          errorDeviceModelDetails ||
          errorDeleteCapbility
        }
      />
    </>
  );
};
