import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';

const useStyles = (height: number) =>
  makeStyles({
    tableContainer: {
      display: 'flex',
      height,
    },
    dataGrid: {
      border: 0,
      height,
    },
    hideCellFocus: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
        {
          outline: 'none',
        },
    },
    hideHeaderCheckbox: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
        {
          display: 'none',
        },
    },
  })();

type DataGridProps = DataGridProProps & {
  containerClassName?: string;
  hideHeaderCheckbox?: boolean;
  height?: number;
};

export const DataGrid: React.FC<DataGridProps> = ({
  containerClassName,
  hideHeaderCheckbox,
  columns: passedColumns,
  height = 270,
  className,
  ...rest
}) => {
  const classes = useStyles(height);
  const columns = React.useRef<GridColumns>(passedColumns);

  return (
    <div className={classNames(classes.tableContainer, containerClassName)}>
      <DataGridPro
        density={'compact'}
        {...rest}
        className={classNames(
          classes.dataGrid,
          classes.hideCellFocus,
          className,
          hideHeaderCheckbox && classes.hideHeaderCheckbox,
        )}
        columns={columns.current}
      />
    </div>
  );
};
