import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import classNames from 'classnames';
import { FancyCard } from '../../../components/FancyCard';
import { createRangeString } from '../../../utils/helpers';
import { Color } from '../../../constants/Colors';

const useStyles = makeStyles({
  card: {
    height: 440,
  },
  list: {
    height: 360,
    overflow: 'auto',
    fontSize: '14px',
  },
  empty: {
    padding: '16px',
  },
  active: {
    backgroundColor: Color.selectedRow,
  },
  assigned: {
    backgroundColor: Color.assignedRow,
  },
  tooltipContent: {
    padding: 0,
    fontSize: '14px',
    lineHeight: '16px',
  },
  tooltipItem: {
    padding: 4,
  },
  listButton: {
    margin: 2,
  },
  deleteButton: {
    padding: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
  actionIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export type Capability = {
  id: string;
  name: string;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
};

type InfoIconProps = { capability: Capability };
const InfoIcon: React.FC<InfoIconProps> = ({ capability }) => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div className={classes.tooltipContent}>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('modeleditor:edit.modelCapabilities.range')}:
            </div>
            <div className={classes.tooltipItem}>
              {createRangeString(capability)}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('modeleditor:edit.modelCapabilities.unit')}:
            </div>
            <div className={classes.tooltipItem}>{capability.unitName}</div>
          </div>
        </div>
      }
    >
      <Icons.Info fontSize="medium" color="action" />
    </Tooltip>
  );
};
type DeleteIconProps = { onDeleteCapability: () => void };
const DeleteIcon: React.FC<DeleteIconProps> = ({ onDeleteCapability }) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={onDeleteCapability}
      size="large"
      className={classes.deleteButton}
    >
      <Icons.Delete fontSize="medium" color="action" />
    </IconButton>
  );
};

type ModelEditorEditModelCapabilitiesProps = {
  loading: boolean;
  capabilities?: Capability[];
  selectedCapabilityId?: string;
  onSelectCapability: (capability?: Capability) => void;
  onDeleteCapability?: (capability: Capability) => void;
};
export const ModelEditorEditModelCapabilities: React.FC<
  ModelEditorEditModelCapabilitiesProps
> = ({
  loading,
  capabilities,
  selectedCapabilityId,
  onSelectCapability,
  onDeleteCapability,
}) => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const classes = useStyles();

  const handleSelectCapability = (capability: Capability) => () => {
    const newSelectedCapability =
      capability.id === selectedCapabilityId ? undefined : capability;
    onSelectCapability(newSelectedCapability);
  };
  const handleDeleteCapability = (capability: Capability) => () =>
    onDeleteCapability && onDeleteCapability(capability);

  return (
    <FancyCard
      title={t('modeleditor:edit.modelCapabilities.title')}
      loading={loading}
      className={classes.card}
    >
      <List className={classes.list}>
        {capabilities?.length ? (
          capabilities.map((capability) => (
            <ListItemButton
              key={capability.id}
              className={classNames(
                classes.listButton,
                selectedCapabilityId === capability.id && classes.active,
              )}
              onClick={handleSelectCapability(capability)}
            >
              <ListItem className={classes.row}>
                {capability.name}
                <div className={classes.actionIcons}>
                  <InfoIcon capability={capability} />
                  {onDeleteCapability && (
                    <DeleteIcon
                      onDeleteCapability={handleDeleteCapability(capability)}
                    />
                  )}
                </div>
              </ListItem>
            </ListItemButton>
          ))
        ) : (
          <div className={classes.empty}>
            {t('modeleditor:edit.modelCapabilities.empty')}
          </div>
        )}
      </List>
    </FancyCard>
  );
};
