import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { ModelEditorOverviewContainer } from './overview/ModelEditorOverviewContainer';
import { ModelEditorEditContainer } from './edit/ModelEditorEditContainer';
import { DeviceModelCreateContainer } from './deviceModel/create/DeviceModelCreateContainer';
import { NotFound } from '../errorScreens';

const Icon = Pages.MODELEDITOR.icon;

export const ModelEditorRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<ModelEditorOverviewContainer />} />}
      />
      <Route
        path="/edit/:gatewayId"
        element={<ProtectedRoute element={<ModelEditorEditContainer />} />}
      />
      <Route
        path="/edit/:gatewayId/new"
        element={<ProtectedRoute element={<DeviceModelCreateContainer />} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
