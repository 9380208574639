import React from 'react';
import * as Icons from '@mui/icons-material';
import { Field, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FancyCard } from '../../../../components/FancyCard';
import { GatewayLocationField, Site } from './GatewayLocationField';
import { PlacementKind } from '../../../../__generated__/types';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  infoIcon: {
    marginRight: '8px',
    display: 'block',
  },
});

export type GatewayFormLocationData = {
  locationType?: PlacementKind;
  locationId: string;
};
export interface GatewayFormLocationProps {
  locationType?: PlacementKind;
  touched: FormikTouched<{ locationId: string }>;
  errors: FormikErrors<{ locationId: string }>;
  sites: Array<Site>;
  loading?: boolean;
  setFieldValue: FormikHelpers<GatewayFormLocationData>['setFieldValue'];
}

export const getGatewayFormLocationSchema = (
  t: TFunction,
): Yup.SchemaOf<GatewayFormLocationData> =>
  Yup.object().shape({
    locationId: Yup.string()
      .required(t('general:errors.required'))
      .notOneOf(['0'], t('general:errors.required')),
    locationType: Yup.mixed<PlacementKind>().oneOf(
      Object.values(PlacementKind),
    ),
  });

export const GatewayFormLocation: React.FC<GatewayFormLocationProps> =
  React.memo(
    ({ locationType, errors, touched, sites, loading, setFieldValue }) => {
      const classes = useStyles();
      const { t } = useTranslation(['configuration', 'general']);

      return (
        <FancyCard
          title={t('configuration:gateway.create.location.title')}
          className={classes.card}
          loading={loading}
          actions={
            <Tooltip
              arrow
              placement="top-end"
              title={
                <div className={classes.tooltipContent}>
                  {t('configuration:gateway.create.location.rules')}
                </div>
              }
              aria-label="non-editable-tooltip"
            >
              <Icons.Info
                fontSize="medium"
                className={classes.infoIcon}
                color="action"
                data-testid="card-info-icon"
              />
            </Tooltip>
          }
        >
          <Grid container spacing={1} className={classes.cardContent}>
            <Field
              loading={loading}
              id="locationId"
              name="locationId"
              type="select"
              as={GatewayLocationField}
              setFieldValue={setFieldValue}
              sites={sites}
              locationType={locationType}
              error={touched?.locationId && Boolean(errors?.locationId)}
              helperText={(touched?.locationId && errors?.locationId) || ' '}
            />
          </Grid>
        </FancyCard>
      );
    },
  );

GatewayFormLocation.displayName = 'GatewayFormLocation';
