import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@mui/material';
import { Color } from '../../../constants/Colors';
import { translateDeploymentStatus } from '../common/statusTranslation';
import { DeploymentStatus } from '../../../api/bacnet/common';

const useStyles = makeStyles({
  card: {
    width: 400,
    margin: 'auto',
    marginBottom: 16,
    marginTop: 16,
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: Color.error,
  },
});

export type DeploymentStatusCardProps = {
  deployedAt: string;
  status: DeploymentStatus;
};

export const DeploymentStatusCard: React.FC<DeploymentStatusCardProps> = ({
  deployedAt,
  status,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const classes = useStyles();

  const deploymentDeployedAt = deployedAt
    ? new Date(deployedAt).toLocaleString()
    : '-';

  return (
    <Card className={classes.card}>
      <CardContent classes={{ root: classes.cardContent }}>
        <div className={classes.row}>
          <div>{translateDeploymentStatus(status, t)}</div>
          <div>{deploymentDeployedAt}</div>
        </div>
      </CardContent>
    </Card>
  );
};
