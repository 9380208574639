import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
});

type Facts = {
  BACnetObjectCount?: number;
  total?: number;
  new?: number;
  changed?: number;
  removed?: number;
};
type MappingStatsCardProps = {
  loading?: boolean;
  facts?: Facts;
  onViewDetails?: () => void;
};

export const MappingStatsCard: React.FC<MappingStatsCardProps> = ({
  loading,
  facts = {},
  onViewDetails,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);

  const renderValue = (v?: number) => (v || v === 0 ? v : '-');
  return (
    <>
      <FancyCard
        className={classes.card}
        loading={loading}
        title={t('deployments:common.mappingStats.title')}
        actions={
          <IconButton
            aria-label="view-mapping-details-button"
            data-testid="view-mapping-details-button"
            onClick={onViewDetails}
            size="medium"
            color="primary"
          >
            <Tooltip
              arrow
              title={
                t('deployments:common.mappingStats.viewDetailsTooltip') || ''
              }
              placement="bottom"
            >
              <Icons.GridOn />
            </Tooltip>
          </IconButton>
        }
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                {t('deployments:common.mappingStats.facts.BACnetObjectCount')}
              </TableCell>
              <TableCell align="right">
                {renderValue(facts.BACnetObjectCount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.mappingStats.facts.total')}
              </TableCell>
              <TableCell align="right">{renderValue(facts.total)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.mappingStats.facts.new')}
              </TableCell>
              <TableCell align="right">{renderValue(facts.new)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.mappingStats.facts.changed')}
              </TableCell>
              <TableCell align="right">{renderValue(facts.changed)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.mappingStats.facts.removed')}
              </TableCell>
              <TableCell align="right">{renderValue(facts.removed)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </FancyCard>
    </>
  );
};
