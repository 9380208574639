import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  useTheme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  SIDEBAR_SECTIONS,
  SIDEBAR_OPENED_WIDTH,
  SIDEBAR_CLOSED_WIDTH,
} from '../../constants/UI';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  drawer: {
    flexShrink: 0,
  },
  drawerClosed: {},
  drawerPaper: {
    width: SIDEBAR_OPENED_WIDTH,
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    boxShadow: `1px 0 10px ${Color.sidebarShadow}`,
    borderRight: `1px solid ${Color.sidebarBorder}`,
  },
  drawerPaperClosed: {
    width: SIDEBAR_CLOSED_WIDTH - 1,
    overflow: 'hidden',
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    '& $separatorContainer': {
      opacity: 0,
      height: 0,
    },
    '& $drawerContainer': {
      overflow: 'hidden',
    },
  },
  drawerContainer: {
    overflow: 'auto',
    paddingTop: '24px',
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  sections: {
    flexGrow: 1,
  },
  listItem: {
    borderRadius: '0 8px 8px 0',
    width: SIDEBAR_OPENED_WIDTH - 10,
    margin: '3px 0',
    border: '1px solid transparent',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  listItemActive: {
    border: '1px solid',
    borderColor: Color.border,
  },
  listItemIcon: {
    paddingLeft: 8,
    marginRight: 4,
  },
  list: {
    padding: 0,
    overflow: 'hidden',
    minWidth: SIDEBAR_OPENED_WIDTH - 10,
  },
  separatorContainer: {
    height: '44px',
    overflow: 'hidden',
    transition: (theme: Theme) =>
      theme.transitions.create(['opacity', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  separator: {
    fontWeight: 600,
    color: Color.separator,
    padding: '24px 26px 8px 26px',
    lineHeight: '1',
    display: 'block',
  },
});

const SidebarLink = React.forwardRef<
  any,
  Omit<NavLinkProps, 'className'> & {
    activeClassName?: string;
    className?: string;
  }
>(({ className, activeClassName, ...props }, ref) => (
  <NavLink
    ref={ref}
    className={({ isActive }) =>
      classNames(isActive && activeClassName, className)
    }
    {...props}
  />
));

SidebarLink.displayName = 'SidebarLink';

interface SidebarProps {
  open?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { hasPermission } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const sections = useMemo(() => {
    return SIDEBAR_SECTIONS.map(({ items, ...rest }) => ({
      items: items.filter((item) =>
        item?.permission ? hasPermission(item.permission) : true,
      ),
      ...rest,
    }));
  }, [hasPermission]);

  const isActive = (paths?: string[]): boolean =>
    !!paths?.includes(location.pathname.split('/')[1]);

  return (
    <Drawer
      className={classNames(classes.drawer, !open && classes.drawerClosed)}
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClosed,
        ),
      }}
      open={open}
      anchor="left"
      data-testid="sidebar"
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <div className={classes.sections}>
          {sections.map(
            ({ id, label: sectionLabel, items }) =>
              items.length > 0 && (
                <div key={id}>
                  <div className={classes.separatorContainer}>
                    <Typography
                      variant="overline"
                      className={classes.separator}
                    >
                      {sectionLabel(t)}
                    </Typography>
                  </div>
                  <List className={classes.list}>
                    {items.map(({ label, icon: Icon, path, childPaths }) => (
                      <ListItem
                        button
                        key={label(t)}
                        component={SidebarLink}
                        to={path}
                        activeClassName={classNames([
                          path && ['Mui-selected', classes.listItemActive],
                          isActive(childPaths) && [
                            'Mui-selected',
                            classes.listItemActive,
                          ],
                        ])}
                        className={classes.listItem}
                        end={path === '/' && !isActive(childPaths)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <Icon />
                        </ListItemIcon>
                        <ListItemText primary={label(t)} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ),
          )}
        </div>
      </div>
    </Drawer>
  );
};
