import React from 'react';
import {
  Divider,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as Icons from '@mui/icons-material';
import { ConfirmAlert } from '../../../components/Alerts';
import { TextField } from '../../../components/Fields';

const useStyles = makeStyles({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

export type StartDeviceDiscoverySettings = {
  timeout: number;
  rangeStart: number;
  rangeEnd: number;
};

type ConfirmDeviceDiscoveryAlertProps = {
  onConfirm: (settings: StartDeviceDiscoverySettings) => void;
  onCancel: () => void;
  open: boolean;
};

export const ConfirmDeviceDiscoveryAlert: React.FC<
  ConfirmDeviceDiscoveryAlertProps
> = ({ onConfirm, onCancel, open }) => {
  const { t } = useTranslation(['deployments', 'general']);
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    timeout: Yup.number().required(t('general:errors.required')),
    rangeStart: Yup.number().required(t('general:errors.required')),
    rangeEnd: Yup.number().required(t('general:errors.required')),
  });

  return (
    <Formik
      initialValues={{
        timeout: 1000,
        rangeStart: 0,
        rangeEnd: 4194302,
      }}
      validationSchema={validationSchema}
      onSubmit={onConfirm}
    >
      {({ submitForm }: FormikProps<StartDeviceDiscoverySettings>) => {
        return (
          <ConfirmAlert
            open={open}
            fullWidth
            maxWidth="md"
            onClose={onCancel}
            onConfirm={submitForm}
            confirmButton={t('general:buttons.ok')}
            cancelButton={t('general:buttons.cancel')}
            closeButton
            title={t(
              'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.title',
            )}
            message={t(
              'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.message',
            )}
            aria-labelledby="start-scan-dialog-title"
            aria-describedby="start-scan-dialog-description"
          >
            <Divider className={classes.divider} />
            <Accordion>
              <AccordionSummary expandIcon={<Icons.ExpandMore />}>
                <Typography>
                  {t(
                    'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.configureDetails',
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      id="timeout"
                      name="timeout"
                      label={t(
                        'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.timeout',
                      )}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Field
                      id="rangeStart"
                      name="rangeStart"
                      label={t(
                        'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.rangeStart',
                      )}
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="rangeEnd"
                      name="rangeEnd"
                      label={t(
                        'discovery:deviceDiscoveryCard.startDeviceDiscoveryAlert.rangeEnd',
                      )}
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </ConfirmAlert>
        );
      }}
    </Formik>
  );
};
