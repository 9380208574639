import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { LayoutSimple } from '../LayoutSimple';
import { Loading } from '../Loading';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 0,
    zIndex: 9999,
  },
  errorWrapper: {
    padding: '5px',
  },
  errorBox: {
    padding: '15px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.error,
    color: 'white',
  },
  errorIcon: {
    marginRight: '15px',
    color: 'white',
  },
});

interface GlobalLoadingProps {
  error?: Error | null;
}

export const GlobalLoading: React.FC<GlobalLoadingProps> = ({ error }) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <LayoutSimple>
        {error ? (
          <Card className={styles.errorWrapper}>
            <div className={styles.errorBox}>
              <Icons.Error className={styles.errorIcon} />
              {error.message}
            </div>
          </Card>
        ) : (
          <Loading />
        )}
      </LayoutSimple>
    </div>
  );
};
