import React from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles({
  loading: {
    margin: 50,
  },
});

type LoadingProps = {
  className?: string;
};
export const Loading: React.FC<LoadingProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <CircularProgress className={classNames(classes.loading, className)} />
  );
};
