import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { Loading } from '../../../components/Loading';
import { Color } from '../../../constants/Colors';

type State = Record<string, NodeJS.Timeout | undefined>;

type Action =
  | {
      type: 'copy';
      rowName: string;
      timeout: NodeJS.Timeout;
    }
  | {
      type: 'reset';
      rowName: string;
    }
  | {
      type: 'reset-all';
    };

const initialState = {};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'copy':
      return {
        ...state,
        [action.rowName]: action.timeout,
      };
    case 'reset':
      return {
        ...state,
        [action.rowName]: undefined,
      };
    case 'reset-all':
      return {};
    default:
      return state;
  }
};

const useStyles = makeStyles({
  card: {
    minHeight: 208,
  },
  banner: {
    marginTop: 16,
    margin: 'auto',
  },
  table: {
    marginTop: 16,
    marginBottom: 16,
  },
  tableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontFamily: 'monospace',
  },
  checkIcon: {
    color: Color.ok,
    marginBottom: 2,
    marginTop: 3,
  },
});

export type Config = {
  host: string;
  port: string;
  clientId: string;
  user: string;
  password: string;
  primaryConnectionString: string;
  primaryAuthKey: string;
  secondaryConnectionString: string;
  secondaryAuthKey: string;
};

type ConfigurationOverviewConfigProps = {
  loading: boolean;
  config?: Config;
  onDownload: () => void;
};
export const ConfigurationOverviewConfig: React.FC<
  ConfigurationOverviewConfigProps
> = ({ loading, config, onDownload }) => {
  const { t } = useTranslation(['configuration', 'general']);
  const classes = useStyles();

  const [isPrivate, setIsPrivate] = useState(false);
  const [copied, dispatch] = useReducer(reducer, initialState);

  const handleCopy = (name: string, value: string) => () => {
    copy(value);
    const timeout = setTimeout(
      () =>
        dispatch({
          type: 'reset',
          rowName: name,
        }),
      500,
    );
    dispatch({
      type: 'copy',
      rowName: name,
      timeout,
    });
  };

  useEffect(() => {
    return () => {
      Object.values(copied).forEach(
        (timeout) => timeout && clearTimeout(timeout),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: 'reset-all' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const renderValue = (value?: string): string =>
    isPrivate ? value || '' : '*'.repeat(10);

  const rows = [
    {
      key: 'host',
      name: t('configuration:select.config.headers.host'),
      value: config?.host || '',
      copy: config?.host || '',
    },
    {
      key: 'port',
      name: t('configuration:select.config.headers.port'),
      value: config?.port || '',
      copy: config?.port || '',
    },
    {
      key: 'clientId',
      name: t('configuration:select.config.headers.clientId'),
      value: config?.clientId || '',
      copy: config?.clientId || '',
    },
    {
      key: 'user',
      name: t('configuration:select.config.headers.user'),
      value: config?.user || '',
      copy: config?.user || '',
    },
    {
      key: 'password',
      name: t('configuration:select.config.headers.password'),
      value: renderValue(config?.password || ''),
      copy: config?.password || '',
    },
    {
      key: 'primaryAuthKey',
      name: t('configuration:select.config.headers.primaryAuthKey'),
      value: renderValue(config?.primaryAuthKey || ''),
      copy: config?.primaryAuthKey || '',
    },
    {
      key: 'primaryConnectionString',
      name: t('configuration:select.config.headers.primaryConnectionString'),
      value: renderValue(config?.primaryConnectionString || ''),
      copy: config?.primaryConnectionString || '',
    },
    {
      key: 'secondaryAuthKey',
      name: t('configuration:select.config.headers.secondaryAuthKey'),
      value: renderValue(config?.secondaryAuthKey || ''),
      copy: config?.secondaryAuthKey || '',
    },
    {
      key: 'secondaryConnectionString',
      name: t('configuration:select.config.headers.secondaryConnectionString'),
      value: renderValue(config?.secondaryConnectionString || ''),
      copy: config?.secondaryConnectionString || '',
    },
  ];

  return (
    <FancyCard
      title={t('configuration:select.config.title')}
      loading={loading}
      className={classes.card}
      actions={
        config && (
          <>
            <Tooltip
              arrow
              placement="top-end"
              title={t('configuration:select.config.visibilityTooltip') || ''}
            >
              <IconButton
                data-testid="make-visible-button"
                onClick={() => setIsPrivate(!isPrivate)}
                size="medium"
                color="primary"
              >
                {isPrivate ? (
                  <Icons.VisibilityOff fontSize="small" />
                ) : (
                  <Icons.Visibility fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              placement="top-end"
              title={t('configuration:select.config.downloadTooltip') || ''}
            >
              <IconButton
                data-testid="download-button"
                onClick={() => onDownload()}
                size="medium"
                color="primary"
              >
                <Icons.SaveAlt fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    >
      {loading && <Loading />}
      {!loading && !config && (
        <EmptyBanner
          description={t('configuration:select.config.noGateway')}
          className={classes.banner}
        />
      )}
      {!loading && config && (
        <Table size="small" className={classes.table}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.key}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  {row.value}
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  {copied[row.name] ? (
                    <Icons.Check
                      className={classes.checkIcon}
                      data-testid={`copy-to-clipboard-success-${row.key}`}
                    />
                  ) : (
                    <IconButton
                      onClick={handleCopy(row.name, row.copy)}
                      size="small"
                      data-testid={`copy-to-clipboard-${row.key}`}
                    >
                      <Icons.ContentCopy />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </FancyCard>
  );
};
