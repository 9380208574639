import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  deleteDeployment,
  getDeployments,
  restoreMappingsFromDeployment,
} from '../../../api/bacnet';
import { ErrorAlert } from '../../../components/Alerts';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { DeploymentList, Deployment } from './DeploymentList';
import * as Entities from '../../../constants/Entities';
import { useDeploymentsGatewayDetailsQuery } from '../../../__generated__/types';
import { entityLocation } from '../../../utils/entity';

export type Deployments = Deployment[];

export const DeploymentListContainer: React.FC = () => {
  const { t } = useTranslation(['deployments', 'general']);
  const navigate = useNavigate();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();
  const [deployments, setDeployments] = useState<Deployments>([]);

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeploymentsGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gwLocation = dataGateway?.placementOfDevice;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEPLOYMENTS,
            id: `?gatewayId=${gatewayId}`,
            name: gateway.name,
          },
          {
            type: Entities.DEPLOYMENTS,
            id: '/deployments',
            name: t('deployments:deploymentList.breadcrumb'),
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('deployments:deploymentList.title'),
      sub:
        gwLocation?.siteName && gwLocation?.buildingName
          ? `${gwLocation?.siteName}, ${gwLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gwLocation]);

  const [
    callGetDeployments,
    { loading: loadingGetDeployments, error: errorGetDeployments },
  ] = usePrepareRequest(getDeployments, {
    onCompleted: (result) => {
      setDeployments(result?.data || []);
    },
  });
  const [callRefreshDeployments, { error: errorRefreshDeployments }] =
    usePrepareRequest(getDeployments, {
      onCompleted: (result) => {
        setDeployments(result?.data || []);
      },
    });
  const [
    callDeleteDeployment,
    { loading: loadingDeleteDeployment, error: errorDeleteDeployment },
  ] = usePrepareRequest(deleteDeployment, {
    onCompleted: (result) => {
      callRefreshDeployments(gatewayId);
    },
  });
  const [
    callRestoreMappings,
    { loading: loadingRestoreMappings, error: errorRestoreMappings },
  ] = usePrepareRequest(restoreMappingsFromDeployment);

  useEffect(() => {
    callGetDeployments(gatewayId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  const navigateBack = () => {
    navigate(entityLocation(Entities.DEPLOYMENTS, `?gatewayId=${gatewayId}`));
  };

  const handleDelete = (deploymentId: string) => {
    callDeleteDeployment(gatewayId, deploymentId);
  };
  const handleViewDetails = (deploymentId: string) => {
    navigate(deploymentId);
  };
  const handleRestoreMappings = (deploymentId: string) => {
    callRestoreMappings(gatewayId, deploymentId);
  };

  return (
    <>
      <DeploymentList
        loading={loadingGetDeployments}
        loadingDelete={loadingDeleteDeployment}
        loadingRestore={loadingRestoreMappings}
        deployments={deployments}
        onBack={navigateBack}
        onDelete={handleDelete}
        onViewDetails={handleViewDetails}
        onRestoreMappings={handleRestoreMappings}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          errorGetDeployments ||
          errorGateway ||
          errorDeleteDeployment ||
          errorRestoreMappings ||
          errorRefreshDeployments
        }
      />
    </>
  );
};
