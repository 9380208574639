import * as Entities from '../constants/Entities';

/**
 * Gets the path of an entity
 *
 * Example:
 *
 * ```
 * import * as Entities from "../constants/Entities.ts";
 *
 * entityLocation(Entities.DEVICE, "1"); // `/devices/1`
 * ```
 *
 * @param entity  The type of the entity to get the location for
 * @param id      The id of the entity
 */
export const entityLocation = (entity: Entities.Entity, id: string): string => {
  return `${entity.path}/${id}`;
};
