import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridFooter,
  GridFooterContainer,
  GridSelectionModel,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { DataGrid } from '../../../components/DataGrid';

export type FieldDevice = {
  name: string;
  id: string;
  model: string;
  capabilitiesCount: number;
  parentDeviceName: string;
  fieldDeviceId: string;
  mappingCount?: number;
};
type FieldDeviceListProps = {
  loading?: boolean;
  devices: FieldDevice[];
  onSelect: (id?: string) => void;
  onAdd?: () => void;
};

export const FieldDeviceList: React.FC<FieldDeviceListProps> = ({
  loading,
  devices,
  onSelect,
  onAdd,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelect = ([currentId]: GridSelectionModel) => {
    const [previousId] = selectionModel;
    const selectedId = previousId === currentId ? undefined : String(currentId);
    const newSelection = previousId === currentId ? [] : [currentId];
    onSelect(selectedId);
    setSelectionModel(newSelection);
  };

  const handleAdd = () => {
    onAdd && onAdd();
    setSelectionModel(['-1']);
  };
  const columns = [
    {
      field: 'name',
      headerName: t('deployments:common.fieldDeviceList.headers.name'),
      description: t(
        'deployments:common.fieldDeviceList.headers.nameDescription',
      ),
      flex: 1,
    },
    {
      field: 'model',
      headerName: t('deployments:common.fieldDeviceList.headers.deviceModel'),
      description: t(
        'deployments:common.fieldDeviceList.headers.deviceModelDescription',
      ),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('deployments:common.fieldDeviceList.headers.description'),
      description: t(
        'deployments:common.fieldDeviceList.headers.descriptionDescription',
      ),
      flex: 1,
    },
    {
      field: 'mappedCapabilities',
      headerName: t(
        'deployments:common.fieldDeviceList.headers.mappedCapabilities',
      ),
      description: t(
        'deployments:common.fieldDeviceList.headers.mappedCapabilitiesDescription',
      ),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mappingCount ?? '-'}/${params.row.capabilitiesCount}`,
    },
  ];

  return (
    <FancyCard
      title={t('deployments:common.fieldDeviceList.title')}
      loading={loading}
      actions={
        onAdd && (
          <IconButton
            aria-label="assign-button"
            data-testid="assign-button"
            onClick={handleAdd}
          >
            <Icons.Add fontSize="medium" color="primary" />
          </IconButton>
        )
      }
    >
      <DataGrid
        loading={loading}
        components={{
          Toolbar: () => (
            <GridFooterContainer>
              <GridToolbarFilterButton />
              <GridFooter />
            </GridFooterContainer>
          ),
        }}
        rows={devices}
        columns={columns}
        onSelectionModelChange={handleSelect}
        selectionModel={selectionModel}
        disableMultipleSelection
        hideFooter
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
