import React from 'react';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/bacnet';
import { ObjectDiscoveryCard } from './ObjectDiscoveryCard';
import { ErrorAlert } from '../../../components/Alerts';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { useDevices } from '../../../hooks/useDevices';
import { GatewayStatus } from '../../../api/bacnet/common';

type ObjectDiscoveryCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayStatus?: GatewayStatus;
  refreshGetGatewayDetails: () => void;
};

export const ObjectDiscoveryCardContainer: React.FC<
  ObjectDiscoveryCardContainerProps
> = ({ loading, gatewayId, gatewayStatus, refreshGetGatewayDetails }) => {
  const { t } = useTranslation(['deployments', 'general']);

  const {
    devices,
    loading: loadingObjectScan,
    error: errorObjectScan,
    refresh: refreshObjectScan,
  } = useDevices(gatewayId);

  const [
    callStartObjectScan,
    { loading: loadingStartObjectScan, error: errorStartObjectScan },
  ] = usePrepareRequest(API.startObjectScan, {
    onCompleted: () => {
      refreshGetGatewayDetails();
      refreshObjectScan();
    },
  });
  const [
    callUploadObjectScan,
    { loading: loadingUploadObjectScan, error: errorUploadObjectScan },
  ] = usePrepareRequest(API.uploadObjectScan, {
    onCompleted: () => {
      refreshGetGatewayDetails();
      refreshObjectScan();
    },
  });

  const [
    callDeleteObjectScan,
    { loading: loadingDeleteObjectScan, error: errorDeleteObjectScan },
  ] = usePrepareRequest(API.deleteObjectScan, {
    onCompleted: () => {
      refreshObjectScan();
    },
  });

  const handleStartScan = async (selectedDevices: string[]) => {
    if (!gatewayId) return;
    await callStartObjectScan(gatewayId, selectedDevices);
  };

  const handleSelectFile = async (
    selectedDevices: string[],
    content: string,
  ) => {
    if (!gatewayId) return;
    await callUploadObjectScan(gatewayId, selectedDevices, content);
  };

  const handleDeleteObjectScan = async (deviceId: string) => {
    if (!gatewayId) return;
    await callDeleteObjectScan(gatewayId, deviceId);
  };

  return (
    <>
      <ObjectDiscoveryCard
        loading={
          loading ||
          loadingObjectScan ||
          loadingUploadObjectScan ||
          loadingStartObjectScan ||
          loadingDeleteObjectScan
        }
        devices={devices}
        gatewayStatus={gatewayStatus}
        onStartScan={handleStartScan}
        onSelectFile={handleSelectFile}
        onDeleteObjectScan={handleDeleteObjectScan}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorObjectScan?.responseCode !== 'NOT_FOUND' && errorObjectScan) ||
          errorUploadObjectScan ||
          errorStartObjectScan ||
          errorDeleteObjectScan
        }
      />
    </>
  );
};
