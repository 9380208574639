import React, { useState } from 'react';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { DeployAlert, Action } from './DeployAlert';
import {
  DeviceScanStats,
  DeviceScanStatsTable,
} from '../common/DeviceScanStatsTable';
import {
  ObjectScansStats,
  ObjectScanStatsTable,
} from '../common/ObjectScanStatsTable';
import { MappingStats, MappingStatsTable } from '../common/MappingStatsTable';
import { Color } from '../../../constants/Colors';
import { GatewayStatus } from '../../../api/bacnet/common';

const useStyles = makeStyles({
  cardContent: {
    minHeight: 284,
  },
  banner: {
    margin: 'auto',
  },
  deployButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  deployButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  deployButton: {
    width: 400,
  },
  deployError: {
    marginTop: 8,
    padding: 2,
    color: Color.error,
  },
});

export type GatewayDetails = {
  status: GatewayStatus;
  deploymentError: string | null;
  deviceScan: DeviceScanStats;
  objectScans: ObjectScansStats;
  mapping: MappingStats;
};
type WorkbenchCardProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayDetails?: GatewayDetails;
  onDiscovery: () => void;
  onEditMappings: () => void;
  onDownload: () => void;
  onDeploy: () => void;
};

export const WorkbenchCard: React.FC<WorkbenchCardProps> = ({
  loading,
  gatewayDetails,
  gatewayId,
  onDiscovery,
  onEditMappings,
  onDownload,
  onDeploy,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const handleDiscovery = () => {
    onDiscovery();
  };

  const handleEditMappings = () => () => {
    onEditMappings();
  };

  const confirmDeploy = () => setShouldConfirm(true);
  const handleCancel = () => setShouldConfirm(false);
  const handleAction = (action: Action) => {
    setShouldConfirm(false);
    if (action === 'deploy') onDeploy();
    if (action === 'download') onDownload();
  };

  const isIdle = gatewayDetails?.status === 'idle';
  const hasMappings = !!gatewayDetails?.mapping?.total;
  const hasScanResult =
    gatewayDetails?.deviceScan?.status === 'completed' &&
    gatewayDetails?.deviceScan?.numOfDevices &&
    gatewayDetails?.deviceScan?.numOfDevices > 0 &&
    gatewayDetails?.objectScans.some(
      (scan) =>
        scan.status === 'completed' &&
        scan.numOfObjects &&
        scan.numOfObjects > 0,
    );
  const canEditMappings = !loading && isIdle && hasScanResult;
  const canDeployMappings = canEditMappings && hasMappings;
  const deploymentError = gatewayDetails?.deploymentError;

  const deviceScanResult = gatewayDetails?.deviceScan;
  const objectScanResult = gatewayDetails?.objectScans;

  return (
    <>
      <FancyCard
        title={t('deployments:overview.workbenchCard.title')}
        loading={loading}
        actions={
          gatewayId && (
            <>
              <IconButton
                aria-label={`start-scan-button`}
                data-testid={`start-scan-button`}
                onClick={handleDiscovery}
                size="medium"
                color="primary"
                disabled={!isIdle}
              >
                <Tooltip
                  arrow
                  title={
                    t('deployments:overview.workbenchCard.startScan') || ''
                  }
                  placement="bottom"
                >
                  <Icons.TrackChanges fontSize="medium" />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label={`edit-mappings-button`}
                data-testid={`edit-mappings-button`}
                onClick={handleEditMappings()}
                size="medium"
                color="primary"
                disabled={!canEditMappings}
              >
                <Tooltip
                  arrow
                  title={
                    t('deployments:overview.workbenchCard.editMappings') || ''
                  }
                  placement="bottom"
                >
                  <Icons.Edit fontSize="medium" />
                </Tooltip>
              </IconButton>
            </>
          )
        }
      >
        <Grid container spacing={2} className={classes.cardContent}>
          {!loading && !gatewayId && (
            <EmptyBanner
              className={classes.banner}
              description={t('deployments:overview.workbenchCard.noGateway')}
            />
          )}
          {gatewayId && (
            <>
              <Grid item xs={12} lg={4} id="scan column">
                <DeviceScanStatsTable
                  status={deviceScanResult?.status}
                  startedAt={deviceScanResult?.startedAt}
                  finishedAt={deviceScanResult?.finishedAt}
                  numOfDevices={deviceScanResult?.numOfDevices}
                />
              </Grid>
              <Grid item xs={12} lg={4} id="scan column">
                <ObjectScanStatsTable objectScans={objectScanResult} />
              </Grid>
              <Grid item xs={12} lg={4} id="deployment column">
                <MappingStatsTable
                  stats={gatewayDetails?.mapping || undefined}
                />
              </Grid>
              <Grid item xs={12} lg={12} id="deploy column">
                <div className={classes.deployButtonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={confirmDeploy}
                    className={classes.deployButton}
                    disabled={!canDeployMappings}
                    data-testid={'deploy-button'}
                  >
                    <div className={classes.deployButtonContent}>
                      {t('deployments:overview.workbenchCard.deployButton')}
                      <Icons.PlayCircleFilled fontSize="large" />
                    </div>
                  </Button>
                  {deploymentError && (
                    <div className={classes.deployError}>
                      {t('deployments:overview.workbenchCard.deployError') +
                        deploymentError}
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </FancyCard>
      <DeployAlert
        open={shouldConfirm}
        onCancel={handleCancel}
        onConfirm={handleAction}
      />
    </>
  );
};
