import { Response } from '../../hooks/useRequest';
import { RequestError } from './RequestError';

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const request = async <T>(
  method: Methods,
  url: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: {},
): Promise<Response<T>> => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;

  const headers = {
    ...defaultHeaders,
    Authorization: token ? `Bearer ${token}` : '',
  };

  const options =
    method === 'GET'
      ? { headers }
      : {
          method,
          headers,
          body: payload ? JSON.stringify(payload) : undefined,
        };

  const uri =
    method === 'GET' && payload
      ? `${url}?${new URLSearchParams(payload).toString()}`
      : url;

  const response = await fetch(uri, options);

  if (!response.ok) {
    try {
      const data = (await response.json()) as T;
      return { error: new RequestError(response.statusText, data) };
    } catch {
      return { error: new RequestError(response.statusText) };
    }
  }

  try {
    return { data: (await response.json()) as T };
  } catch {
    return {};
  }
};

export const requestFile = async (
  method: Methods,
  url: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: {},
): Promise<Response<Blob>> => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;

  const headers = {
    ...defaultHeaders,
    Authorization: token ? `Bearer ${token}` : '',
  };

  const options =
    method === 'GET'
      ? { headers }
      : {
          method,
          headers,
          body: payload ? JSON.stringify(payload) : undefined,
        };

  const uri =
    method === 'GET' && payload
      ? `${url}?${new URLSearchParams(payload).toString()}`
      : url;

  const response = await fetch(uri, options);

  if (!response.ok) {
    return { error: new RequestError(response.statusText) };
  }

  try {
    return { data: await response.blob() };
  } catch {
    return {};
  }
};
