import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FancyErrorCard } from '../../../components/FancyErrorCard';

export const Forbidden: React.FC = () => {
  const { t } = useTranslation(['general']);
  const navigate = useNavigate();
  return (
    <FancyErrorCard
      message={t('auth:forbidden.error')}
      actionMessage={t('auth:forbidden.home')}
      onClickAction={() => {
        navigate('/');
      }}
    />
  );
};
