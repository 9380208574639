import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Divider,
  Typography,
  CircularProgress,
  Theme,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { Breadcrumbs } from '../Breadcrumbs';
import { useHeader } from '../../contexts/header-context';
import { FancyBackground } from '../FancyBackground/FancyBackground';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  root: {
    margin: '0',
    position: 'relative',
    background: Color.pageHeaderBackground,
    padding: '80px 20px 20px 20px',
    borderRadius: 8,
    border: '1px solid',
    borderColor: Color.border,
    marginBottom: '16px',
  },
  titleContent: {
    fontWeight: 600,
    minHeight: '32px',
    opacity: 1,
    transition: (theme: Theme) =>
      theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  titleContentLoading: {
    opacity: 0,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: (theme: Theme) =>
      theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  loaderLoading: {
    opacity: 1,
  },
  loaderProgress: {},
  divider: {
    marginTop: '4px',
    marginBottom: '6px',
  },
  iconContainer: {
    opacity: 0.02,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '21px',
    justifyContent: 'space-between',
    transform: 'translateY(0)',
    transition: (theme: Theme) =>
      theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  subtitleWrapper: {
    overflow: 'hidden',
  },
  subtitleLoading: {
    transform: 'translateY(-100px)',
  },
  subtitleContent: {
    fontSize: '14px',
  },
});

export const PageHeader: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const header = useHeader();

  const {
    loading,
    title: { main: maintitle, sub: subtitle },
    icon: Icon,
  } = header;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography
            className={classNames(
              classes.titleContent,
              (!maintitle || loading) && classes.titleContentLoading,
            )}
            variant="h5"
            component="span"
            display="block"
          >
            {maintitle}
          </Typography>
          <div
            className={classNames(
              classes.loader,
              loading && classes.loaderLoading,
            )}
          >
            <CircularProgress
              size="20px"
              className={classes.loaderProgress}
              color="primary"
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.subtitleWrapper}>
          <div
            className={classNames(
              classes.subtitle,
              loading && classes.subtitleLoading,
            )}
          >
            <Breadcrumbs />
            <Typography
              variant="body1"
              color="textSecondary"
              component="div"
              className={classes.subtitleContent}
            >
              {subtitle}
            </Typography>
          </div>
        </div>
      </div>
      {Icon && <FancyBackground icon={<Icon />} />}
    </>
  );
};
