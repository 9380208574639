import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter, sub } from 'date-fns';
import { Grid } from '@mui/material';
import { FieldDevice } from './MonitoringOverviewFieldDeviceList';
import { ErrorAlert } from '../../../components/Alerts';
import { MonitoringOverviewDeviceData } from './MonitoringOverviewDeviceData';
import { Status } from '../../../components/StatusIndicator';
import { OFFLINE_THRESHOLD } from '../../../constants/Misc';
import { useDeviceDataQuery } from '../../../__generated__/types';

type DeviceData = {
  deviceId: string;
  deviceModelCapabilityId: string;
  utcTimeMeasured: string;
  valueString: string;
};

const getStatus = (d?: DeviceData): Status => {
  let status = Status.unknown;
  if (d?.utcTimeMeasured) {
    status = Status.disconnected;
    if (
      isAfter(
        new Date(d.utcTimeMeasured),
        sub(new Date(), { minutes: OFFLINE_THRESHOLD }),
      )
    ) {
      status = Status.connected;
    }
  }
  return status;
};

type MonitoringOverviewDeviceDataContainerProps = {
  loading?: boolean;
  device: FieldDevice;
};

export const MonitoringOverviewDeviceDataContainer: React.FC<
  MonitoringOverviewDeviceDataContainerProps
> = ({ device }) => {
  const { t } = useTranslation(['monitoring', 'general']);
  const pollTimeout = useRef<NodeJS.Timeout>();

  const capabilities = device.capabilities || [];
  const deviceModelCapabilityIds = capabilities.map((c) => c.id);
  const { loading, error, data, refetch } = useDeviceDataQuery({
    variables: { deviceId: device.id, deviceModelCapabilityIds },
  });

  useEffect(() => {
    const poll = () => {
      refetch();
      pollTimeout.current = setTimeout(poll, 10000);
    };
    poll();
    return () => {
      const timeout = pollTimeout.current;
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedData = capabilities.map((capability) => {
    const values = (data?.latestSensorData || [])
      .map((d) => ({
        deviceId: d?.deviceId || '',
        deviceModelCapabilityId: d?.deviceModelCapabilityId || '-1',
        utcTimeMeasured: d?.utcTimeMeasured || '',
        valueString: d?.valueString || '',
      }))
      .filter((v) => v.deviceModelCapabilityId !== '-1');

    const capabilityData = values.find(
      (d) => d.deviceModelCapabilityId === capability.id,
    );
    const ts = capabilityData?.utcTimeMeasured
      ? new Date(capabilityData.utcTimeMeasured).toLocaleString()
      : '';
    const value = capabilityData?.valueString
      ? `${capabilityData.valueString || ''} ${capability?.unitSymbol || ''}`
      : '';
    const status = getStatus(capabilityData);

    return {
      id: capability.id,
      capability: capability?.name || '',
      status,
      ts,
      value,
    };
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MonitoringOverviewDeviceData data={mappedData} loading={loading} />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={error}
      />
    </>
  );
};
