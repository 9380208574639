import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ErrorAlert } from '../../../components/Alerts';
import { ErrorMessage } from '../../../components/Errors';
import { useMonitoringGatewayDetailsLazyQuery } from '../../../__generated__/types';
import {
  FieldDevice,
  MonitoringOverviewFieldDeviceList,
} from './MonitoringOverviewFieldDeviceList';
import { byName } from '../../../utils/sort';
import {
  getLatestSuccessfulDeployment,
  getDeploymentMappings,
} from '../../../api/bacnet';
import { usePrepareRequest } from '../../../hooks/useRequest';

type MonitoringOverviewFieldDeviceListContainerProps = {
  gatewayId?: string;
  onSelectDevice: (device?: FieldDevice) => void;
};
export const MonitoringOverviewFieldDeviceListContainer: React.FC<
  MonitoringOverviewFieldDeviceListContainerProps
> = ({ gatewayId, onSelectDevice }) => {
  const { t } = useTranslation(['monitoring', 'general', 'errors']);
  const [devices, setDevices] = useState<FieldDevice[]>();

  const [
    callGatewayDetails,
    { loading: loadingGateway, error: errorGateway, data: dataGateway },
  ] = useMonitoringGatewayDetailsLazyQuery();
  const [
    callGetLatestSuccessfulDeployment,
    {
      loading: loadingLatestSuccessfulDeployment,
      error: errorLatestSuccessfulDeployment,
    },
  ] = usePrepareRequest(getLatestSuccessfulDeployment);
  const [callGetMappings, { loading: loadingMappings, error: errorMappings }] =
    usePrepareRequest(getDeploymentMappings);

  useEffect(() => {
    if (gatewayId) {
      callGatewayDetails({ variables: { gatewayId } });
      callGetLatestSuccessfulDeployment(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useEffect(() => {
    if (!gatewayId) {
      setDevices(undefined);
      return;
    }

    callGetLatestSuccessfulDeployment(gatewayId)
      .then((deploymentsResult) => {
        const deployment = deploymentsResult.response?.data;
        if (!deployment) return null;
        return callGetMappings(gatewayId, deployment.id);
      })
      .then((mappingsResult) => {
        const gateway = dataGateway?.device;
        const childDevices = gateway?.inverseParentDevice || [];
        const mappings = mappingsResult?.response?.data || [];

        const mappedDevices = childDevices
          .map((device) => {
            return {
              id: device?.id || '-1',
              name: device?.name || '-',
              description: device?.description || '-',
              model: device?.deviceModel?.name || '-',
              capabilities:
                device?.deviceModel?.deviceModelCapabilities?.map((c) => ({
                  id: c.id,
                  name: c.capability.name,
                  unitSymbol: c.unit.unitSymbol || '',
                })) || [],
              fieldDeviceId: device?.fieldDeviceId || '-',
              parentDeviceName: gateway?.name || '-',
              mappingCount:
                mappings?.filter((m) => m.mappedDeviceId === device.id)
                  .length || 0,
            };
          })
          .filter((device) => device.id !== '-1')
          .sort(byName);

        setDevices(mappedDevices);
        return mappedDevices;
      })
      .catch((e) => console.warn(e));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGateway, gatewayId]);

  if (errorGateway) {
    return (
      <ErrorMessage
        error={errorGateway}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MonitoringOverviewFieldDeviceList
            loading={
              loadingGateway ||
              loadingLatestSuccessfulDeployment ||
              loadingMappings
            }
            devices={devices}
            onSelect={onSelectDevice}
          />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorLatestSuccessfulDeployment?.responseCode !==
            'UNKNOWN_GATEWAY' &&
            errorLatestSuccessfulDeployment?.responseCode !== 'NOT_FOUND' &&
            errorLatestSuccessfulDeployment) ||
          errorMappings
        }
      />
    </>
  );
};
