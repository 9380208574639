import React, { useCallback } from 'react';
import { Theme, useTheme } from '@mui/material';
import {
  Header as UIHeader,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  Avatar,
  Icons,
  Divider,
  Colors,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { makeStyles } from '@mui/styles';
import { Logo } from '../Logo';
import {
  ADMIN_APP_URI,
  BUILDING_INSIGHTS_APP_URI,
  PORTAL_APP_URI,
} from '../../utils/env';

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  Spacer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  leftActions: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    '-webkit-font-smoothing': 'antialiased',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.Neutral.text,
    marginLeft: '16px',
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: '16px',
  },
  open: {
    transform: `rotate(180deg)`,
  },
});

interface HeaderProps {
  onCollapseClick?: () => void;
  sidebarOpen?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onCollapseClick,
  sidebarOpen,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { signOut } = useAuth();
  const { user } = useAuth();
  const { t } = useTranslation(['general']);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const [firstName, lastName] = (user?.info?.name || '').split(/ /);

  return (
    <UIHeader className={classes.header}>
      <div className={classes.leftActions}>
        <IconButton
          icon={Icons.IconCollapseMenu}
          onClick={onCollapseClick}
          className={sidebarOpen ? undefined : classes.open}
        />
        <div className={classes.title}>
          {t('general:apps.commissioningApp')}
        </div>
      </div>
      <Logo />
      <div className={classes.rightActions}>
        <Popover
          trigger={IconButton}
          icon={Icons.IconApps}
          triggerActiveProp="active"
        >
          <Menu>
            <MenuItem component="a" href={ADMIN_APP_URI} target="_blank">
              {t('general:apps.adminApp')}
            </MenuItem>
            <MenuItem
              component="a"
              href={BUILDING_INSIGHTS_APP_URI}
              target="_blank"
            >
              {t('general:apps.buildingInsightsApp')}
            </MenuItem>
          </Menu>
        </Popover>
        <Avatar
          firstName={firstName || ''}
          lastName={lastName || ''}
          email={user?.info?.email || ''}
          className={classes.avatar}
        >
          <Menu>
            <MenuItem component="a" href={PORTAL_APP_URI} target="_blank">
              {t('general:header.myAccount')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>
              {t('general:account.signOut')}
            </MenuItem>
          </Menu>
        </Avatar>
      </div>
    </UIHeader>
  );
};
