import React, { ChangeEvent, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { DeviceScanTable } from './DeviceScanTable';
import { EmptyBanner } from '../../../components/EmptyBanner';
import {
  ConfirmDeviceDiscoveryAlert,
  StartDeviceDiscoverySettings,
} from './ConfirmDeviceDiscoveryAlert';
import { GatewayStatus, ScanStatus } from '../../../api/bacnet/common';

const useStyles = makeStyles({
  cardContent: {
    minHeight: 200,
  },
  banner: {
    margin: 'auto',
  },
  button: {
    margin: 8,
    marginBottom: 16,
  },
});

type DeviceScan = {
  numOfDevices: number;
  status: ScanStatus;
  startedAt: string;
  finishedAt?: string;
  error: null | string;
};

export type DeviceDiscoveryCardProps = {
  loading?: boolean;
  gatewayStatus?: GatewayStatus;
  deviceScan?: DeviceScan;
  onStartScan: (settings: StartDeviceDiscoverySettings) => void;
  onSelectFile: (content: string) => void;
};

export const DeviceDiscoveryCard: React.FC<DeviceDiscoveryCardProps> = ({
  loading,
  deviceScan,
  gatewayStatus,
  onStartScan,
  onSelectFile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['discovery', 'general']);
  const [shouldStartScan, setShouldStartScan] = useState(false);

  const confirmStartScan = () => setShouldStartScan(true);
  const handleCancelStartScan = () => setShouldStartScan(false);
  const handleStartScan = (settings: StartDeviceDiscoverySettings) => {
    setShouldStartScan(false);
    return onStartScan(settings);
  };

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const content = await fileList?.[0]?.text();
    if (content) {
      onSelectFile(content);
    }
  };

  const isIdle = gatewayStatus === 'idle';

  return (
    <>
      <FancyCard
        title={t('discovery:deviceDiscoveryCard.title')}
        loading={loading}
      >
        <Grid container spacing={2} className={classes.cardContent}>
          {!loading && !deviceScan ? (
            <EmptyBanner
              className={classes.banner}
              description={t('discovery:deviceDiscoveryCard.noDeviceScan')}
              actionLabel={t('discovery:deviceDiscoveryCard.startScan')}
              onClickAction={confirmStartScan}
            />
          ) : (
            <>
              <Grid item xs={12}>
                <DeviceScanTable loading={loading} {...deviceScan} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  onClick={confirmStartScan}
                  color="primary"
                  size="large"
                  aria-label="restart-scan-button"
                  variant="outlined"
                  disabled={loading || !isIdle}
                >
                  {t('discovery:deviceDiscoveryCard.restartScan')}
                </Button>
                <label htmlFor={'file-upload-device'}>
                  <input
                    accept="text/xml"
                    id={'file-upload-device'}
                    data-testid={'file-upload-device'}
                    name={'file-upload-device'}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleSelectFile}
                  />
                  <Button
                    className={classes.button}
                    color="secondary"
                    size="large"
                    aria-label="upload-scan-button"
                    variant="outlined"
                    component="span"
                    disabled={loading || !isIdle}
                  >
                    {t('discovery:deviceDiscoveryCard.uploadScan')}
                  </Button>
                </label>
              </Grid>
            </>
          )}
        </Grid>
      </FancyCard>
      <ConfirmDeviceDiscoveryAlert
        open={shouldStartScan}
        onCancel={handleCancelStartScan}
        onConfirm={handleStartScan}
      />
    </>
  );
};
