import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

interface DataTypeInputDoubleProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputDouble: React.FC<DataTypeInputDoubleProps> = ({
  disabled,
  value,
  id,
  name,
  label,
  className,
  onChange,
  autoFocus = true,
  error,
  helperText,
  optional,
}) => {
  const { t } = useTranslation(['general']);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    onChange(event.target.value);

  return (
    <NumberFormat
      fullWidth
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={handleChange}
      customInput={TextField}
      className={className}
      id={id}
      name={name}
      label={optional ? makeOptional(t, label) : label}
      decimalScale={10}
      allowedDecimalSeparators={['.']}
      InputLabelProps={{
        shrink: true,
      }}
      error={error}
      helperText={helperText}
    />
  );
};
