import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FancyCard } from '../../../components/FancyCard';
import { Status, StatusIndicator } from '../../../components/StatusIndicator';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { DataGrid } from '../../../components/DataGrid';
import { OFFLINE_THRESHOLD } from '../../../constants/Misc';

type MonitoringOverviewDeviceDataProps = {
  loading?: boolean;
  data?: Array<{
    id: string;
    capability: string;
    status: Status;
    ts: string;
    value: string;
  }>;
};

const useStyles = makeStyles({
  banner: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});
export const MonitoringOverviewDeviceData: React.FC<
  MonitoringOverviewDeviceDataProps
> = ({ loading, data }) => {
  const classes = useStyles();
  const { t } = useTranslation(['monitoring', 'general']);

  const columns = [
    {
      field: 'status',
      headerName: t('monitoring:overview.data.headers.status'),
      description: t('monitoring:overview.data.headers.statusDescription', {
        threshold: OFFLINE_THRESHOLD,
      }),
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <StatusIndicator status={params.row.status} />
      ),
      cellClassName: 'MuiDataGrid-cell--textCenter',
    },
    {
      field: 'capability',
      headerName: t('monitoring:overview.data.headers.capability'),
      description: t('monitoring:overview.data.headers.capabilityDescription'),
      flex: 1,
    },
    {
      field: 'ts',
      headerName: t('monitoring:overview.data.headers.ts'),
      description: t('monitoring:overview.data.headers.tsDescription'),
      flex: 1,
    },
    {
      field: 'value',
      headerName: t('monitoring:overview.data.headers.value'),
      description: t('monitoring:overview.data.headers.valueDescription'),
      flex: 1,
    },
  ];

  return (
    <FancyCard loading={loading} title={t('monitoring:overview.data.title')}>
      <DataGrid
        loading={loading}
        components={{
          NoRowsOverlay: () =>
            data ? (
              <EmptyBanner
                className={classes.banner}
                description={t('monitoring:overview.noData')}
              />
            ) : (
              <EmptyBanner
                className={classes.banner}
                description={t('monitoring:overview.noDevice')}
              />
            ),
        }}
        rows={data || []}
        columns={columns}
        hideFooter
        disableMultipleSelection
        hideFooterSelectedRowCount
      />
    </FancyCard>
  );
};
