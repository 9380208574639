import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import slugify from 'react-slugify';
import { ActionsBar } from '../../../../components/ActionsBar';
import {
  DeviceFormData,
  DeviceFormInfo,
  getDeviceFormInfoSchema,
} from './DeviceFormInfo';

import {
  DeviceAttributesForm,
  AttributeSet,
  getDeviceAttributesFormSchema,
} from '../../../../components/DeviceAttributesForm';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type ValidationState = 'LOADING' | 'ERROR' | 'VALID' | 'INVALID';

export type DeviceCreateData = DeviceFormData & {
  attributeSets: AttributeSet[];
};

interface DeviceCreateProps {
  gateway?: { id: string; name: string };
  allDeviceModels: Array<{
    id: string;
    name: string;
    deviceModelAttributeSets: AttributeSet[];
  }>;
  loading?: boolean;
  validateFieldDeviceId: (
    fieldDeviceId: string,
    parentDeviceId: string,
  ) => void;
  fieldDeviceIdValidationState: ValidationState;
  onSubmit: (device: DeviceCreateData) => void;
  onDiscard: () => void;
}

export const DeviceCreate: React.FC<DeviceCreateProps> = ({
  gateway,
  allDeviceModels,
  loading,
  validateFieldDeviceId,
  fieldDeviceIdValidationState,
  onSubmit,
  onDiscard,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);

  const validationSchema = getDeviceFormInfoSchema(
    t,
    fieldDeviceIdValidationState !== 'INVALID',
  ).concat(getDeviceAttributesFormSchema(t));

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        fieldDeviceId: '',
        parentDeviceId: gateway?.id || '0',
        parentDevice: gateway?.name || '',
        deviceModelId: '0',
        attributeSets: [] as AttributeSet[],
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        submitForm,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldError,
        setFieldTouched,
      }: FormikProps<DeviceCreateData>) => {
        const handleModelSelection = (value: string) => {
          const selectedModel = allDeviceModels.find((dm) => dm.id === value);
          const selectedAttributeSets = selectedModel?.deviceModelAttributeSets;
          setFieldValue('deviceModelId', value);
          setFieldValue(
            'attributeSets',
            selectedAttributeSets?.map((attributeSet) => ({
              ...attributeSet,
              attributes: attributeSet.attributes.map((attribute) => ({
                ...attribute,
                enabled: attribute.required,
              })),
            })),
          );
          setFieldError('attributeSets', undefined);
          setFieldTouched('attributeSets', false);
        };

        const handleChangeName = (value: string) => {
          const fieldDeviceId = slugify(value);
          setFieldValue('fieldDeviceId', fieldDeviceId);
          validateFieldDeviceId(fieldDeviceId, values.parentDeviceId || '0');
          setFieldTouched('fieldDeviceId', true, true);
        };

        const handleFieldDeviceIdValidation = (input: {
          fieldDeviceId?: string;
          parentDeviceId?: string;
        }) => {
          if (input.fieldDeviceId !== undefined) {
            setFieldValue('fieldDeviceId', input.fieldDeviceId);
            validateFieldDeviceId(
              input.fieldDeviceId,
              values.parentDeviceId || '0',
            );
          }
          if (input.parentDeviceId !== undefined) {
            setFieldValue('parentDeviceId', input.parentDeviceId);
            validateFieldDeviceId(
              values.fieldDeviceId || '',
              input.parentDeviceId,
            );
          }
        };

        if (
          errors.fieldDeviceId ===
            t('deployments:device.create.notUniqueError') &&
          fieldDeviceIdValidationState !== 'INVALID'
        ) {
          setFieldError('fieldDeviceId', undefined);
        } else if (
          errors.fieldDeviceId !==
            t('deployments:device.create.notUniqueError') &&
          fieldDeviceIdValidationState === 'INVALID'
        ) {
          setFieldError(
            'fieldDeviceId',
            t('deployments:device.create.notUniqueError'),
          );
        }

        return (
          <>
            <ActionsBar>
              <Button
                onClick={onDiscard}
                color="primary"
                size="large"
                className={classes.actionButton}
                aria-label="discard-button"
              >
                {t('general:buttons.discard')}
              </Button>
              <Button
                onClick={submitForm}
                className={classes.actionButton}
                color="secondary"
                variant="outlined"
                size="large"
                aria-label="save-button"
                disabled={loading}
              >
                {t('general:buttons.save')}
              </Button>
            </ActionsBar>
            <div className={classes.section}>
              <Grid container spacing={3} alignItems="stretch" direction="row">
                <Grid item xs={12}>
                  <DeviceFormInfo
                    type="create"
                    loading={loading}
                    onSelectModel={handleModelSelection}
                    onChangeName={handleChangeName}
                    validateFieldDeviceId={handleFieldDeviceIdValidation}
                    gateway={gateway}
                    allDeviceModels={allDeviceModels}
                  />
                </Grid>
              </Grid>
            </div>
            {values.attributeSets && values.attributeSets?.length > 0 && (
              <div className={classes.section}>
                <DeviceAttributesForm
                  attributeSets={values.attributeSets}
                  onChange={setFieldValue}
                  loading={loading}
                  touched={touched}
                  errors={errors}
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
