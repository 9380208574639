import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Button, Grid } from '@mui/material';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import { ErrorMessage } from '../../../components/Errors';
import { useDeploymentsGatewayDetailsQuery } from '../../../__generated__/types';
import { Capability, DeviceCapabilities } from '../common/DeviceCapabilities';
import { ScanResult } from '../common/ScanResult';
import { usePrepareRequest, useRequest } from '../../../hooks/useRequest';
import {
  getDeployment,
  getDeploymentScan,
  Deployment,
  getDeploymentMappings,
} from '../../../api/bacnet';
import { FieldDeviceList } from '../common/FieldDeviceList';
import { byName } from '../../../utils/sort';
import { MappingStatsCard } from '../common/MappingStatsCard';
import { ActionsBar } from '../../../components/ActionsBar';
import { BACnetDevice } from '../../../api/bacnet/common';

type Mapping = {
  bacnetDeviceId: string;
  bacnetObjectId: string;
  mappedCapabilityId: string;
};
type Mappings = Record<string, Mapping>;

type Device = {
  id: string;
  name: string;
  fieldDeviceId: string;
  capabilities: Capability[];
};

export const DeploymentsDetailsContainer: React.FC = () => {
  const { t } = useTranslation(['deployments', 'general', 'errors']);
  const navigate = useNavigate();
  const location = useLocation();
  const { gatewayId = '0', deploymentId = '0' } = useParams();

  const { setTitle, setLoading } = useHeader();

  const [selectedCapability, setSelectedCapability] = useState<Capability>();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [deployment, setDeployment] = useState<Deployment>();
  const [mappings, setMappings] = useState<Mappings>({});

  const {
    response: responseScanResultDetails,
    loading: loadingScanResultDetails,
    error: errorScanResultDetails,
  } = useRequest(getDeploymentScan, gatewayId, deploymentId);
  const {
    loading: loadingMappings,
    response: responseMappings,
    error: errorMappings,
  } = useRequest(getDeploymentMappings, gatewayId, deploymentId);
  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeploymentsGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gatewayLocation = dataGateway?.placementOfDevice;

  const hierarchy =
    gateway && deployment
      ? makeHierarchyBreadcrumb(
          [
            {
              type: Entities.DEPLOYMENTS,
              id: `?gatewayId=${gateway.id}`,
              name: gateway.name,
            },
            {
              type: Entities.DEPLOYMENTS,
              id: `list/${gateway.id}`,
              name: t('deployments:deploymentList.breadcrumb'),
            },
          ],
          t,
        ).concat({
          title: new Date(deployment.deployedAt).toLocaleString(),
          location: '',
        })
      : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('deployments:details.title'),
      sub:
        gatewayLocation?.siteName && gatewayLocation?.buildingName
          ? `${gatewayLocation?.siteName}, ${gatewayLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gatewayLocation]);

  const [
    callGetDeployment,
    { loading: loadingGetDeployment, error: errorGetDeployment },
  ] = usePrepareRequest(getDeployment, {
    onCompleted: (result) => {
      setDeployment(result?.data);
    },
  });
  useEffect(() => {
    callGetDeployment(gatewayId, deploymentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useEffect(() => {
    if (!gateway || !responseMappings?.data) return;
    const enrichedMappings = responseMappings.data
      .map((m) => {
        const mappedDevice = gateway.inverseParentDevice.find(
          (d) => d.id === m.mappedDeviceId,
        );
        const modelCapability =
          mappedDevice?.deviceModel.deviceModelCapabilities.find(
            (c) => c.id === m.mappedCapabilityId,
          );
        return {
          bacnetDeviceId: m.bacnetDeviceId,
          bacnetObjectId: m.bacnetObjectId,
          mappedDeviceId: m.mappedDeviceId,
          mappedCapabilityId: m.mappedCapabilityId,
          deviceName: mappedDevice?.name || '-',
          fieldDeviceId: mappedDevice?.fieldDeviceId || '-',
          capabilityName: modelCapability?.capability?.name || '-',
          fieldSelector: modelCapability?.fieldSelector || '-',
        };
      })
      .reduce(
        (map, m) => ({
          ...map,
          [m.mappedCapabilityId + m.mappedDeviceId]: m,
        }),
        {} as Record<string, Mapping>,
      );
    setMappings({ ...enrichedMappings, ...mappings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseMappings?.data, gateway]);

  const handleSelectDevice = (id?: string) => {
    setSelectedCapability(undefined);
    const device = id
      ? gateway?.inverseParentDevice.find((d) => d.id === id)
      : undefined;

    const capabilities = (device?.deviceModel?.deviceModelCapabilities || [])
      .map((capability) => ({
        id: capability?.id || '-1',
        name: capability?.capability?.name || '',
        fieldSelector: capability?.fieldSelector || '',
        technicalMin: capability?.technicalMin ?? undefined,
        technicalMax: capability?.technicalMax ?? undefined,
        unitName: capability?.unit?.name || '',
        unitSymbol: capability?.unit?.unitSymbol || '',
      }))
      .filter((capability) => capability.id !== '-1')
      .sort(byName);

    const mappedDevice = device && {
      id: device.id,
      name: device.name,
      fieldDeviceId: device.fieldDeviceId || '-',
      capabilities,
    };
    setSelectedDevice(mappedDevice);
  };

  const handleSelectCapability = (capability?: Capability) => {
    setSelectedCapability(capability);
  };

  const handleViewDetails = () => {
    navigate('mappings');
  };

  const devices = useMemo(
    () =>
      (gateway?.inverseParentDevice || [])
        .map((device) => {
          const capabilityIds = device.deviceModel.deviceModelCapabilities.map(
            (dmc) => dmc.id,
          );

          return {
            id: device?.id || '-1',
            name: device?.name || '-',
            description: device?.description || '-',
            model: device?.deviceModel?.name || '-',
            capabilitiesCount:
              device?.deviceModel?.deviceModelCapabilities?.length || 0,
            fieldDeviceId: device?.fieldDeviceId || '-',
            parentDeviceName: gateway?.name || '-',
            mappingCount: capabilityIds.reduce(
              (sum, cId) => sum + (device && mappings[cId + device.id] ? 1 : 0),
              0,
            ),
          };
        })
        .filter((device) => device.id !== '-1')
        .sort(byName),
    [gateway, mappings],
  );

  const navigateBack = () => {
    const hasHistory = location.key !== 'default';
    if (hasHistory) {
      navigate(-1);
    } else {
      navigate(
        location.pathname.substring(0, location.pathname.lastIndexOf('/')),
      );
    }
  };

  const scanResult = useMemo(
    () =>
      responseScanResultDetails?.data.reduce(
        (combinedResult, scanData) => [
          ...combinedResult,
          ...scanData.scanResult.unit.device,
        ],
        [] as BACnetDevice[],
      ),
    [responseScanResultDetails],
  );

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGateway) {
    return (
      <ErrorMessage
        error={errorGateway}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  const visibleCapabilities = selectedDevice?.capabilities.map((c) => ({
    ...c,
    active: c?.id === selectedCapability?.id,
    assigned:
      c.id && selectedDevice ? mappings[c.id + selectedDevice.id] : undefined,
  }));

  const deployFacts = {
    BACnetObjectCount: scanResult?.length,
    ...deployment?.mapping,
  };

  return (
    <>
      <ActionsBar>
        <Button
          onClick={navigateBack}
          color="primary"
          size="large"
          aria-label="back-button"
        >
          {t('general:buttons.back')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <FieldDeviceList
            loading={loadingGateway}
            devices={devices}
            onSelect={handleSelectDevice}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MappingStatsCard
            loading={loadingMappings || loadingGetDeployment}
            facts={deployFacts}
            onViewDetails={handleViewDetails}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DeviceCapabilities
            capabilities={visibleCapabilities}
            selectedCapabilityId={selectedCapability?.id}
            onSelectCapability={handleSelectCapability}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <ScanResult
            loading={loadingGateway || loadingScanResultDetails}
            scanResult={scanResult}
            filterDevice={selectedDevice}
            filterCapability={selectedCapability}
            mappings={mappings}
          />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorMappings?.responseCode !== 'NOT_FOUND' && errorMappings) ||
          errorGetDeployment ||
          errorScanResultDetails
        }
      />
    </>
  );
};
