import React from 'react';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/bacnet';
import { DeviceDiscoveryCard } from './DeviceDiscoveryCard';
import { ErrorAlert } from '../../../components/Alerts';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { useDeviceScan } from '../../../hooks/useDeviceScan';
import { GatewayStatus } from '../../../api/bacnet/common';

type DeviceDiscoveryCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayStatus?: GatewayStatus;
  refreshGetGatewayDetails: () => void;
};

export const DeviceDiscoveryCardContainer: React.FC<
  DeviceDiscoveryCardContainerProps
> = ({ loading, gatewayId, gatewayStatus, refreshGetGatewayDetails }) => {
  const { t } = useTranslation(['deployments', 'general']);

  const {
    deviceScan,
    loading: loadingDeviceScan,
    error: errorDeviceScan,
    refresh: refreshDeviceScan,
  } = useDeviceScan(gatewayId);

  const [
    callStartDeviceScan,
    { loading: loadingStartDeviceScan, error: errorStartDeviceScan },
  ] = usePrepareRequest(API.startDeviceScan, {
    onCompleted: () => {
      refreshGetGatewayDetails();
      refreshDeviceScan();
    },
  });
  const [
    callUploadDeviceScan,
    { loading: loadingUploadDeviceScan, error: errorUploadDeviceScan },
  ] = usePrepareRequest(API.uploadDeviceScan, {
    onCompleted: () => {
      refreshGetGatewayDetails();
      refreshDeviceScan();
    },
  });

  const handleStartScan = (settings: API.DeviceDiscoverySettings) => {
    if (!gatewayId) return null;
    return callStartDeviceScan(gatewayId, settings);
  };

  const handleSelectFile = async (content: string) => {
    if (!gatewayId) return;
    await callUploadDeviceScan(gatewayId, content);
  };

  return (
    <>
      <DeviceDiscoveryCard
        loading={
          loading ||
          loadingDeviceScan ||
          loadingUploadDeviceScan ||
          loadingStartDeviceScan
        }
        deviceScan={deviceScan}
        gatewayStatus={gatewayStatus}
        onStartScan={handleStartScan}
        onSelectFile={handleSelectFile}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorDeviceScan?.responseCode !== 'NOT_FOUND' && errorDeviceScan) ||
          errorUploadDeviceScan ||
          errorStartDeviceScan
        }
      />
    </>
  );
};
