import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../../../components/ActionsBar';
import { DeploymentListCard, Deployment } from './DeploymentListCard';
import { Loading } from '../../../components/Loading';
import { EmptyBanner } from '../../../components/EmptyBanner';

export type { Deployment };

type DeploymentListProps = {
  loading?: boolean;
  loadingDelete?: boolean;
  loadingRestore?: boolean;
  deployments: Deployment[];
  onBack: () => void;
  onRestoreMappings: (deploymentId: string) => void;
  onViewDetails: (deploymentId: string) => void;
  onDelete: (deploymentId: string) => void;
};

export const DeploymentList: React.FC<DeploymentListProps> = ({
  loading,
  loadingDelete,
  loadingRestore,
  deployments,
  onBack,
  onRestoreMappings,
  onViewDetails,
  onDelete,
}) => {
  const { t } = useTranslation(['deployments', 'general']);

  const handleDelete = (deploymentId: string) => () => {
    onDelete(deploymentId);
  };
  const handleViewDetails = (deploymentId: string) => () => {
    onViewDetails(deploymentId);
  };
  const handleRestoreMappings = (deploymentId: string) => () => {
    onRestoreMappings(deploymentId);
  };

  return (
    <>
      <ActionsBar>
        <Button
          onClick={onBack}
          color="primary"
          size="large"
          aria-label="back-button"
        >
          {t('general:buttons.back')}
        </Button>
      </ActionsBar>
      {loading && <Loading />}
      {!loading && !deployments.length && (
        <EmptyBanner
          description={t('deployments:deploymentList.noDeployments')}
          actionLabel={t('general:buttons.back')}
          onClickAction={onBack}
          disableIcon
        />
      )}
      {!loading &&
        deployments.map((deployment) => (
          <DeploymentListCard
            loadingDelete={loadingDelete}
            loadingRestore={loadingRestore}
            deployment={deployment}
            key={deployment.id}
            onDelete={handleDelete(deployment.id)}
            onViewDetails={handleViewDetails(deployment.id)}
            onRestoreMappings={handleRestoreMappings(deployment.id)}
          />
        ))}
    </>
  );
};
