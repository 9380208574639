import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ErrorMessage } from '../../../components/Errors';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { DeviceDiscoveryCardContainer } from './DeviceDiscoveryCardContainer';
import { useGatewayStatus } from '../../../hooks/useGatewayStatus';
import { ActionsBar } from '../../../components/ActionsBar';
import { ObjectDiscoveryCardContainer } from './ObjectDiscoveryCardContainer';

export const DiscoveryOverviewContainer: React.FC = () => {
  const { t } = useTranslation(['discovery', 'general']);
  const { setTitle, setIcon } = useHeader();
  const navigate = useNavigate();
  const { gatewayId } = useParams();
  const {
    details: gatewayDetails,
    loading: loadingGetGatewayDetails,
    error: errorGetGatewayDetails,
    refresh: refreshGetGatewayDetails,
  } = useGatewayStatus(gatewayId);

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.DEPLOYMENTS, t),
    location: Entities.DEPLOYMENTS.path,
  });
  useEffect(() => {
    setTitle({ main: t('discovery:overview.title') });
    setIcon(Pages.DEPLOYMENTS.icon);
  }, [setTitle, setIcon, t]);

  const navigateBack = () => {
    navigate(entityLocation(Entities.DEPLOYMENTS, `?gatewayId=${gatewayId}`));
  };

  if (!gatewayId) {
    return (
      <ErrorMessage
        error={new Error('No gatewayId provided')}
        message={t('errors:notProvided.gatewayId')}
      />
    );
  }
  if (errorGetGatewayDetails) {
    return (
      <ErrorMessage
        error={errorGetGatewayDetails}
        message={t('errors:notFound.gateway')}
      />
    );
  }

  return (
    <>
      <ActionsBar>
        <Button
          onClick={navigateBack}
          color="primary"
          size="large"
          aria-label="back-button"
        >
          {t('general:buttons.back')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DeviceDiscoveryCardContainer
            loading={loadingGetGatewayDetails}
            gatewayId={gatewayId}
            gatewayStatus={gatewayDetails?.status}
            refreshGetGatewayDetails={refreshGetGatewayDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <ObjectDiscoveryCardContainer
            loading={loadingGetGatewayDetails}
            gatewayId={gatewayId}
            gatewayStatus={gatewayDetails?.status}
            refreshGetGatewayDetails={refreshGetGatewayDetails}
          />
        </Grid>
      </Grid>
    </>
  );
};
