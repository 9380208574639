import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import * as API from '../../../api/bacnet';
import { ErrorAlert } from '../../../components/Alerts';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useHeader } from '../../../contexts/header-context';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { makeHierarchyBreadcrumb } from '../../../utils/breadcrumb';
import { MappingList, Mapping } from './MappingList';
import * as Entities from '../../../constants/Entities';
import { useDeploymentsGatewayDetailsQuery } from '../../../__generated__/types';
import { deleteMappings } from '../../../api/bacnet';

export type Mappings = Mapping[];

export const MappingListContainer: React.FC = () => {
  const { t } = useTranslation(['deployments', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { gatewayId = '0', deploymentId } = useParams();
  const { setTitle, setLoading } = useHeader();
  const [mappings, setMappings] = useState<Mappings>([]);

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeploymentsGatewayDetailsQuery({
    variables: {
      gatewayId,
    },
  });
  const gateway = dataGateway?.device;
  const gwLocation = dataGateway?.placementOfDevice;

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEPLOYMENTS,
            id: `?gatewayId=${gateway.id}`,
            name: gateway.name,
          },
          {
            type: Entities.DEPLOYMENTS,
            id: `list/${gateway.id}`,
            name: t('deployments:deploymentList.breadcrumb'),
          },
        ],
        t,
      )
        .concat({
          title: new Date().toLocaleString(),
          location: location.pathname.substring(
            0,
            location.pathname.lastIndexOf('/'),
          ),
        })
        .concat({
          title: t('deployments:mappingList.breadcrumb'),
          location: 'mappings',
        })
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({
      main: t('deployments:mappingList.title'),
      sub:
        gwLocation?.siteName && gwLocation?.buildingName
          ? `${gwLocation?.siteName}, ${gwLocation?.buildingName}`
          : '',
    });
    setLoading(loadingGateway);
  }, [setTitle, setLoading, loadingGateway, t, gwLocation]);

  const enrichMappings = (data?: API.Mappings) => {
    if (!gateway || !data) return [];
    return data.map((m) => {
      const mappedDevice = gateway.inverseParentDevice.find(
        (d) => d.id === m.mappedDeviceId,
      );
      const modelCapability =
        mappedDevice?.deviceModel.deviceModelCapabilities.find(
          (c) => c.id === m.mappedCapabilityId,
        );
      return {
        bacnetDeviceId: m.bacnetDeviceId,
        bacnetObjectId: m.bacnetObjectId,
        mappedDeviceId: m.mappedDeviceId,
        mappedCapabilityId: m.mappedCapabilityId,
        deviceName: mappedDevice?.name || '-',
        fieldDeviceId: mappedDevice?.fieldDeviceId || '-',
        capabilityName: modelCapability?.capability.name || '-',
        fieldSelector: modelCapability?.fieldSelector || '-',
      };
    });
  };

  const [
    callGetLatestMappings,
    { loading: loadingGetLatestMappings, error: errorGetLatestMappings },
  ] = usePrepareRequest(API.getLatestMappings, {
    onCompleted: (result) => {
      setMappings(enrichMappings(result?.data));
    },
  });
  const [
    callGetMappings,
    { loading: loadingGetMappings, error: errorGetMappings },
  ] = usePrepareRequest(API.getMappings, {
    onCompleted: (result) => {
      setMappings(enrichMappings(result?.data));
    },
  });
  const [
    callDeleteMappings,
    { loading: loadingSaveMappings, error: errorSaveMappings },
  ] = usePrepareRequest(deleteMappings, {
    onCompleted: () => {
      if (deploymentId) {
        callGetMappings(gatewayId, deploymentId);
      } else {
        callGetLatestMappings(gatewayId);
      }
    },
  });

  useEffect(() => {
    if (!gateway) return;
    if (deploymentId) {
      callGetMappings(gateway.id, deploymentId);
    } else {
      callGetLatestMappings(gateway.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway, deploymentId]);

  const navigateBack = () => {
    const hasHistory = location.key !== 'default';
    if (hasHistory) {
      navigate(-1);
    } else {
      navigate(
        location.pathname.substring(0, location.pathname.lastIndexOf('/')),
      );
    }
  };

  const handleDeleteAll = () => {
    callDeleteMappings(gatewayId);
  };

  return (
    <>
      <MappingList
        loading={
          loadingGateway ||
          loadingGetLatestMappings ||
          loadingSaveMappings ||
          loadingGetMappings
        }
        mappings={mappings}
        onBack={navigateBack}
        onDeleteAll={deploymentId ? undefined : handleDeleteAll}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          errorGetLatestMappings ||
          errorSaveMappings ||
          errorGetMappings ||
          errorGateway
        }
      />
    </>
  );
};
