import { request, requestFile } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import {
  url,
  ObjectScanResultEntity,
  ScanResultDataEntity,
  MappingsEntity,
  DeploymentEntity,
  GatewayConfigEntity,
  GatewayDetailsEntity,
  ScanStatsEntity,
  MappingStatsEntity,
  DeviceScanEntity,
  DevicesEntity,
  DeviceScanResultEntity,
} from './common';
import { EntityResponse } from '../common';

export type GatewayConfig = GatewayConfigEntity;
export type ObjectScanResult = ObjectScanResultEntity;
export type ScanResultData = ScanResultDataEntity;
export type ScanStats = ScanStatsEntity;
export type MappingStats = MappingStatsEntity;
export type Mappings = MappingsEntity;
export type Deployment = DeploymentEntity;
export type GatewayDetails = GatewayDetailsEntity;
export type DeviceScan = DeviceScanEntity;
export type Devices = DevicesEntity;
export type DeviceScanResult = DeviceScanResultEntity;

export type DeviceDiscoverySettings = {
  timeout: number;
  rangeStart: number;
  rangeEnd: number;
};

export const getGatewayDetails = async (
  gatewayId: string,
): Promise<Response<EntityResponse<GatewayDetails>>> => {
  return request('GET', url(`/gateways/${gatewayId}`));
};

export const getLatestMappings = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Mappings>>> => {
  return request('GET', url(`/gateways/${gatewayId}/mapping/all`));
};

export const compareMapping = async (
  gatewayId: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/mapping/compare`), {
    action: 'trigger',
  });
};

export const getGatewayConfig = async (
  gatewayId: string,
): Promise<Response<EntityResponse<GatewayConfig>>> => {
  return request('GET', url(`/gateways/${gatewayId}/configuration`));
};
export const getGatewayConfigFile = async (
  gatewayId: string,
): Promise<Response<Blob>> => {
  return requestFile('GET', url(`/gateways/${gatewayId}/configuration/file`));
};

export const getDeployments = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Deployment[]>>> => {
  return request('GET', url(`/gateways/${gatewayId}/deployments`));
};

export const deployMapping = async (
  gatewayId: string,
): Promise<Response<Blob>> => {
  return request('POST', url(`/gateways/${gatewayId}/deploy`), {
    action: 'dispatch',
  });
};

export const getLatestDeployment = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Deployment>>> => {
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/latest
    `),
  );
};

export const getLatestSuccessfulDeployment = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Deployment>>> => {
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/latestSuccessful
    `),
  );
};

export const getDeployment = async (
  gatewayId: string,
  deploymentId: string,
): Promise<Response<EntityResponse<Deployment>>> => {
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}`),
  );
};

export const deleteDeployment = async (
  gatewayId: string,
  deploymentId: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request(
    'DELETE',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}`),
  );
};

export const getDeploymentScan = async (
  gatewayId: string,
  deploymentId: string,
): Promise<Response<EntityResponse<ObjectScanResult>>> => {
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}/object-scan`),
  );
};

export const getDeploymentMappings = async (
  gatewayId: string,
  deploymentId: string,
): Promise<Response<EntityResponse<Mappings>>> => {
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}/mapping`),
  );
};

export const restoreMappingsFromDeployment = async (
  gatewayId: string,
  deploymentId: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request(
    'POST',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}/mapping/restore`),
  );
};

export const deleteMappings = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Mappings>>> => {
  return request('DELETE', url(`/gateways/${gatewayId}/mapping/all`), []);
};
export const saveMapping = async (
  gatewayId: string,
  mapping: {
    bacnetDeviceId: string;
    bacnetObjectId: string;
    mappedDeviceId: string;
    mappedCapabilityId: string;
  },
): Promise<Response<EntityResponse<Mappings>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/mapping`), mapping);
};
export const deleteMapping = async (
  gatewayId: string,
  mapping: {
    bacnetDeviceId: string;
    bacnetObjectId: string;
  },
): Promise<Response<EntityResponse<Mappings>>> => {
  return request('DELETE', url(`/gateways/${gatewayId}/mapping`), mapping);
};

export const downloadMapping = async (
  gatewayId: string,
): Promise<Response<Blob>> => {
  return requestFile('POST', url(`/gateways/${gatewayId}/deploy`), {
    action: 'download',
  });
};

export const getMappings = async (
  gatewayId: string,
  deploymentId: 'current' | string,
): Promise<Response<EntityResponse<Mappings>>> => {
  if (deploymentId === 'current') {
    return request('GET', url(`/gateways/${gatewayId}/mapping`));
  }
  return request(
    'GET',
    url(`/gateways/${gatewayId}/deployments/${deploymentId}/mapping`),
  );
};

export const getDeviceScan = async (
  gatewayId: string,
): Promise<Response<EntityResponse<DeviceScan>>> => {
  return request('GET', url(`/gateways/${gatewayId}/device-scan`));
};

export const startDeviceScan = async (
  gatewayId: string,
  settings: DeviceDiscoverySettings,
): Promise<Response<EntityResponse<unknown>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/device-scan`), {
    action: 'trigger',
    settings,
  });
};

export const uploadDeviceScan = async (
  gatewayId: string,
  xml: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/device-scan`), {
    action: 'upload',
    xml,
  });
};

export const getDevices = async (
  gatewayId: string,
): Promise<Response<EntityResponse<Devices>>> => {
  return request('GET', url(`/gateways/${gatewayId}/devices`));
};

export const getDeviceScanResult = async (
  gatewayId: string,
): Promise<Response<EntityResponse<DeviceScanResult>>> => {
  return request('GET', url(`/gateways/${gatewayId}/device-scan`));
};

export const getLatestObjectScan = async (
  gatewayId: string,
): Promise<Response<EntityResponse<ObjectScanResult>>> => {
  return request('GET', url(`/gateways/${gatewayId}/object-scan`));
};

export const startObjectScan = async (
  gatewayId: string,
  deviceIds: string[],
): Promise<Response<EntityResponse<unknown>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/object-scan`), {
    action: 'trigger',
    deviceIds,
  });
};

export const uploadObjectScan = async (
  gatewayId: string,
  devices: string[],
  xml: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request('PUT', url(`/gateways/${gatewayId}/object-scan`), {
    action: 'upload',
    devices,
    xml,
  });
};

export const deleteObjectScan = async (
  gatewayId: string,
  deviceId: string,
): Promise<Response<EntityResponse<unknown>>> => {
  return request(
    'DELETE',
    url(`/gateways/${gatewayId}/object-scan/${deviceId}`),
    {},
  );
};
