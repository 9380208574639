import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import * as API from '../api/bacnet';
import { RequestError } from '../api/utils/RequestError';
import { usePrepareRequest } from './useRequest';

export type UseGatewayStatus = {
  details: API.GatewayDetails | undefined;
  loading: boolean;
  error: RequestError | undefined;
  refresh: () => void;
};

export const useGatewayStatus = (gatewayId?: string): UseGatewayStatus => {
  const [details, setDetails] = useState<API.GatewayDetails>();
  const gatewayDetailsPollTimeout = useRef<NodeJS.Timeout>();

  const [callGetGatewayDetails, { loading, error }] = usePrepareRequest(
    API.getGatewayDetails,
    {
      onCompleted: (result) => {
        setDetails(result?.data);
      },
    },
  );
  const [pollGetGatewayDetails] = usePrepareRequest(API.getGatewayDetails, {
    onCompleted: (result) => {
      setDetails(result?.data);
    },
  });
  useInterval(() => {
    if (gatewayId) pollGetGatewayDetails(gatewayId);
  }, 10000);

  useEffect(() => {
    setDetails(undefined);
    if (gatewayDetailsPollTimeout.current) {
      clearTimeout(gatewayDetailsPollTimeout.current);
    }
    if (gatewayId) {
      callGetGatewayDetails(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  const refresh = () => {
    if (gatewayId) callGetGatewayDetails(gatewayId);
  };

  return {
    details,
    loading,
    error,
    refresh,
  };
};
