import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import * as API from '../api/bacnet';
import { RequestError } from '../api/utils/RequestError';
import { usePrepareRequest } from './useRequest';

export type UseDeviceScan = {
  deviceScan: API.DeviceScan | undefined;
  loading: boolean;
  error: RequestError | undefined;
  refresh: () => void;
};

export const useDeviceScan = (gatewayId?: string): UseDeviceScan => {
  const [deviceScan, setDeviceScan] = useState<API.DeviceScan>();
  const gatewayDetailsPollTimeout = useRef<NodeJS.Timeout>();

  const [callGetDeviceScan, { loading, error }] = usePrepareRequest(
    API.getDeviceScan,
    {
      onCompleted: (result) => {
        setDeviceScan(result?.data);
      },
    },
  );
  const [pollGetDeviceScan] = usePrepareRequest(API.getDeviceScan, {
    onCompleted: (result) => {
      setDeviceScan(result?.data);
    },
  });

  useEffect(() => {
    setDeviceScan(undefined);
    if (gatewayDetailsPollTimeout.current) {
      clearTimeout(gatewayDetailsPollTimeout.current);
    }
    if (gatewayId) {
      callGetDeviceScan(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useInterval(() => {
    if (gatewayId) pollGetDeviceScan(gatewayId);
  }, 10000);

  const refresh = () => {
    if (gatewayId) callGetDeviceScan(gatewayId);
  };

  return {
    deviceScan,
    loading,
    error,
    refresh,
  };
};
