import React from 'react';
import { IconButton, Menu, MenuItem, MenuList } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Capability } from './DeviceCapabilities';

const useStyles = makeStyles({
  dense: {
    padding: 0,
  },
  list: {
    padding: 0,
    maxHeight: 200,
  },
});

type AssignButtonProps = {
  assignableTo: Capability[];
  assignedTo: Capability;
  onSelectCapability: (c: Capability) => void;
};
export const AssignButton: React.FC<AssignButtonProps> = ({
  assignableTo,
  assignedTo,
  onSelectCapability,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const unassignedCapabilities: Capability[] = assignableTo;
  const assignedCapability: Capability = assignedTo;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleSelectCapability = (capability: Capability) => () => {
    onSelectCapability && onSelectCapability(capability);
    handleClose();
  };

  if (!assignedCapability && !unassignedCapabilities?.length) return null;
  const canEdit = !!unassignedCapabilities;
  return (
    <>
      <IconButton
        aria-label="assign-button"
        data-testid="assign-button"
        onClick={handleClick}
        className={classes.dense}
        disabled={!canEdit}
      >
        {assignedCapability && <Icons.Check fontSize="medium" color="action" />}
        {!assignedCapability && canEdit && (
          <Icons.ArrowBack fontSize="medium" color="action" />
        )}
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        classes={{
          root: classes.dense,
          list: classes.list,
        }}
      >
        <MenuList dense>
          {assignedCapability ? (
            <MenuItem onClick={handleSelectCapability(assignedCapability)}>
              {t('deployments:common.scanResult.action.remove', {
                name: assignedCapability.name,
              })}
            </MenuItem>
          ) : (
            unassignedCapabilities.map((c) => (
              <MenuItem key={c.id} onClick={handleSelectCapability(c)}>
                {c.name}
              </MenuItem>
            ))
          )}
        </MenuList>
      </Menu>
    </>
  );
};
