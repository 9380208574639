import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  Uuid: string;
};

export enum AggregationIntervals {
  D_01 = 'D_01',
  D_02 = 'D_02',
  D_03 = 'D_03',
  D_04 = 'D_04',
  D_05 = 'D_05',
  D_06 = 'D_06',
  D_07 = 'D_07',
  H_01 = 'H_01',
  H_02 = 'H_02',
  H_03 = 'H_03',
  H_04 = 'H_04',
  H_06 = 'H_06',
  H_08 = 'H_08',
  H_12 = 'H_12',
  Ms_500 = 'MS_500',
  M_01 = 'M_01',
  M_02 = 'M_02',
  M_03 = 'M_03',
  M_04 = 'M_04',
  M_05 = 'M_05',
  M_06 = 'M_06',
  M_10 = 'M_10',
  M_12 = 'M_12',
  M_15 = 'M_15',
  M_20 = 'M_20',
  M_30 = 'M_30',
  None = 'NONE',
  S_01 = 'S_01',
  S_02 = 'S_02',
  S_03 = 'S_03',
  S_04 = 'S_04',
  S_05 = 'S_05',
  S_06 = 'S_06',
  S_10 = 'S_10',
  S_12 = 'S_12',
  S_15 = 'S_15',
  S_20 = 'S_20',
  S_30 = 'S_30'
}

export enum AggregationTypes {
  Avg = 'AVG',
  Sum = 'SUM'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type Attribute = {
  __typename?: 'Attribute';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};


export type AttributeDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type AttributeMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type AttributeCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
};

export type AttributeDefinitionFilterInput = {
  and?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
};

export type AttributeFilterInput = {
  and?: InputMaybe<Array<AttributeFilterInput>>;
  attributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AttributeSetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeSortInput = {
  attributeId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type AttributeUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeWithValue = {
  __typename?: 'AttributeWithValue';
  attribute?: Maybe<Attribute>;
  value?: Maybe<Scalars['String']>;
};

export type AttributesByBuildings = {
  __typename?: 'AttributesByBuildings';
  buildingId?: Maybe<Scalars['Uuid']>;
  deviceAttributes?: Maybe<Array<AttributeDefinition>>;
};

export type AttributesByBuildingsFilterInput = {
  and?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
  buildingId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceAttributes?: InputMaybe<ListFilterInputTypeOfAttributeDefinitionFilterInput>;
  or?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
};

export type AttributesByBuildingsSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
};

export type AuthorizeDirective = {
  __typename?: 'AuthorizeDirective';
  apply: ApplyPolicy;
  policy?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type Building = {
  __typename?: 'Building';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Uuid']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Uuid']>;
  mappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  name: Scalars['String'];
  site: Site;
  siteId: Scalars['Uuid'];
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['Uuid']>;
};


/** I am a generated type! */
export type BuildingStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};

export type BuildingCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Uuid']>;
  locationId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  siteId: Scalars['Uuid'];
};

export type BuildingFilterInput = {
  and?: InputMaybe<Array<BuildingFilterInput>>;
  buildingId?: InputMaybe<ComparableGuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  mappingDeviceBuildings?: InputMaybe<ListFilterInputTypeOfMappingDeviceBuildingFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuildingFilterInput>>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<ComparableGuidOperationFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type BuildingSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type BuildingUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  imageId?: InputMaybe<Scalars['Uuid']>;
  locationId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['Uuid']>;
};

export type Capability = {
  __typename?: 'Capability';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
};


export type CapabilityDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type CapabilityCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type CapabilityFilterInput = {
  and?: InputMaybe<Array<CapabilityFilterInput>>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CapabilityFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type CapabilitySetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilitySortInput = {
  capabilityId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CapabilityUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<Scalars['Uuid']>>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<Scalars['Uuid']>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export enum ConnectionStates {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Unknown = 'UNKNOWN'
}

export type DataPoint = {
  __typename?: 'DataPoint';
  device?: Maybe<Device>;
  deviceId: Scalars['Uuid'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['Uuid'];
  tenantId: Scalars['Uuid'];
  utcTimeMeasured: Scalars['DateTime'];
  valueString?: Maybe<Scalars['String']>;
};

export enum DataTypes {
  Bool = 'BOOL',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Double = 'DOUBLE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  NotSpecified = 'NOT_SPECIFIED',
  String = 'STRING'
}

export type DataTypesOperationFilterInput = {
  eq?: InputMaybe<DataTypes>;
  in?: InputMaybe<Array<DataTypes>>;
  neq?: InputMaybe<DataTypes>;
  nin?: InputMaybe<Array<DataTypes>>;
};

export enum DeleteE2ETestDataResult {
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export enum DemoSiteType {
  Cube = 'CUBE',
  Dstrct = 'DSTRCT',
  Edge = 'EDGE',
  KpHeadquarters = 'KP_HEADQUARTERS',
  Ship = 'SHIP'
}

/** I am a generated type! */
export type Device = {
  __typename?: 'Device';
  connectionState?: Maybe<ConnectionStates>;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceModel: DeviceModel;
  deviceModelId: Scalars['Uuid'];
  fieldDeviceId?: Maybe<Scalars['String']>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  id: Scalars['Uuid'];
  inverseParentDevice: Array<Device>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['Uuid']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};


/** I am a generated type! */
export type DeviceDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


/** I am a generated type! */
export type DeviceInverseParentDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceAndCapabilityInfo = {
  __typename?: 'DeviceAndCapabilityInfo';
  device?: Maybe<Device>;
  deviceId: Scalars['Uuid'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['Uuid'];
  telemetryRecords?: Maybe<Array<Maybe<TelemetryRecord>>>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type DeviceAttributeValue = {
  __typename?: 'DeviceAttributeValue';
  attribute: Attribute;
  attributeId: Scalars['Uuid'];
  device: Device;
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
  value?: Maybe<Scalars['String']>;
};

export type DeviceAttributeValueCreateType = {
  attributeId: Scalars['Uuid'];
  deviceId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceAttributeValueFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceAttributeValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type DeviceAttributeValueSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeValueSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceAttributeValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeValueUpdateType = {
  attributeId?: InputMaybe<Scalars['Uuid']>;
  deviceId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceCreate = {
  __typename?: 'DeviceCreate';
  description?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelId?: Maybe<Scalars['ID']>;
  fieldDeviceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['Uuid']>;
  serialNo?: Maybe<Scalars['String']>;
};

export type DeviceCreateAttributeValueType = {
  attributeId: Scalars['Uuid'];
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateInputType = {
  description?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=100 */
  fieldDeviceId?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateType = {
  description?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=100 */
  fieldDeviceId?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceFilterInput = {
  and?: InputMaybe<Array<DeviceFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  fieldDeviceId?: InputMaybe<StringOperationFilterInput>;
  gatewayDeviceConnections?: InputMaybe<ListFilterInputTypeOfGatewayDeviceConnectionFilterInput>;
  inverseParentDevice?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  parentDevice?: InputMaybe<DeviceFilterInput>;
  parentDeviceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceMappingReturnType = {
  __typename?: 'DeviceMappingReturnType';
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  placementId: Scalars['Uuid'];
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  dataMeasurementTimestampFieldSelector?: Maybe<Scalars['String']>;
  dataMeasurementTimestampFormat?: Maybe<Scalars['String']>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['Uuid'];
  devices: Array<Device>;
  fieldDeviceIdFieldSelector?: Maybe<Scalars['String']>;
  gatewayReceivedDataTimestampFieldSelector?: Maybe<Scalars['String']>;
  gatewayReceivedDataTimestampFormat?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
};


export type DeviceModelDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type DeviceModelDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type DeviceModelDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceModelAttributeSet = {
  __typename?: 'DeviceModelAttributeSet';
  deviceModel: DeviceModel;
  deviceModelId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
};


export type DeviceModelAttributeSetMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type DeviceModelAttributeSetCreateType = {
  deviceModelId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceModelAttributeSetSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelAttributeSetUpdateType = {
  deviceModelId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type DeviceModelCapability = {
  __typename?: 'DeviceModelCapability';
  capability: Capability;
  capabilityId: Scalars['Uuid'];
  deviceModel: DeviceModel;
  deviceModelId: Scalars['Uuid'];
  fieldSelector?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  tenantId?: Maybe<Scalars['Uuid']>;
  unit: Unit;
  unitId: Scalars['Uuid'];
};

export type DeviceModelCapabilityCreateType = {
  capabilityId: Scalars['Uuid'];
  deviceModelId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['Uuid'];
};

export type DeviceModelCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelCapabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  fieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  technicalMax?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  technicalMin?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type DeviceModelCapabilityIdsInput = {
  deviceModelCapabilityId?: InputMaybe<Scalars['Uuid']>;
};

export type DeviceModelCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldSelector?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelCapabilityId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  fieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
};

export type DeviceModelCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['Uuid']>;
  deviceModelId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Uuid']>;
};

export type DeviceModelCreateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  deviceTypeId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=50 */
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelFilterInput = {
  and?: InputMaybe<Array<DeviceModelFilterInput>>;
  dataMeasurementTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  dataMeasurementTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  deviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfDeviceModelAttributeSetFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  devices?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  fieldDeviceIdFieldSelector?: InputMaybe<StringOperationFilterInput>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceModelSetNullInputType = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  devices?: InputMaybe<Scalars['Boolean']>;
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelSortInput = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<SortEnumType>;
  dataMeasurementTimestampFormat?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  fieldDeviceIdFieldSelector?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelUpdateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=50 */
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type DeviceSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldDeviceId?: InputMaybe<Scalars['Boolean']>;
  gatewayDeviceConnections?: InputMaybe<Scalars['Boolean']>;
  inverseParentDevice?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['Boolean']>;
  serialNo?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceSortInput = {
  description?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelId?: InputMaybe<SortEnumType>;
  fieldDeviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentDevice?: InputMaybe<DeviceSortInput>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  deviceModels: Array<DeviceModel>;
  id: Scalars['Uuid'];
  isGateway?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
};


export type DeviceTypeDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};

export type DeviceTypeCreateType = {
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceTypeFilterInput = {
  and?: InputMaybe<Array<DeviceTypeFilterInput>>;
  deviceModels?: InputMaybe<ListFilterInputTypeOfDeviceModelFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGateway?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceTypeSetNullInputType = {
  deviceModels?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeSortInput = {
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGateway?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceTypeUpdateType = {
  id: Scalars['Uuid'];
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type DeviceUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=100 */
  fieldDeviceId?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentDeviceId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceWithAttributeValues = {
  __typename?: 'DeviceWithAttributeValues';
  attributeValues?: Maybe<Array<Maybe<AttributeWithValue>>>;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  deviceId: Scalars['Uuid'];
  deviceModel?: Maybe<DeviceModel>;
  deviceModelId: Scalars['Uuid'];
  fieldDeviceId?: Maybe<Scalars['String']>;
  gatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  inverseParentDevice?: Maybe<Array<Maybe<Device>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['Uuid']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type DevicesByBuilding = {
  __typename?: 'DevicesByBuilding';
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['ID']>;
  device?: Maybe<Device>;
  deviceAttributeValues?: Maybe<Array<ValueEntity>>;
  deviceDescription?: Maybe<Scalars['String']>;
  deviceFieldDeviceId?: Maybe<Scalars['String']>;
  deviceGatewayName?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['ID']>;
  deviceModelName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['Uuid']>;
  deviceTypeName?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Uuid']>;
  storeyId?: Maybe<Scalars['Uuid']>;
  zoneId?: Maybe<Scalars['Uuid']>;
};

export type DevicesByBuildingFilterInput = {
  and?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  buildingId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfValueEntityFilterInput>;
  deviceDescription?: InputMaybe<StringOperationFilterInput>;
  deviceFieldDeviceId?: InputMaybe<StringOperationFilterInput>;
  deviceGatewayName?: InputMaybe<StringOperationFilterInput>;
  deviceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceModelName?: InputMaybe<StringOperationFilterInput>;
  deviceName?: InputMaybe<StringOperationFilterInput>;
  deviceSerialNumber?: InputMaybe<StringOperationFilterInput>;
  deviceTypeId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  qStat?: InputMaybe<QueryStatisticsFilterInput>;
  siteId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  storeyId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zoneId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DevicesByBuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceDescription?: InputMaybe<SortEnumType>;
  deviceFieldDeviceId?: InputMaybe<SortEnumType>;
  deviceGatewayName?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceModelName?: InputMaybe<SortEnumType>;
  deviceName?: InputMaybe<SortEnumType>;
  deviceSerialNumber?: InputMaybe<SortEnumType>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  deviceTypeName?: InputMaybe<SortEnumType>;
  qStat?: InputMaybe<QueryStatisticsSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnection = {
  __typename?: 'GatewayDeviceConnection';
  device: Device;
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  primaryAuthenticationKey: Scalars['String'];
  primaryConnectionString?: Maybe<Scalars['String']>;
  secondaryAuthenticationKey: Scalars['String'];
  secondaryConnectionString?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type GatewayDeviceConnectionCreateType = {
  deviceId: Scalars['Uuid'];
  /** MinLength=0, MaxLength=100 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

export type GatewayDeviceConnectionFilterInput = {
  and?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  primaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  primaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  secondaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  secondaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GatewayDeviceConnectionSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  primaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  secondaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type GatewayDeviceConnectionSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnectionId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  primaryAuthenticationKey?: InputMaybe<SortEnumType>;
  primaryConnectionString?: InputMaybe<SortEnumType>;
  secondaryAuthenticationKey?: InputMaybe<SortEnumType>;
  secondaryConnectionString?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnectionUpdateType = {
  deviceId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=100 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

/** I am a generated type! */
export type Image = {
  __typename?: 'Image';
  buildings: Array<Building>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceImages: Array<MappingDeviceImage>;
  name: Scalars['String'];
  sites: Array<Site>;
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['Uuid']>;
  url?: Maybe<Scalars['String']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type ImageBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type ImageMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


/** I am a generated type! */
export type ImageSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};


/** I am a generated type! */
export type ImageStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};


/** I am a generated type! */
export type ImageZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type ImageCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImages?: InputMaybe<ListFilterInputTypeOfMappingDeviceImageFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type ImageSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceImages?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type ImageSortInput = {
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type ImageUpdateType = {
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

export type ListFilterInputTypeOfAttributeDefinitionFilterInput = {
  all?: InputMaybe<AttributeDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AttributeDefinitionFilterInput>;
  some?: InputMaybe<AttributeDefinitionFilterInput>;
};

export type ListFilterInputTypeOfBuildingFilterInput = {
  all?: InputMaybe<BuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BuildingFilterInput>;
  some?: InputMaybe<BuildingFilterInput>;
};

export type ListFilterInputTypeOfDeviceAttributeValueFilterInput = {
  all?: InputMaybe<DeviceAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceAttributeValueFilterInput>;
  some?: InputMaybe<DeviceAttributeValueFilterInput>;
};

export type ListFilterInputTypeOfDeviceFilterInput = {
  all?: InputMaybe<DeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFilterInput>;
  some?: InputMaybe<DeviceFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelCapabilityFilterInput = {
  all?: InputMaybe<DeviceModelCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelCapabilityFilterInput>;
  some?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelFilterInput = {
  all?: InputMaybe<DeviceModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelFilterInput>;
  some?: InputMaybe<DeviceModelFilterInput>;
};

export type ListFilterInputTypeOfGatewayDeviceConnectionFilterInput = {
  all?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  some?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceBuildingFilterInput = {
  all?: InputMaybe<MappingDeviceBuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceBuildingFilterInput>;
  some?: InputMaybe<MappingDeviceBuildingFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceImageFilterInput = {
  all?: InputMaybe<MappingDeviceImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceImageFilterInput>;
  some?: InputMaybe<MappingDeviceImageFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceZoneFilterInput = {
  all?: InputMaybe<MappingDeviceZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceZoneFilterInput>;
  some?: InputMaybe<MappingDeviceZoneFilterInput>;
};

export type ListFilterInputTypeOfSiteFilterInput = {
  all?: InputMaybe<SiteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SiteFilterInput>;
  some?: InputMaybe<SiteFilterInput>;
};

export type ListFilterInputTypeOfStoreyFilterInput = {
  all?: InputMaybe<StoreyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreyFilterInput>;
  some?: InputMaybe<StoreyFilterInput>;
};

export type ListFilterInputTypeOfValueEntityFilterInput = {
  all?: InputMaybe<ValueEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ValueEntityFilterInput>;
  some?: InputMaybe<ValueEntityFilterInput>;
};

export type ListFilterInputTypeOfZoneFilterInput = {
  all?: InputMaybe<ZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ZoneFilterInput>;
  some?: InputMaybe<ZoneFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type Location = {
  __typename?: 'Location';
  buildings: Array<Building>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  no?: Maybe<Scalars['String']>;
  sites: Array<Site>;
  street?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['Uuid']>;
  zip?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type LocationBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type LocationSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};

export type LocationCreateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

export type LocationFilterInput = {
  and?: InputMaybe<Array<LocationFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  county?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lat?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  locationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  lon?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  no?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  street?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['Boolean']>;
  county?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  no?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  street?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type LocationSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  county?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lat?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  lon?: InputMaybe<SortEnumType>;
  no?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type LocationUpdateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

export type MappingAttributeDeviceModelAttributeSet = {
  __typename?: 'MappingAttributeDeviceModelAttributeSet';
  attribute: Attribute;
  attributeId: Scalars['Uuid'];
  deviceModelAttributeSet: DeviceModelAttributeSet;
  deviceModelAttributeSetId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type MappingAttributeDeviceModelAttributeSetCreateType = {
  attributeId: Scalars['Uuid'];
  deviceModelAttributeSetId: Scalars['Uuid'];
};

export type MappingAttributeDeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingAttributeDeviceModelAttributeSetUpdateType = {
  attributeId?: InputMaybe<Scalars['Uuid']>;
  deviceModelAttributeSetId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
};

/** I am a generated type! */
export type MappingDeviceBuilding = {
  __typename?: 'MappingDeviceBuilding';
  building: Building;
  buildingId: Scalars['Uuid'];
  device?: Maybe<Device>;
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type MappingDeviceBuildingCreateType = {
  buildingId: Scalars['Uuid'];
  deviceId: Scalars['Uuid'];
};

export type MappingDeviceBuildingFilterInput = {
  and?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceBuildingId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingDeviceBuildingSetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceBuildingSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceBuildingId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceBuildingUpdateType = {
  buildingId?: InputMaybe<Scalars['Uuid']>;
  deviceId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
};

/** I am a generated type! */
export type MappingDeviceImage = {
  __typename?: 'MappingDeviceImage';
  device?: Maybe<Device>;
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  image: Image;
  imageId: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};

export type MappingDeviceImageCreateType = {
  deviceId: Scalars['Uuid'];
  imageId: Scalars['Uuid'];
};

export type MappingDeviceImageFilterInput = {
  and?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingDeviceImageSetNullInputType = {
  image?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceImageSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceImageId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceImageUpdateType = {
  deviceId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  imageId?: InputMaybe<Scalars['Uuid']>;
};

/** I am a generated type! */
export type MappingDeviceZone = {
  __typename?: 'MappingDeviceZone';
  device?: Maybe<Device>;
  deviceId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['Uuid']>;
  zone: Zone;
  zoneId: Scalars['Uuid'];
};

export type MappingDeviceZoneCreateType = {
  deviceId: Scalars['Uuid'];
  zoneId: Scalars['Uuid'];
};

export type MappingDeviceZoneFilterInput = {
  and?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZoneId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zone?: InputMaybe<ZoneFilterInput>;
  zoneId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type MappingDeviceZoneSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zone?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceZoneSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceZoneId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zone?: InputMaybe<ZoneSortInput>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceZoneUpdateType = {
  deviceId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  zoneId?: InputMaybe<Scalars['Uuid']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cleanupGatewayDevicesFromIoTHub: Scalars['String'];
  createAttribute?: Maybe<Attribute>;
  createAttributes?: Maybe<Array<Maybe<Attribute>>>;
  createBuilding?: Maybe<Building>;
  createBuildings?: Maybe<Array<Maybe<Building>>>;
  createCapabilities?: Maybe<Array<Maybe<Capability>>>;
  createCapability?: Maybe<Capability>;
  createDeviceModel?: Maybe<DeviceModel>;
  createDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  createDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  createDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  createDeviceModelCapability?: Maybe<DeviceModelCapability>;
  createDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  createDeviceType?: Maybe<DeviceType>;
  createDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  createDeviceWithPlacement: DeviceCreate;
  createGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  createGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  createImage?: Maybe<Image>;
  createImages?: Maybe<Array<Maybe<Image>>>;
  createLocation?: Maybe<Location>;
  createLocations?: Maybe<Array<Maybe<Location>>>;
  createMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  createMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  createMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  createMappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  createMappingDeviceImage?: Maybe<MappingDeviceImage>;
  createMappingDeviceImages?: Maybe<Array<Maybe<MappingDeviceImage>>>;
  createMappingDeviceZone?: Maybe<MappingDeviceZone>;
  createMappingDeviceZones?: Maybe<Array<Maybe<MappingDeviceZone>>>;
  createSite?: Maybe<Site>;
  createSites?: Maybe<Array<Maybe<Site>>>;
  createStorey?: Maybe<Storey>;
  createStoreys?: Maybe<Array<Maybe<Storey>>>;
  createUnit?: Maybe<Unit>;
  createUnits: Array<Maybe<Unit>>;
  createZone?: Maybe<Zone>;
  createZones: Array<Maybe<Zone>>;
  deleteAttribute?: Maybe<Attribute>;
  deleteAttributes?: Maybe<Array<Maybe<Attribute>>>;
  deleteBuilding?: Maybe<Building>;
  deleteBuildings?: Maybe<Array<Maybe<Building>>>;
  deleteCapabilities?: Maybe<Array<Maybe<Capability>>>;
  deleteCapability?: Maybe<Capability>;
  deleteDevice?: Maybe<Device>;
  deleteDeviceModel?: Maybe<DeviceModel>;
  deleteDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deleteDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  deleteDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  deleteDeviceModelCapability?: Maybe<DeviceModelCapability>;
  deleteDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  deleteDeviceType?: Maybe<DeviceType>;
  deleteDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  deleteDevices?: Maybe<Array<Maybe<Device>>>;
  deleteDevicesByGatewayId: DeleteE2ETestDataResult;
  deleteGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  deleteGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  deleteImage?: Maybe<Image>;
  deleteImages?: Maybe<Array<Maybe<Image>>>;
  deleteLocation?: Maybe<Location>;
  deleteLocations?: Maybe<Array<Maybe<Location>>>;
  deleteMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  deleteMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  deleteMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  deleteMappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  deleteMappingDeviceImage?: Maybe<MappingDeviceImage>;
  deleteMappingDeviceImages?: Maybe<Array<Maybe<MappingDeviceImage>>>;
  deleteMappingDeviceZone?: Maybe<MappingDeviceZone>;
  deleteMappingDeviceZones?: Maybe<Array<Maybe<MappingDeviceZone>>>;
  deleteSite?: Maybe<Site>;
  deleteSites?: Maybe<Array<Maybe<Site>>>;
  deleteStorey?: Maybe<Storey>;
  deleteStoreys?: Maybe<Array<Maybe<Storey>>>;
  deleteUnit?: Maybe<Unit>;
  deleteUnits: Array<Maybe<Unit>>;
  deleteZone?: Maybe<Zone>;
  deleteZones: Array<Maybe<Zone>>;
  exportIoTDevicesToBlobStorage: Scalars['String'];
  rebuildDeviceMappingsInStorageTable: Scalars['String'];
  redoCreateGatewayDeviceNotification?: Maybe<Scalars['String']>;
  regenerateGatewayDeviceConnectionKey?: Maybe<GatewayDeviceConnection>;
  regenerateGatewayDeviceConnectionsKeys?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  regenerateTenantGatewayDeviceConnectionsKeys?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  removeDeletedDeviceMappingsInStorageTable: Scalars['String'];
  setDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  unsetDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  updateAttribute?: Maybe<Attribute>;
  updateAttributes?: Maybe<Array<Maybe<Attribute>>>;
  updateBuilding?: Maybe<Building>;
  updateBuildings?: Maybe<Array<Maybe<Building>>>;
  updateCapabilities?: Maybe<Array<Maybe<Capability>>>;
  updateCapability?: Maybe<Capability>;
  updateDevice?: Maybe<Device>;
  updateDeviceModel?: Maybe<DeviceModel>;
  updateDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  updateDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  updateDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  updateDeviceModelCapability?: Maybe<DeviceModelCapability>;
  updateDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  updateDeviceType?: Maybe<DeviceType>;
  updateDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  updateDevices?: Maybe<Array<Maybe<Device>>>;
  updateGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  updateGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  updateImage?: Maybe<Image>;
  updateImages?: Maybe<Array<Maybe<Image>>>;
  updateLocation?: Maybe<Location>;
  updateLocations?: Maybe<Array<Maybe<Location>>>;
  updateMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  updateMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  updateMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  updateMappingDeviceBuildingToBuilding?: Maybe<MappingDeviceBuilding>;
  updateMappingDeviceBuildingToZone?: Maybe<MappingDeviceZone>;
  updateMappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  updateMappingDeviceImage?: Maybe<MappingDeviceImage>;
  updateMappingDeviceImages?: Maybe<Array<Maybe<MappingDeviceImage>>>;
  updateMappingDeviceZone?: Maybe<MappingDeviceZone>;
  updateMappingDeviceZoneToBuilding?: Maybe<MappingDeviceBuilding>;
  updateMappingDeviceZoneToZone?: Maybe<MappingDeviceZone>;
  updateMappingDeviceZones?: Maybe<Array<Maybe<MappingDeviceZone>>>;
  updatePlacementOfDevice: DeviceMappingReturnType;
  updateSite?: Maybe<Site>;
  updateSites?: Maybe<Array<Maybe<Site>>>;
  updateStorey?: Maybe<Storey>;
  updateStoreys?: Maybe<Array<Maybe<Storey>>>;
  updateUnit: Unit;
  updateUnits: Array<Unit>;
  updateZone: Zone;
  updateZones: Array<Zone>;
};


export type MutationCleanupGatewayDevicesFromIoTHubArgs = {
  tenantIds?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationCreateAttributeArgs = {
  attribute?: InputMaybe<AttributeCreateType>;
};


export type MutationCreateAttributesArgs = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeCreateType>>>;
};


export type MutationCreateBuildingArgs = {
  building?: InputMaybe<BuildingCreateType>;
};


export type MutationCreateBuildingsArgs = {
  buildings?: InputMaybe<Array<InputMaybe<BuildingCreateType>>>;
};


export type MutationCreateCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CapabilityCreateType>>>;
};


export type MutationCreateCapabilityArgs = {
  capability?: InputMaybe<CapabilityCreateType>;
};


export type MutationCreateDeviceModelArgs = {
  deviceModel?: InputMaybe<DeviceModelCreateType>;
};


export type MutationCreateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetCreateType>;
};


export type MutationCreateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets?: InputMaybe<Array<InputMaybe<DeviceModelAttributeSetCreateType>>>;
};


export type MutationCreateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityCreateType>>>;
};


export type MutationCreateDeviceModelCapabilityArgs = {
  deviceModelCapability?: InputMaybe<DeviceModelCapabilityCreateType>;
};


export type MutationCreateDeviceModelsArgs = {
  deviceModels?: InputMaybe<Array<InputMaybe<DeviceModelCreateType>>>;
};


export type MutationCreateDeviceTypeArgs = {
  deviceType?: InputMaybe<DeviceTypeCreateType>;
};


export type MutationCreateDeviceTypesArgs = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceTypeCreateType>>>;
};


export type MutationCreateDeviceWithPlacementArgs = {
  attributeValues: Array<InputMaybe<DeviceCreateAttributeValueType>>;
  device: DeviceCreateInputType;
  placementId: Scalars['Uuid'];
  placementType: PlacementKind;
};


export type MutationCreateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionCreateType>;
};


export type MutationCreateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections?: InputMaybe<Array<InputMaybe<GatewayDeviceConnectionCreateType>>>;
};


export type MutationCreateImageArgs = {
  image?: InputMaybe<ImageCreateType>;
};


export type MutationCreateImagesArgs = {
  images?: InputMaybe<Array<InputMaybe<ImageCreateType>>>;
};


export type MutationCreateLocationArgs = {
  location?: InputMaybe<LocationCreateType>;
};


export type MutationCreateLocationsArgs = {
  locations?: InputMaybe<Array<InputMaybe<LocationCreateType>>>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<MappingAttributeDeviceModelAttributeSetCreateType>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Array<InputMaybe<MappingAttributeDeviceModelAttributeSetCreateType>>>;
};


export type MutationCreateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding?: InputMaybe<MappingDeviceBuildingCreateType>;
};


export type MutationCreateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings?: InputMaybe<Array<InputMaybe<MappingDeviceBuildingCreateType>>>;
};


export type MutationCreateMappingDeviceImageArgs = {
  mappingDeviceImage?: InputMaybe<MappingDeviceImageCreateType>;
};


export type MutationCreateMappingDeviceImagesArgs = {
  mappingDeviceImages?: InputMaybe<Array<InputMaybe<MappingDeviceImageCreateType>>>;
};


export type MutationCreateMappingDeviceZoneArgs = {
  mappingDeviceZone?: InputMaybe<MappingDeviceZoneCreateType>;
};


export type MutationCreateMappingDeviceZonesArgs = {
  mappingDeviceZones?: InputMaybe<Array<InputMaybe<MappingDeviceZoneCreateType>>>;
};


export type MutationCreateSiteArgs = {
  site?: InputMaybe<SiteCreateType>;
};


export type MutationCreateSitesArgs = {
  sites?: InputMaybe<Array<InputMaybe<SiteCreateType>>>;
};


export type MutationCreateStoreyArgs = {
  storey?: InputMaybe<StoreyCreateType>;
};


export type MutationCreateStoreysArgs = {
  storeys?: InputMaybe<Array<InputMaybe<StoreyCreateType>>>;
};


export type MutationCreateUnitArgs = {
  unit: UnitCreateType;
};


export type MutationCreateUnitsArgs = {
  units: Array<UnitCreateType>;
};


export type MutationCreateZoneArgs = {
  zone: ZoneCreateType;
};


export type MutationCreateZonesArgs = {
  zones: Array<ZoneCreateType>;
};


export type MutationDeleteAttributeArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteAttributesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteBuildingsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteCapabilitiesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteCapabilityArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceModelArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceModelAttributeSetArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceModelAttributeSetsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteDeviceModelCapabilitiesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteDeviceModelCapabilityArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceModelsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteDeviceTypeArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteDeviceTypesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteDevicesArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteDevicesByGatewayIdArgs = {
  gatewayId: Scalars['Uuid'];
};


export type MutationDeleteGatewayDeviceConnectionArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteGatewayDeviceConnectionsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteImageArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteImagesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteMappingDeviceBuildingArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteMappingDeviceBuildingsArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteMappingDeviceImageArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteMappingDeviceImagesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteMappingDeviceZoneArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteMappingDeviceZonesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteSiteArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteSitesArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteStoreyArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteStoreysArgs = {
  ids?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationDeleteUnitArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteUnitsArgs = {
  ids: Array<Scalars['Uuid']>;
};


export type MutationDeleteZoneArgs = {
  id: Scalars['Uuid'];
};


export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['Uuid']>;
};


export type MutationExportIoTDevicesToBlobStorageArgs = {
  blobName: Scalars['String'];
};


export type MutationRebuildDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationRedoCreateGatewayDeviceNotificationArgs = {
  deviceId: Scalars['Uuid'];
};


export type MutationRegenerateGatewayDeviceConnectionKeyArgs = {
  deviceId: Scalars['Uuid'];
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceConnectionsKeysArgs = {
  deviceIds?: InputMaybe<Array<Scalars['Uuid']>>;
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateTenantGatewayDeviceConnectionsKeysArgs = {
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRemoveDeletedDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['Uuid']>>;
};


export type MutationSetDeviceAttributeValueArgs = {
  attributeId: Scalars['Uuid'];
  deviceId: Scalars['Uuid'];
  value?: InputMaybe<Scalars['String']>;
};


export type MutationUnsetDeviceAttributeValueArgs = {
  attributeId: Scalars['Uuid'];
  deviceId: Scalars['Uuid'];
};


export type MutationUpdateAttributeArgs = {
  attribute?: InputMaybe<AttributeUpdateType>;
};


export type MutationUpdateAttributesArgs = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeUpdateType>>>;
};


export type MutationUpdateBuildingArgs = {
  building?: InputMaybe<BuildingUpdateType>;
};


export type MutationUpdateBuildingsArgs = {
  buildings?: InputMaybe<Array<InputMaybe<BuildingUpdateType>>>;
};


export type MutationUpdateCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CapabilityUpdateType>>>;
};


export type MutationUpdateCapabilityArgs = {
  capability?: InputMaybe<CapabilityUpdateType>;
};


export type MutationUpdateDeviceArgs = {
  device?: InputMaybe<DeviceUpdateType>;
};


export type MutationUpdateDeviceModelArgs = {
  deviceModel?: InputMaybe<DeviceModelUpdateType>;
};


export type MutationUpdateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets?: InputMaybe<Array<InputMaybe<DeviceModelAttributeSetUpdateType>>>;
};


export type MutationUpdateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityUpdateType>>>;
};


export type MutationUpdateDeviceModelCapabilityArgs = {
  deviceModelCapability?: InputMaybe<DeviceModelCapabilityUpdateType>;
};


export type MutationUpdateDeviceModelsArgs = {
  deviceModels?: InputMaybe<Array<InputMaybe<DeviceModelUpdateType>>>;
};


export type MutationUpdateDeviceTypeArgs = {
  deviceType?: InputMaybe<DeviceTypeUpdateType>;
};


export type MutationUpdateDeviceTypesArgs = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceTypeUpdateType>>>;
};


export type MutationUpdateDevicesArgs = {
  devices?: InputMaybe<Array<InputMaybe<DeviceUpdateType>>>;
};


export type MutationUpdateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionUpdateType>;
};


export type MutationUpdateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections?: InputMaybe<Array<InputMaybe<GatewayDeviceConnectionUpdateType>>>;
};


export type MutationUpdateImageArgs = {
  image?: InputMaybe<ImageUpdateType>;
};


export type MutationUpdateImagesArgs = {
  images?: InputMaybe<Array<InputMaybe<ImageUpdateType>>>;
};


export type MutationUpdateLocationArgs = {
  location?: InputMaybe<LocationUpdateType>;
};


export type MutationUpdateLocationsArgs = {
  locations?: InputMaybe<Array<InputMaybe<LocationUpdateType>>>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<MappingAttributeDeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Array<InputMaybe<MappingAttributeDeviceModelAttributeSetUpdateType>>>;
};


export type MutationUpdateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding?: InputMaybe<MappingDeviceBuildingUpdateType>;
};


export type MutationUpdateMappingDeviceBuildingToBuildingArgs = {
  mappingDeviceBuildingToBuildingUpdate?: InputMaybe<MappingDeviceBuildingUpdateType>;
};


export type MutationUpdateMappingDeviceBuildingToZoneArgs = {
  mappingDeviceBuildingToZoneUpdate?: InputMaybe<MappingDeviceZoneCreateType>;
};


export type MutationUpdateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings?: InputMaybe<Array<InputMaybe<MappingDeviceBuildingUpdateType>>>;
};


export type MutationUpdateMappingDeviceImageArgs = {
  mappingDeviceImage?: InputMaybe<MappingDeviceImageUpdateType>;
};


export type MutationUpdateMappingDeviceImagesArgs = {
  mappingDeviceImages?: InputMaybe<Array<InputMaybe<MappingDeviceImageUpdateType>>>;
};


export type MutationUpdateMappingDeviceZoneArgs = {
  mappingDeviceZone?: InputMaybe<MappingDeviceZoneUpdateType>;
};


export type MutationUpdateMappingDeviceZoneToBuildingArgs = {
  mappingDeviceZoneToBuildingUpdate?: InputMaybe<MappingDeviceBuildingCreateType>;
};


export type MutationUpdateMappingDeviceZoneToZoneArgs = {
  mappingDeviceZoneToZoneUpdate?: InputMaybe<MappingDeviceZoneUpdateType>;
};


export type MutationUpdateMappingDeviceZonesArgs = {
  mappingDeviceZones?: InputMaybe<Array<InputMaybe<MappingDeviceZoneUpdateType>>>;
};


export type MutationUpdatePlacementOfDeviceArgs = {
  deviceId: Scalars['Uuid'];
  placementId: Scalars['Uuid'];
  placementType: PlacementKind;
};


export type MutationUpdateSiteArgs = {
  site?: InputMaybe<SiteUpdateType>;
};


export type MutationUpdateSitesArgs = {
  sites?: InputMaybe<Array<InputMaybe<SiteUpdateType>>>;
};


export type MutationUpdateStoreyArgs = {
  storey?: InputMaybe<StoreyUpdateType>;
};


export type MutationUpdateStoreysArgs = {
  storeys?: InputMaybe<Array<InputMaybe<StoreyUpdateType>>>;
};


export type MutationUpdateUnitArgs = {
  unit: UnitUpdateType;
};


export type MutationUpdateUnitsArgs = {
  units: Array<UnitUpdateType>;
};


export type MutationUpdateZoneArgs = {
  zone: ZoneUpdateType;
};


export type MutationUpdateZonesArgs = {
  zones: Array<ZoneUpdateType>;
};

export type PlacementFilterRow = {
  __typename?: 'PlacementFilterRow';
  buildingId?: Maybe<Scalars['Uuid']>;
  deviceId?: Maybe<Scalars['Uuid']>;
  locationId?: Maybe<Scalars['Uuid']>;
  mappingPlacementId?: Maybe<Scalars['Uuid']>;
  siteId?: Maybe<Scalars['Uuid']>;
  storeyId?: Maybe<Scalars['Uuid']>;
  zoneId?: Maybe<Scalars['Uuid']>;
};

export type PlacementFilterRowFilterInput = {
  and?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  buildingId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  mappingPlacementId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  or?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  siteId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  storeyId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zoneId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export enum PlacementKind {
  Building = 'BUILDING',
  Zone = 'ZONE'
}

export type PlacementOfDevice = {
  __typename?: 'PlacementOfDevice';
  buildingCity?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['Uuid']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingStreet?: Maybe<Scalars['String']>;
  buildingZip?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['Uuid']>;
  locationCity?: Maybe<Scalars['String']>;
  locationCountry?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Uuid']>;
  mappingPlacementId?: Maybe<Scalars['Uuid']>;
  mappingPlacementType?: Maybe<Scalars['String']>;
  siteCity?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Uuid']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteStreet?: Maybe<Scalars['String']>;
  siteZip?: Maybe<Scalars['String']>;
  storeyId?: Maybe<Scalars['Uuid']>;
  storeyName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['Uuid']>;
  zoneName?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  attribute?: Maybe<Attribute>;
  attributes: Array<Attribute>;
  /**
   * Queries all attributes assigned to devices in a building
   * @deprecated Renamed to GetAttributesByBuildings
   */
  attributesByBuilding: Array<Maybe<AttributesByBuildings>>;
  /** Queries all attributes assigned to devices in a building */
  attributesByBuildings: Array<Maybe<AttributesByBuildings>>;
  building?: Maybe<Building>;
  buildingFilterTable: Array<Maybe<PlacementFilterRow>>;
  buildingSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  buildings: Array<Building>;
  capabilities: Array<Capability>;
  capability?: Maybe<Capability>;
  createDemoSite: Scalars['Boolean'];
  device?: Maybe<Device>;
  deviceAttributeValue?: Maybe<DeviceAttributeValue>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModels: Array<DeviceModel>;
  deviceType?: Maybe<DeviceType>;
  deviceTypes: Array<DeviceType>;
  deviceWithAttributes?: Maybe<DeviceWithAttributeValues>;
  devices: Array<Device>;
  /** Queries all devices in a building */
  devicesByBuildings: Array<Maybe<DevicesByBuilding>>;
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  image?: Maybe<Image>;
  images: Array<Image>;
  latestSensorData?: Maybe<Array<Maybe<DataPoint>>>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  mappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  mappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  mappingDeviceBuildings: Array<MappingDeviceBuilding>;
  mappingDeviceImage?: Maybe<MappingDeviceImage>;
  mappingDeviceImages: Array<MappingDeviceImage>;
  mappingDeviceZone?: Maybe<MappingDeviceZone>;
  mappingDeviceZones: Array<MappingDeviceZone>;
  multipleDeviceSensorData?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  placementOfDevice?: Maybe<PlacementOfDevice>;
  query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  sensorData?: Maybe<Array<Maybe<DataPoint>>>;
  site?: Maybe<Site>;
  sites: Array<Site>;
  storey?: Maybe<Storey>;
  storeys: Array<Storey>;
  unit?: Maybe<Unit>;
  units: Array<Unit>;
  zone?: Maybe<Zone>;
  zoneAndBuildingFilterTable: Array<Maybe<PlacementFilterRow>>;
  zoneFilterTable: Array<Maybe<PlacementFilterRow>>;
  zones: Array<Zone>;
};


export type QueryAttributeArgs = {
  id: Scalars['Uuid'];
};


export type QueryAttributesArgs = {
  order?: InputMaybe<Array<AttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributeFilterInput>;
};


export type QueryAttributesByBuildingArgs = {
  order?: InputMaybe<Array<AttributesByBuildingsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributesByBuildingsFilterInput>;
};


export type QueryAttributesByBuildingsArgs = {
  order?: InputMaybe<Array<AttributesByBuildingsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributesByBuildingsFilterInput>;
};


export type QueryBuildingArgs = {
  id: Scalars['Uuid'];
};


export type QueryBuildingFilterTableArgs = {
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


export type QueryCapabilitiesArgs = {
  order?: InputMaybe<Array<CapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CapabilityFilterInput>;
};


export type QueryCapabilityArgs = {
  id: Scalars['Uuid'];
};


export type QueryCreateDemoSiteArgs = {
  demoSiteType: DemoSiteType;
};


export type QueryDeviceArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceAttributeValueArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type QueryDeviceModelArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceModelAttributeSetArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type QueryDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type QueryDeviceModelCapabilityArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


export type QueryDeviceTypeArgs = {
  id: Scalars['Uuid'];
};


export type QueryDeviceTypesArgs = {
  order?: InputMaybe<Array<DeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTypeFilterInput>;
};


export type QueryDeviceWithAttributesArgs = {
  id: Scalars['Uuid'];
};


export type QueryDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDevicesByBuildingsArgs = {
  order?: InputMaybe<Array<DevicesByBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
};


export type QueryGatewayDeviceConnectionArgs = {
  id: Scalars['Uuid'];
};


export type QueryGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


export type QueryImageArgs = {
  id: Scalars['Uuid'];
};


export type QueryImagesArgs = {
  order?: InputMaybe<Array<ImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilterInput>;
};


export type QueryLatestSensorDataArgs = {
  deviceId: Scalars['Uuid'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['Uuid']>>;
  limitPerCapability?: InputMaybe<Scalars['Int']>;
};


export type QueryLocationArgs = {
  id: Scalars['Uuid'];
};


export type QueryLocationsArgs = {
  order?: InputMaybe<Array<LocationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationFilterInput>;
};


export type QueryMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['Uuid'];
};


export type QueryMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};


export type QueryMappingDeviceBuildingArgs = {
  id: Scalars['Uuid'];
};


export type QueryMappingDeviceBuildingsArgs = {
  order?: InputMaybe<Array<MappingDeviceBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceBuildingFilterInput>;
};


export type QueryMappingDeviceImageArgs = {
  id: Scalars['Uuid'];
};


export type QueryMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


export type QueryMappingDeviceZoneArgs = {
  id: Scalars['Uuid'];
};


export type QueryMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};


export type QueryMultipleDeviceSensorDataArgs = {
  where?: InputMaybe<QueryFiltersMultipleInput>;
};


export type QueryPlacementOfDeviceArgs = {
  deviceId: Scalars['Uuid'];
};


export type QuerySensorDataArgs = {
  where?: InputMaybe<QueryFiltersInput>;
};


export type QuerySiteArgs = {
  id: Scalars['Uuid'];
};


export type QuerySitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};


export type QueryStoreyArgs = {
  id: Scalars['Uuid'];
};


export type QueryStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};


export type QueryUnitArgs = {
  id: Scalars['Uuid'];
};


export type QueryUnitsArgs = {
  order?: InputMaybe<Array<UnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryZoneArgs = {
  id: Scalars['Uuid'];
};


export type QueryZoneAndBuildingFilterTableArgs = {
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZoneFilterTableArgs = {
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type QueryFiltersInput = {
  deviceId?: InputMaybe<Scalars['Uuid']>;
  deviceModelCapabilityId?: InputMaybe<Scalars['Uuid']>;
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
};

export type QueryFiltersMultipleDeviceAndCapabilityInput = {
  deviceId: Scalars['Uuid'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>>>;
};

export type QueryFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  queryFiltersMultipleDeviceAndCapability?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryStatisticsFilterInput = {
  and?: InputMaybe<Array<QueryStatisticsFilterInput>>;
  filtered_item_count?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  httpTraceIdentifier?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QueryStatisticsFilterInput>>;
  returned_item_count?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  skip?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  take?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  total_item_count?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type QueryStatisticsOutputType = {
  __typename?: 'QueryStatisticsOutputType';
  filtered_item_count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  returned_item_count?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  total_item_count?: Maybe<Scalars['Int']>;
};

export type QueryStatisticsSortInput = {
  filtered_item_count?: InputMaybe<SortEnumType>;
  httpTraceIdentifier?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  returned_item_count?: InputMaybe<SortEnumType>;
  skip?: InputMaybe<SortEnumType>;
  take?: InputMaybe<SortEnumType>;
  total_item_count?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type Site = {
  __typename?: 'Site';
  buildings: Array<Building>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Uuid']>;
  inverseParentSite: Array<Site>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Uuid']>;
  name: Scalars['String'];
  parentSite?: Maybe<Site>;
  parentSiteId?: Maybe<Scalars['Uuid']>;
  tenantId?: Maybe<Scalars['Uuid']>;
};


/** I am a generated type! */
export type SiteBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type SiteInverseParentSiteArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};

export type SiteCreateType = {
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Uuid']>;
  locationId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentSiteId?: InputMaybe<Scalars['Uuid']>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  inverseParentSite?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  parentSite?: InputMaybe<SiteFilterInput>;
  parentSiteId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  siteId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type SiteSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentSite?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  parentSite?: InputMaybe<Scalars['Boolean']>;
  parentSiteId?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type SiteSortInput = {
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentSite?: InputMaybe<SiteSortInput>;
  parentSiteId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type SiteUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  imageId?: InputMaybe<Scalars['Uuid']>;
  locationId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentSiteId?: InputMaybe<Scalars['Uuid']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** I am a generated type! */
export type Storey = {
  __typename?: 'Storey';
  building: Building;
  buildingId: Scalars['Uuid'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Uuid']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type StoreyZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type StoreyCreateType = {
  buildingId: Scalars['Uuid'];
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type StoreyFilterInput = {
  and?: InputMaybe<Array<StoreyFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<ComparableGuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoreyFilterInput>>;
  storeyId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type StoreySetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type StoreySortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type StoreyUpdateType = {
  buildingId?: InputMaybe<Scalars['Uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  imageId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TelemetryRecord = {
  __typename?: 'TelemetryRecord';
  utcTimeMeasured: Scalars['DateTime'];
  valueString?: Maybe<Scalars['String']>;
};

export type TimeAndAggregationParameter = {
  __typename?: 'TimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType: AggregationTypes;
  deviceAndCapabilityInfos?: Maybe<Array<Maybe<DeviceAndCapabilityInfo>>>;
};

export enum TimeSpans {
  Last_1Hour = 'LAST_1_HOUR',
  Last_2Hour = 'LAST_2_HOUR',
  Last_4Hour = 'LAST_4_HOUR',
  Last_7Day = 'LAST_7_DAY',
  Last_12Hour = 'LAST_12_HOUR',
  Last_15Min = 'LAST_15_MIN',
  Last_24Hour = 'LAST_24_HOUR',
  Last_30Day = 'LAST_30_DAY',
  Last_30Min = 'LAST_30_MIN',
  Last_90Day = 'LAST_90_DAY'
}

export type Unit = {
  __typename?: 'Unit';
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['Uuid'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['Uuid']>;
  unitSymbol?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  uris?: Maybe<Array<Scalars['String']>>;
};


export type UnitDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type UnitCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  uri?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

export type UnitFilterInput = {
  and?: InputMaybe<Array<UnitFilterInput>>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  uri?: InputMaybe<StringOperationFilterInput>;
  uris?: InputMaybe<ListStringOperationFilterInput>;
};

export type UnitSetNullInputType = {
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unitSymbol?: InputMaybe<Scalars['Boolean']>;
  uri?: InputMaybe<Scalars['Boolean']>;
  uris?: InputMaybe<Scalars['Boolean']>;
};

export type UnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
  uri?: InputMaybe<SortEnumType>;
};

export type UnitUpdateType = {
  id: Scalars['Uuid'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  uri?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

export type ValueEntity = {
  __typename?: 'ValueEntity';
  value?: Maybe<Scalars['String']>;
};

export type ValueEntityFilterInput = {
  and?: InputMaybe<Array<ValueEntityFilterInput>>;
  or?: InputMaybe<Array<ValueEntityFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type Zone = {
  __typename?: 'Zone';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['Uuid']>;
  inverseParentZone: Array<Zone>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceZones: Array<MappingDeviceZone>;
  name: Scalars['String'];
  parentZone?: Maybe<Zone>;
  parentZoneId?: Maybe<Scalars['Uuid']>;
  storey: Storey;
  storeyId: Scalars['Uuid'];
  tenantId?: Maybe<Scalars['Uuid']>;
};


/** I am a generated type! */
export type ZoneInverseParentZoneArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};


/** I am a generated type! */
export type ZoneMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};

export type ZoneCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentZoneId?: InputMaybe<Scalars['Uuid']>;
  storeyId: Scalars['Uuid'];
};

export type ZoneFilterInput = {
  and?: InputMaybe<Array<ZoneFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  inverseParentZone?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZones?: InputMaybe<ListFilterInputTypeOfMappingDeviceZoneFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ZoneFilterInput>>;
  parentZone?: InputMaybe<ZoneFilterInput>;
  parentZoneId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  storey?: InputMaybe<StoreyFilterInput>;
  storeyId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  zoneId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ZoneSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentZone?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceZones?: InputMaybe<Scalars['Boolean']>;
  parentZone?: InputMaybe<Scalars['Boolean']>;
  parentZoneId?: InputMaybe<Scalars['Boolean']>;
  storey?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type ZoneSortInput = {
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentZone?: InputMaybe<ZoneSortInput>;
  parentZoneId?: InputMaybe<SortEnumType>;
  storey?: InputMaybe<StoreySortInput>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type ZoneUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  imageId?: InputMaybe<Scalars['Uuid']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentZoneId?: InputMaybe<Scalars['Uuid']>;
  storeyId?: InputMaybe<Scalars['Uuid']>;
};

export type GatewayListQueryVariables = Exact<{ [key: string]: never; }>;


export type GatewayListQuery = { __typename?: 'Query', devices: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, connectionState?: ConnectionStates | null, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> } }>, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> } }> };

export type GatewayCreateGatewayModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GatewayCreateGatewayModelsQuery = { __typename?: 'Query', deviceModels: Array<{ __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null }, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', name: string, id: string, required?: boolean | null, dataType: DataTypes } }> }> }>, sites: Array<{ __typename?: 'Site', id: string, name: string, buildings: Array<{ __typename?: 'Building', id: string, name: string, siteId: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, buildingId: string, zones: Array<{ __typename?: 'Zone', id: string, name: string, storeyId: string }> }> }> }> };

export type GatewayCreateWithPlacementMutationVariables = Exact<{
  device: DeviceCreateInputType;
  placementId: Scalars['Uuid'];
  placementType: PlacementKind;
  attributeValues: Array<InputMaybe<DeviceCreateAttributeValueType>> | InputMaybe<DeviceCreateAttributeValueType>;
}>;


export type GatewayCreateWithPlacementMutation = { __typename?: 'Mutation', createDeviceWithPlacement: { __typename?: 'DeviceCreate', id?: string | null } };

export type SiteListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<SiteFilterInput>;
}>;


export type SiteListQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: string, name: string, locationId?: string | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null }>, buildingSchema_query_statistics?: Array<{ __typename?: 'QueryStatisticsOutputType', filtered_item_count?: number | null } | null> | null, query_statistics?: Array<{ __typename?: 'QueryStatisticsOutputType', filtered_item_count?: number | null } | null> | null };

export type DeploymentsGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['Uuid'];
}>;


export type DeploymentsGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, description?: string | null, fieldDeviceId?: string | null, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, serialNo?: string | null, fieldDeviceId?: string | null, parentDeviceId?: string | null, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceTypeId: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null } } }>, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', zoneName?: string | null, buildingName?: string | null, locationCity?: string | null, locationCountry?: string | null, siteName?: string | null, storeyName?: string | null } | null };

export type DeviceCreateFieldDeviceModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceCreateFieldDeviceModelsQuery = { __typename?: 'Query', deviceModels: Array<{ __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null }, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', name: string, id: string, required?: boolean | null, dataType: DataTypes } }> }> }> };

export type DeviceCreateFieldDeviceIdExistsQueryVariables = Exact<{
  fieldDeviceId: Scalars['String'];
  parentDeviceId: Scalars['Uuid'];
}>;


export type DeviceCreateFieldDeviceIdExistsQuery = { __typename?: 'Query', devices: Array<{ __typename?: 'Device', id: string, fieldDeviceId?: string | null, parentDeviceId?: string | null }> };

export type DeviceCreateGatewayQueryVariables = Exact<{
  gatewayId: Scalars['Uuid'];
}>;


export type DeviceCreateGatewayQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null } } } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', mappingPlacementId?: string | null, mappingPlacementType?: string | null, zoneName?: string | null, zoneId?: string | null, buildingName?: string | null, buildingId?: string | null, locationCity?: string | null, locationCountry?: string | null, siteName?: string | null, storeyName?: string | null } | null };

export type DeviceCreateWithPlacementMutationVariables = Exact<{
  device: DeviceCreateInputType;
  placementId: Scalars['Uuid'];
  placementType: PlacementKind;
  attributeValues: Array<InputMaybe<DeviceCreateAttributeValueType>> | InputMaybe<DeviceCreateAttributeValueType>;
}>;


export type DeviceCreateWithPlacementMutation = { __typename?: 'Mutation', createDeviceWithPlacement: { __typename?: 'DeviceCreate', id?: string | null } };

export type DeviceModelCreateGatewayAndDeviceTypesQueryVariables = Exact<{
  gatewayId: Scalars['Uuid'];
}>;


export type DeviceModelCreateGatewayAndDeviceTypesQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null } } } | null, deviceTypes: Array<{ __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }> };

export type DeviceModelCreateMutationVariables = Exact<{
  deviceModel: DeviceModelCreateType;
}>;


export type DeviceModelCreateMutation = { __typename?: 'Mutation', createDeviceModel?: { __typename?: 'DeviceModel', id: string } | null };

export type CapabilitiesAndUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type CapabilitiesAndUnitsQuery = { __typename?: 'Query', capabilities: Array<{ __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }>, units: Array<{ __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null }> };

export type DeviceModelCapabilityCreateMutationVariables = Exact<{
  deviceModelCapabilityInput: DeviceModelCapabilityCreateType;
}>;


export type DeviceModelCapabilityCreateMutation = { __typename?: 'Mutation', createDeviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string } | null };

export type DeviceModelCapabilityDeleteMutationVariables = Exact<{
  deviceModelCapabilityId: Scalars['Uuid'];
}>;


export type DeviceModelCapabilityDeleteMutation = { __typename?: 'Mutation', deleteDeviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string } | null };

export type DeviceModelsListQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceModelsListQuery = { __typename?: 'Query', deviceModels: Array<{ __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, fieldDeviceIdFieldSelector?: string | null, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMin?: number | null, technicalMax?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> }> };

export type DeviceModelDetailsQueryVariables = Exact<{
  deviceModelId: Scalars['Uuid'];
}>;


export type DeviceModelDetailsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, fieldDeviceIdFieldSelector?: string | null, isGlobal?: boolean | null, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMin?: number | null, technicalMax?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } | null };

export type ModelEditorGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['Uuid'];
}>;


export type ModelEditorGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, description?: string | null, fieldDeviceId?: string | null, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, serialNo?: string | null, fieldDeviceId?: string | null, parentDeviceId?: string | null, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceTypeId: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null } } }>, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', zoneName?: string | null, buildingName?: string | null, locationCity?: string | null, locationCountry?: string | null, siteName?: string | null, storeyName?: string | null } | null };

export type DeviceDataQueryVariables = Exact<{
  deviceId: Scalars['Uuid'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type DeviceDataQuery = { __typename?: 'Query', latestSensorData?: Array<{ __typename?: 'DataPoint', deviceId: string, deviceModelCapabilityId: string, utcTimeMeasured: any, valueString?: string | null } | null> | null };

export type MonitoringGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['Uuid'];
}>;


export type MonitoringGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, description?: string | null, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, fieldDeviceId?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } }> } | null };


export const GatewayListDocument = gql`
    query GatewayList {
  devices(
    where: {and: [{deviceModel: {deviceType: {isGateway: {eq: true}}}}]}
    order: {name: ASC}
  ) {
    id
    name
    description
    connectionState
    inverseParentDevice {
      id
      name
      deviceModel {
        deviceModelCapabilities {
          id
        }
      }
    }
    deviceModel {
      id
      name
      deviceType {
        id
        name
        isGateway
      }
      deviceModelCapabilities {
        id
      }
    }
  }
}
    `;

/**
 * __useGatewayListQuery__
 *
 * To run a query within a React component, call `useGatewayListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGatewayListQuery(baseOptions?: Apollo.QueryHookOptions<GatewayListQuery, GatewayListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayListQuery, GatewayListQueryVariables>(GatewayListDocument, options);
      }
export function useGatewayListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayListQuery, GatewayListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayListQuery, GatewayListQueryVariables>(GatewayListDocument, options);
        }
export type GatewayListQueryHookResult = ReturnType<typeof useGatewayListQuery>;
export type GatewayListLazyQueryHookResult = ReturnType<typeof useGatewayListLazyQuery>;
export type GatewayListQueryResult = Apollo.QueryResult<GatewayListQuery, GatewayListQueryVariables>;
export const GatewayCreateGatewayModelsDocument = gql`
    query GatewayCreateGatewayModels {
  deviceModels(where: {deviceType: {isGateway: {eq: true}}}) {
    id
    name
    deviceType {
      isGateway
    }
    deviceModelAttributeSets {
      id
      name
      mappingAttributeDeviceModelAttributeSets {
        attribute {
          name
          id
          required
          dataType
        }
      }
    }
  }
  sites {
    id
    name
    buildings {
      id
      name
      siteId
      storeys {
        id
        name
        buildingId
        zones {
          id
          name
          storeyId
        }
      }
    }
  }
}
    `;

/**
 * __useGatewayCreateGatewayModelsQuery__
 *
 * To run a query within a React component, call `useGatewayCreateGatewayModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayCreateGatewayModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayCreateGatewayModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGatewayCreateGatewayModelsQuery(baseOptions?: Apollo.QueryHookOptions<GatewayCreateGatewayModelsQuery, GatewayCreateGatewayModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayCreateGatewayModelsQuery, GatewayCreateGatewayModelsQueryVariables>(GatewayCreateGatewayModelsDocument, options);
      }
export function useGatewayCreateGatewayModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayCreateGatewayModelsQuery, GatewayCreateGatewayModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayCreateGatewayModelsQuery, GatewayCreateGatewayModelsQueryVariables>(GatewayCreateGatewayModelsDocument, options);
        }
export type GatewayCreateGatewayModelsQueryHookResult = ReturnType<typeof useGatewayCreateGatewayModelsQuery>;
export type GatewayCreateGatewayModelsLazyQueryHookResult = ReturnType<typeof useGatewayCreateGatewayModelsLazyQuery>;
export type GatewayCreateGatewayModelsQueryResult = Apollo.QueryResult<GatewayCreateGatewayModelsQuery, GatewayCreateGatewayModelsQueryVariables>;
export const GatewayCreateWithPlacementDocument = gql`
    mutation GatewayCreateWithPlacement($device: DeviceCreateInputType!, $placementId: Uuid!, $placementType: PlacementKind!, $attributeValues: [DeviceCreateAttributeValueType]!) {
  createDeviceWithPlacement(
    device: $device
    placementId: $placementId
    placementType: $placementType
    attributeValues: $attributeValues
  ) {
    id
  }
}
    `;
export type GatewayCreateWithPlacementMutationFn = Apollo.MutationFunction<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>;

/**
 * __useGatewayCreateWithPlacementMutation__
 *
 * To run a mutation, you first call `useGatewayCreateWithPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewayCreateWithPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewayCreateWithPlacementMutation, { data, loading, error }] = useGatewayCreateWithPlacementMutation({
 *   variables: {
 *      device: // value for 'device'
 *      placementId: // value for 'placementId'
 *      placementType: // value for 'placementType'
 *      attributeValues: // value for 'attributeValues'
 *   },
 * });
 */
export function useGatewayCreateWithPlacementMutation(baseOptions?: Apollo.MutationHookOptions<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>(GatewayCreateWithPlacementDocument, options);
      }
export type GatewayCreateWithPlacementMutationHookResult = ReturnType<typeof useGatewayCreateWithPlacementMutation>;
export type GatewayCreateWithPlacementMutationResult = Apollo.MutationResult<GatewayCreateWithPlacementMutation>;
export type GatewayCreateWithPlacementMutationOptions = Apollo.BaseMutationOptions<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>;
export const SiteListDocument = gql`
    query SiteList($skip: Int!, $take: Int!, $where: SiteFilterInput) {
  sites(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    id
    name
    locationId
    location {
      id
      street
      no
      zip
      city
      county
      country
    }
  }
  buildingSchema_query_statistics {
    filtered_item_count
  }
  query_statistics {
    filtered_item_count
  }
}
    `;

/**
 * __useSiteListQuery__
 *
 * To run a query within a React component, call `useSiteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSiteListQuery(baseOptions: Apollo.QueryHookOptions<SiteListQuery, SiteListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteListQuery, SiteListQueryVariables>(SiteListDocument, options);
      }
export function useSiteListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteListQuery, SiteListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteListQuery, SiteListQueryVariables>(SiteListDocument, options);
        }
export type SiteListQueryHookResult = ReturnType<typeof useSiteListQuery>;
export type SiteListLazyQueryHookResult = ReturnType<typeof useSiteListLazyQuery>;
export type SiteListQueryResult = Apollo.QueryResult<SiteListQuery, SiteListQueryVariables>;
export const DeploymentsGatewayDetailsDocument = gql`
    query DeploymentsGatewayDetails($gatewayId: Uuid!) {
  device(id: $gatewayId) {
    id
    name
    description
    fieldDeviceId
    inverseParentDevice {
      id
      name
      description
      serialNo
      fieldDeviceId
      parentDeviceId
      deviceModelId
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          fieldSelector
          technicalMax
          technicalMin
          capability {
            id
            name
            description
            dataType
          }
          unit {
            id
            name
            unitSymbol
          }
        }
        deviceTypeId
        deviceType {
          id
          name
          isGateway
        }
      }
    }
    deviceModel {
      id
      name
      deviceType {
        id
        name
        isGateway
      }
      deviceModelCapabilities {
        id
        fieldSelector
        technicalMax
        technicalMin
        capability {
          id
          name
          description
          dataType
        }
        unit {
          id
          name
          unitSymbol
        }
      }
    }
  }
  placementOfDevice(deviceId: $gatewayId) {
    zoneName
    buildingName
    locationCity
    locationCountry
    siteName
    storeyName
  }
}
    `;

/**
 * __useDeploymentsGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useDeploymentsGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useDeploymentsGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>(DeploymentsGatewayDetailsDocument, options);
      }
export function useDeploymentsGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>(DeploymentsGatewayDetailsDocument, options);
        }
export type DeploymentsGatewayDetailsQueryHookResult = ReturnType<typeof useDeploymentsGatewayDetailsQuery>;
export type DeploymentsGatewayDetailsLazyQueryHookResult = ReturnType<typeof useDeploymentsGatewayDetailsLazyQuery>;
export type DeploymentsGatewayDetailsQueryResult = Apollo.QueryResult<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>;
export const DeviceCreateFieldDeviceModelsDocument = gql`
    query DeviceCreateFieldDeviceModels {
  deviceModels(where: {deviceType: {isGateway: {eq: false}}}) {
    id
    name
    deviceType {
      isGateway
    }
    deviceModelAttributeSets {
      id
      name
      mappingAttributeDeviceModelAttributeSets {
        attribute {
          name
          id
          required
          dataType
        }
      }
    }
  }
}
    `;

/**
 * __useDeviceCreateFieldDeviceModelsQuery__
 *
 * To run a query within a React component, call `useDeviceCreateFieldDeviceModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCreateFieldDeviceModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCreateFieldDeviceModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceCreateFieldDeviceModelsQuery(baseOptions?: Apollo.QueryHookOptions<DeviceCreateFieldDeviceModelsQuery, DeviceCreateFieldDeviceModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceCreateFieldDeviceModelsQuery, DeviceCreateFieldDeviceModelsQueryVariables>(DeviceCreateFieldDeviceModelsDocument, options);
      }
export function useDeviceCreateFieldDeviceModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceCreateFieldDeviceModelsQuery, DeviceCreateFieldDeviceModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceCreateFieldDeviceModelsQuery, DeviceCreateFieldDeviceModelsQueryVariables>(DeviceCreateFieldDeviceModelsDocument, options);
        }
export type DeviceCreateFieldDeviceModelsQueryHookResult = ReturnType<typeof useDeviceCreateFieldDeviceModelsQuery>;
export type DeviceCreateFieldDeviceModelsLazyQueryHookResult = ReturnType<typeof useDeviceCreateFieldDeviceModelsLazyQuery>;
export type DeviceCreateFieldDeviceModelsQueryResult = Apollo.QueryResult<DeviceCreateFieldDeviceModelsQuery, DeviceCreateFieldDeviceModelsQueryVariables>;
export const DeviceCreateFieldDeviceIdExistsDocument = gql`
    query DeviceCreateFieldDeviceIdExists($fieldDeviceId: String!, $parentDeviceId: Uuid!) {
  devices(
    where: {fieldDeviceId: {eq: $fieldDeviceId}, parentDeviceId: {eq: $parentDeviceId}}
  ) {
    id
    fieldDeviceId
    parentDeviceId
  }
}
    `;

/**
 * __useDeviceCreateFieldDeviceIdExistsQuery__
 *
 * To run a query within a React component, call `useDeviceCreateFieldDeviceIdExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCreateFieldDeviceIdExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCreateFieldDeviceIdExistsQuery({
 *   variables: {
 *      fieldDeviceId: // value for 'fieldDeviceId'
 *      parentDeviceId: // value for 'parentDeviceId'
 *   },
 * });
 */
export function useDeviceCreateFieldDeviceIdExistsQuery(baseOptions: Apollo.QueryHookOptions<DeviceCreateFieldDeviceIdExistsQuery, DeviceCreateFieldDeviceIdExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceCreateFieldDeviceIdExistsQuery, DeviceCreateFieldDeviceIdExistsQueryVariables>(DeviceCreateFieldDeviceIdExistsDocument, options);
      }
export function useDeviceCreateFieldDeviceIdExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceCreateFieldDeviceIdExistsQuery, DeviceCreateFieldDeviceIdExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceCreateFieldDeviceIdExistsQuery, DeviceCreateFieldDeviceIdExistsQueryVariables>(DeviceCreateFieldDeviceIdExistsDocument, options);
        }
export type DeviceCreateFieldDeviceIdExistsQueryHookResult = ReturnType<typeof useDeviceCreateFieldDeviceIdExistsQuery>;
export type DeviceCreateFieldDeviceIdExistsLazyQueryHookResult = ReturnType<typeof useDeviceCreateFieldDeviceIdExistsLazyQuery>;
export type DeviceCreateFieldDeviceIdExistsQueryResult = Apollo.QueryResult<DeviceCreateFieldDeviceIdExistsQuery, DeviceCreateFieldDeviceIdExistsQueryVariables>;
export const DeviceCreateGatewayDocument = gql`
    query DeviceCreateGateway($gatewayId: Uuid!) {
  device(id: $gatewayId) {
    id
    name
    deviceModel {
      deviceType {
        isGateway
      }
    }
  }
  placementOfDevice(deviceId: $gatewayId) {
    mappingPlacementId
    mappingPlacementType
    zoneName
    zoneId
    buildingName
    buildingId
    locationCity
    locationCountry
    siteName
    storeyName
  }
}
    `;

/**
 * __useDeviceCreateGatewayQuery__
 *
 * To run a query within a React component, call `useDeviceCreateGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCreateGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCreateGatewayQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useDeviceCreateGatewayQuery(baseOptions: Apollo.QueryHookOptions<DeviceCreateGatewayQuery, DeviceCreateGatewayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceCreateGatewayQuery, DeviceCreateGatewayQueryVariables>(DeviceCreateGatewayDocument, options);
      }
export function useDeviceCreateGatewayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceCreateGatewayQuery, DeviceCreateGatewayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceCreateGatewayQuery, DeviceCreateGatewayQueryVariables>(DeviceCreateGatewayDocument, options);
        }
export type DeviceCreateGatewayQueryHookResult = ReturnType<typeof useDeviceCreateGatewayQuery>;
export type DeviceCreateGatewayLazyQueryHookResult = ReturnType<typeof useDeviceCreateGatewayLazyQuery>;
export type DeviceCreateGatewayQueryResult = Apollo.QueryResult<DeviceCreateGatewayQuery, DeviceCreateGatewayQueryVariables>;
export const DeviceCreateWithPlacementDocument = gql`
    mutation DeviceCreateWithPlacement($device: DeviceCreateInputType!, $placementId: Uuid!, $placementType: PlacementKind!, $attributeValues: [DeviceCreateAttributeValueType]!) {
  createDeviceWithPlacement(
    device: $device
    placementId: $placementId
    placementType: $placementType
    attributeValues: $attributeValues
  ) {
    id
  }
}
    `;
export type DeviceCreateWithPlacementMutationFn = Apollo.MutationFunction<DeviceCreateWithPlacementMutation, DeviceCreateWithPlacementMutationVariables>;

/**
 * __useDeviceCreateWithPlacementMutation__
 *
 * To run a mutation, you first call `useDeviceCreateWithPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceCreateWithPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceCreateWithPlacementMutation, { data, loading, error }] = useDeviceCreateWithPlacementMutation({
 *   variables: {
 *      device: // value for 'device'
 *      placementId: // value for 'placementId'
 *      placementType: // value for 'placementType'
 *      attributeValues: // value for 'attributeValues'
 *   },
 * });
 */
export function useDeviceCreateWithPlacementMutation(baseOptions?: Apollo.MutationHookOptions<DeviceCreateWithPlacementMutation, DeviceCreateWithPlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceCreateWithPlacementMutation, DeviceCreateWithPlacementMutationVariables>(DeviceCreateWithPlacementDocument, options);
      }
export type DeviceCreateWithPlacementMutationHookResult = ReturnType<typeof useDeviceCreateWithPlacementMutation>;
export type DeviceCreateWithPlacementMutationResult = Apollo.MutationResult<DeviceCreateWithPlacementMutation>;
export type DeviceCreateWithPlacementMutationOptions = Apollo.BaseMutationOptions<DeviceCreateWithPlacementMutation, DeviceCreateWithPlacementMutationVariables>;
export const DeviceModelCreateGatewayAndDeviceTypesDocument = gql`
    query DeviceModelCreateGatewayAndDeviceTypes($gatewayId: Uuid!) {
  device(id: $gatewayId) {
    id
    name
    deviceModel {
      deviceType {
        isGateway
      }
    }
  }
  deviceTypes(where: {isGateway: {eq: false}}) {
    id
    name
    isGateway
  }
}
    `;

/**
 * __useDeviceModelCreateGatewayAndDeviceTypesQuery__
 *
 * To run a query within a React component, call `useDeviceModelCreateGatewayAndDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCreateGatewayAndDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelCreateGatewayAndDeviceTypesQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useDeviceModelCreateGatewayAndDeviceTypesQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelCreateGatewayAndDeviceTypesQuery, DeviceModelCreateGatewayAndDeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelCreateGatewayAndDeviceTypesQuery, DeviceModelCreateGatewayAndDeviceTypesQueryVariables>(DeviceModelCreateGatewayAndDeviceTypesDocument, options);
      }
export function useDeviceModelCreateGatewayAndDeviceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelCreateGatewayAndDeviceTypesQuery, DeviceModelCreateGatewayAndDeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelCreateGatewayAndDeviceTypesQuery, DeviceModelCreateGatewayAndDeviceTypesQueryVariables>(DeviceModelCreateGatewayAndDeviceTypesDocument, options);
        }
export type DeviceModelCreateGatewayAndDeviceTypesQueryHookResult = ReturnType<typeof useDeviceModelCreateGatewayAndDeviceTypesQuery>;
export type DeviceModelCreateGatewayAndDeviceTypesLazyQueryHookResult = ReturnType<typeof useDeviceModelCreateGatewayAndDeviceTypesLazyQuery>;
export type DeviceModelCreateGatewayAndDeviceTypesQueryResult = Apollo.QueryResult<DeviceModelCreateGatewayAndDeviceTypesQuery, DeviceModelCreateGatewayAndDeviceTypesQueryVariables>;
export const DeviceModelCreateDocument = gql`
    mutation DeviceModelCreate($deviceModel: DeviceModelCreateType!) {
  createDeviceModel(deviceModel: $deviceModel) {
    id
  }
}
    `;
export type DeviceModelCreateMutationFn = Apollo.MutationFunction<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>;

/**
 * __useDeviceModelCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCreateMutation, { data, loading, error }] = useDeviceModelCreateMutation({
 *   variables: {
 *      deviceModel: // value for 'deviceModel'
 *   },
 * });
 */
export function useDeviceModelCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>(DeviceModelCreateDocument, options);
      }
export type DeviceModelCreateMutationHookResult = ReturnType<typeof useDeviceModelCreateMutation>;
export type DeviceModelCreateMutationResult = Apollo.MutationResult<DeviceModelCreateMutation>;
export type DeviceModelCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>;
export const CapabilitiesAndUnitsDocument = gql`
    query CapabilitiesAndUnits {
  capabilities(order: {name: ASC}) {
    id
    name
    description
    dataType
  }
  units {
    id
    name
    unitSymbol
  }
}
    `;

/**
 * __useCapabilitiesAndUnitsQuery__
 *
 * To run a query within a React component, call `useCapabilitiesAndUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilitiesAndUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilitiesAndUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCapabilitiesAndUnitsQuery(baseOptions?: Apollo.QueryHookOptions<CapabilitiesAndUnitsQuery, CapabilitiesAndUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilitiesAndUnitsQuery, CapabilitiesAndUnitsQueryVariables>(CapabilitiesAndUnitsDocument, options);
      }
export function useCapabilitiesAndUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilitiesAndUnitsQuery, CapabilitiesAndUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilitiesAndUnitsQuery, CapabilitiesAndUnitsQueryVariables>(CapabilitiesAndUnitsDocument, options);
        }
export type CapabilitiesAndUnitsQueryHookResult = ReturnType<typeof useCapabilitiesAndUnitsQuery>;
export type CapabilitiesAndUnitsLazyQueryHookResult = ReturnType<typeof useCapabilitiesAndUnitsLazyQuery>;
export type CapabilitiesAndUnitsQueryResult = Apollo.QueryResult<CapabilitiesAndUnitsQuery, CapabilitiesAndUnitsQueryVariables>;
export const DeviceModelCapabilityCreateDocument = gql`
    mutation DeviceModelCapabilityCreate($deviceModelCapabilityInput: DeviceModelCapabilityCreateType!) {
  createDeviceModelCapability(deviceModelCapability: $deviceModelCapabilityInput) {
    id
  }
}
    `;
export type DeviceModelCapabilityCreateMutationFn = Apollo.MutationFunction<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>;

/**
 * __useDeviceModelCapabilityCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelCapabilityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCapabilityCreateMutation, { data, loading, error }] = useDeviceModelCapabilityCreateMutation({
 *   variables: {
 *      deviceModelCapabilityInput: // value for 'deviceModelCapabilityInput'
 *   },
 * });
 */
export function useDeviceModelCapabilityCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>(DeviceModelCapabilityCreateDocument, options);
      }
export type DeviceModelCapabilityCreateMutationHookResult = ReturnType<typeof useDeviceModelCapabilityCreateMutation>;
export type DeviceModelCapabilityCreateMutationResult = Apollo.MutationResult<DeviceModelCapabilityCreateMutation>;
export type DeviceModelCapabilityCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>;
export const DeviceModelCapabilityDeleteDocument = gql`
    mutation DeviceModelCapabilityDelete($deviceModelCapabilityId: Uuid!) {
  deleteDeviceModelCapability(id: $deviceModelCapabilityId) {
    id
  }
}
    `;
export type DeviceModelCapabilityDeleteMutationFn = Apollo.MutationFunction<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>;

/**
 * __useDeviceModelCapabilityDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceModelCapabilityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCapabilityDeleteMutation, { data, loading, error }] = useDeviceModelCapabilityDeleteMutation({
 *   variables: {
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useDeviceModelCapabilityDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>(DeviceModelCapabilityDeleteDocument, options);
      }
export type DeviceModelCapabilityDeleteMutationHookResult = ReturnType<typeof useDeviceModelCapabilityDeleteMutation>;
export type DeviceModelCapabilityDeleteMutationResult = Apollo.MutationResult<DeviceModelCapabilityDeleteMutation>;
export type DeviceModelCapabilityDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>;
export const DeviceModelsListDocument = gql`
    query DeviceModelsList {
  deviceModels(where: {deviceType: {isGateway: {eq: false}}}, order: {name: ASC}) {
    id
    name
    isGlobal
    fieldDeviceIdFieldSelector
    deviceType {
      isGateway
    }
    deviceModelCapabilities {
      id
      fieldSelector
      technicalMin
      technicalMax
      capability {
        id
        name
        description
        dataType
      }
      unit {
        id
        name
        unitSymbol
      }
    }
  }
}
    `;

/**
 * __useDeviceModelsListQuery__
 *
 * To run a query within a React component, call `useDeviceModelsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceModelsListQuery(baseOptions?: Apollo.QueryHookOptions<DeviceModelsListQuery, DeviceModelsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelsListQuery, DeviceModelsListQueryVariables>(DeviceModelsListDocument, options);
      }
export function useDeviceModelsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelsListQuery, DeviceModelsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelsListQuery, DeviceModelsListQueryVariables>(DeviceModelsListDocument, options);
        }
export type DeviceModelsListQueryHookResult = ReturnType<typeof useDeviceModelsListQuery>;
export type DeviceModelsListLazyQueryHookResult = ReturnType<typeof useDeviceModelsListLazyQuery>;
export type DeviceModelsListQueryResult = Apollo.QueryResult<DeviceModelsListQuery, DeviceModelsListQueryVariables>;
export const DeviceModelDetailsDocument = gql`
    query DeviceModelDetails($deviceModelId: Uuid!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    fieldDeviceIdFieldSelector
    isGlobal
    deviceType {
      id
      name
      isGateway
    }
    deviceModelCapabilities {
      id
      fieldSelector
      technicalMin
      technicalMax
      capability {
        id
        name
        description
        dataType
      }
      unit {
        id
        name
        unitSymbol
      }
    }
  }
}
    `;

/**
 * __useDeviceModelDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelDetailsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>(DeviceModelDetailsDocument, options);
      }
export function useDeviceModelDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>(DeviceModelDetailsDocument, options);
        }
export type DeviceModelDetailsQueryHookResult = ReturnType<typeof useDeviceModelDetailsQuery>;
export type DeviceModelDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelDetailsLazyQuery>;
export type DeviceModelDetailsQueryResult = Apollo.QueryResult<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>;
export const ModelEditorGatewayDetailsDocument = gql`
    query ModelEditorGatewayDetails($gatewayId: Uuid!) {
  device(id: $gatewayId) {
    id
    name
    description
    fieldDeviceId
    inverseParentDevice {
      id
      name
      description
      serialNo
      fieldDeviceId
      parentDeviceId
      deviceModelId
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          fieldSelector
          technicalMax
          technicalMin
          capability {
            id
            name
            description
            dataType
          }
          unit {
            id
            name
            unitSymbol
          }
        }
        deviceTypeId
        deviceType {
          id
          name
          isGateway
        }
      }
    }
    deviceModel {
      id
      name
      deviceType {
        id
        name
        isGateway
      }
      deviceModelCapabilities {
        id
        fieldSelector
        technicalMax
        technicalMin
        capability {
          id
          name
          description
          dataType
        }
        unit {
          id
          name
          unitSymbol
        }
      }
    }
  }
  placementOfDevice(deviceId: $gatewayId) {
    zoneName
    buildingName
    locationCity
    locationCountry
    siteName
    storeyName
  }
}
    `;

/**
 * __useModelEditorGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useModelEditorGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelEditorGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelEditorGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useModelEditorGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<ModelEditorGatewayDetailsQuery, ModelEditorGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelEditorGatewayDetailsQuery, ModelEditorGatewayDetailsQueryVariables>(ModelEditorGatewayDetailsDocument, options);
      }
export function useModelEditorGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelEditorGatewayDetailsQuery, ModelEditorGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelEditorGatewayDetailsQuery, ModelEditorGatewayDetailsQueryVariables>(ModelEditorGatewayDetailsDocument, options);
        }
export type ModelEditorGatewayDetailsQueryHookResult = ReturnType<typeof useModelEditorGatewayDetailsQuery>;
export type ModelEditorGatewayDetailsLazyQueryHookResult = ReturnType<typeof useModelEditorGatewayDetailsLazyQuery>;
export type ModelEditorGatewayDetailsQueryResult = Apollo.QueryResult<ModelEditorGatewayDetailsQuery, ModelEditorGatewayDetailsQueryVariables>;
export const DeviceDataDocument = gql`
    query DeviceData($deviceId: Uuid!, $deviceModelCapabilityIds: [Uuid!]) {
  latestSensorData(
    deviceId: $deviceId
    deviceModelCapabilityIds: $deviceModelCapabilityIds
    limitPerCapability: 1
  ) {
    deviceId
    deviceModelCapabilityId
    utcTimeMeasured
    valueString
  }
}
    `;

/**
 * __useDeviceDataQuery__
 *
 * To run a query within a React component, call `useDeviceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *   },
 * });
 */
export function useDeviceDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceDataQuery, DeviceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDataQuery, DeviceDataQueryVariables>(DeviceDataDocument, options);
      }
export function useDeviceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDataQuery, DeviceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDataQuery, DeviceDataQueryVariables>(DeviceDataDocument, options);
        }
export type DeviceDataQueryHookResult = ReturnType<typeof useDeviceDataQuery>;
export type DeviceDataLazyQueryHookResult = ReturnType<typeof useDeviceDataLazyQuery>;
export type DeviceDataQueryResult = Apollo.QueryResult<DeviceDataQuery, DeviceDataQueryVariables>;
export const MonitoringGatewayDetailsDocument = gql`
    query MonitoringGatewayDetails($gatewayId: Uuid!) {
  device(id: $gatewayId) {
    id
    name
    description
    inverseParentDevice {
      id
      name
      description
      fieldDeviceId
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          fieldSelector
          technicalMax
          technicalMin
          capability {
            id
            name
            description
            dataType
          }
          unit {
            id
            name
            unitSymbol
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMonitoringGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useMonitoringGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useMonitoringGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<MonitoringGatewayDetailsQuery, MonitoringGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitoringGatewayDetailsQuery, MonitoringGatewayDetailsQueryVariables>(MonitoringGatewayDetailsDocument, options);
      }
export function useMonitoringGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitoringGatewayDetailsQuery, MonitoringGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitoringGatewayDetailsQuery, MonitoringGatewayDetailsQueryVariables>(MonitoringGatewayDetailsDocument, options);
        }
export type MonitoringGatewayDetailsQueryHookResult = ReturnType<typeof useMonitoringGatewayDetailsQuery>;
export type MonitoringGatewayDetailsLazyQueryHookResult = ReturnType<typeof useMonitoringGatewayDetailsLazyQuery>;
export type MonitoringGatewayDetailsQueryResult = Apollo.QueryResult<MonitoringGatewayDetailsQuery, MonitoringGatewayDetailsQueryVariables>;