import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '../../../../components/Autocomplete';
import { PlacementKind } from '../../../../__generated__/types';
import type { GatewayFormLocationData } from './GatewayFormLocation';

type State = {
  site?: string;
  building?: string;
  storey?: string;
  zone?: string;
  placementKind?: PlacementKind;
  placementId?: string;
};

type Action =
  | {
      type: 'reset-with-zone';
      site: string;
      building: string;
      storey: string;
      zone: string;
    }
  | {
      type: 'reset-with-building';
      site: string;
      building: string;
    }
  | { type: 'site'; value: string }
  | { type: 'building'; value: string }
  | { type: 'storey'; value: string }
  | { type: 'zone'; value: string };

const initialState = {};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'site':
      return {
        site: action.value,
        building: undefined,
        storey: undefined,
        zone: undefined,
        placementKind: PlacementKind.Building,
        placementId: undefined,
      };
    case 'building':
      return {
        site: state.site,
        building: action.value,
        storey: undefined,
        zone: undefined,
        placementKind: PlacementKind.Building,
        placementId: action.value,
      };
    case 'storey':
      return {
        site: state.site,
        building: state.building,
        storey: action.value,
        zone: undefined,
        placementKind: PlacementKind.Zone,
        placementId: undefined,
      };
    case 'zone':
      return {
        site: state.site,
        building: state.building,
        storey: state.storey,
        zone: action.value,
        placementKind: PlacementKind.Zone,
        placementId: action.value,
      };
    case 'reset-with-zone':
      return {
        site: action.site,
        building: action.building,
        storey: action.storey,
        zone: action.zone,
        placementKind: PlacementKind.Zone,
        placementId: action.zone,
      };
    case 'reset-with-building':
      return {
        site: action.site,
        building: action.building,
        storey: undefined,
        zone: undefined,
        placementKind: PlacementKind.Building,
        placementId: action.building,
      };
    default:
      return state;
  }
};

const useStyles = makeStyles({
  field: {
    minHeight: '70px',
  },
});

type BasicEntity = { id: string; name: string };
type Zone = BasicEntity & { storeyId: string };
type Storey = BasicEntity & { zones: Array<Zone>; buildingId: string };
type Building = BasicEntity & { storeys: Array<Storey>; siteId: string };
export type Site = BasicEntity & { buildings: Array<Building> };

type GatewayLocationFieldProps = {
  value: string;
  error?: boolean;
  helperText: string;
  sites: Array<Site>;
  locationType?: PlacementKind;
  loading?: boolean;
  setFieldValue: FormikHelpers<GatewayFormLocationData>['setFieldValue'];
};

export const GatewayLocationField: React.FC<GatewayLocationFieldProps> =
  React.memo(
    ({
      value,
      error,
      helperText,
      sites,
      locationType,
      loading,
      setFieldValue,
    }) => {
      const classes = useStyles();
      const { t } = useTranslation(['configuration', 'general']);

      const [
        { site, building, storey, zone, placementId, placementKind },
        dispatch,
      ] = useReducer(reducer, initialState);

      const { buildings, storeys, zones } = useMemo(() => {
        const allBuildings: Building[] = sites
          .map((item) => item.buildings || [])
          .flat();
        const allStoreys: Storey[] = allBuildings
          .map((item) => item.storeys || [])
          .flat();
        const allZones: Zone[] = allStoreys
          .map((item) => item.zones || [])
          .flat();
        return {
          buildings: allBuildings,
          storeys: allStoreys,
          zones: allZones,
        };
      }, [sites]);

      const sitesOptions = useMemo(
        () =>
          (sites || [])
            .map((option) => ({ id: option.id, label: option.name }))
            .concat([
              {
                id: '0',
                label: t(
                  'configuration:gateway.create.deviceLocationField.site',
                ),
              },
            ]),
        [sites, t],
      );

      const buildingsOptions = useMemo(
        () =>
          ((sites || []).find((item) => item.id === site)?.buildings || [])
            .map((option) => ({ id: option.id, label: option.name }))
            .concat([
              {
                id: '0',
                label: t(
                  'configuration:gateway.create.deviceLocationField.building',
                ),
              },
            ]),
        [sites, site, t],
      );

      const storeysOptions = useMemo(
        () =>
          (
            (buildings || []).find((item) => item.id === building)?.storeys ||
            []
          )
            .map((option) => ({ id: option.id, label: option.name }))
            .concat([
              {
                id: '0',
                label: t(
                  'configuration:gateway.create.deviceLocationField.storey',
                ),
              },
            ]),
        [buildings, building, t],
      );

      const zonesOptions = useMemo(
        () =>
          ((storeys || []).find((item) => item.id === storey)?.zones || [])
            .map((option) => ({ id: option.id, label: option.name }))
            .concat([
              {
                id: '0',
                label: t(
                  'configuration:gateway.create.deviceLocationField.zone',
                ),
              },
            ]),
        [storeys, storey, t],
      );

      useEffect(() => {
        const valueZone =
          locationType === PlacementKind.Zone
            ? zones.find(({ id }) => id === value)
            : undefined;
        const valueStorey = storeys.find(
          ({ id }) => id === valueZone?.storeyId,
        );
        const valueBuilding =
          locationType === PlacementKind.Zone
            ? buildings.find(({ id }) => id === valueStorey?.buildingId)
            : buildings.find(({ id }) => id === value);
        const valueSite = sites.find(({ id }) => id === valueBuilding?.siteId);

        if (
          locationType === PlacementKind.Zone &&
          valueSite?.id &&
          valueBuilding?.id &&
          valueStorey?.id &&
          valueZone?.id
        ) {
          dispatch({
            type: 'reset-with-zone',
            site: valueSite.id,
            building: valueBuilding.id,
            storey: valueStorey.id,
            zone: valueZone.id,
          });
        }

        if (
          locationType === PlacementKind.Building &&
          valueSite?.id &&
          valueBuilding?.id
        ) {
          dispatch({
            type: 'reset-with-building',
            site: valueSite.id,
            building: valueBuilding.id,
          });
        }
      }, [sites, buildings, storeys, zones, value, locationType]);

      const handleChangeSite = useCallback(
        (event: React.ChangeEvent<{ name?: string }>, id: string | number) => {
          dispatch({ type: 'site', value: String(id) });
        },
        [dispatch],
      );

      const handleChangeBuilding = useCallback(
        (event: React.ChangeEvent<{ name?: string }>, id: string | number) => {
          dispatch({ type: 'building', value: String(id) });
        },
        [dispatch],
      );

      const handleChangeStorey = useCallback(
        (event: React.ChangeEvent<{ name?: string }>, id: string | number) => {
          dispatch({ type: 'storey', value: String(id) });
        },
        [dispatch],
      );

      const handleChangeZone = useCallback(
        (event: React.ChangeEvent<{ name?: string }>, id: string | number) => {
          dispatch({ type: 'zone', value: String(id) });
        },
        [dispatch],
      );

      useEffect(() => {
        if (placementKind === undefined && placementId === undefined) {
          return;
        }
        if (placementKind !== locationType) {
          setFieldValue('locationType', placementKind || '');
        }
        if (placementId !== value) {
          setFieldValue('locationId', placementId || '0');
        }
      }, [placementId, placementKind, locationType, value, setFieldValue]);

      return (
        <>
          <Grid item xs={12} className={classes.field}>
            <Autocomplete
              id="siteId"
              data-testid="siteId-select"
              label={t('configuration:gateway.create.deviceLocationField.site')}
              fullWidth
              value={site || '0'}
              error={site === '0' && error}
              onChange={handleChangeSite}
              disabled={loading}
              options={sitesOptions}
            />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <Autocomplete
              id="buildingId"
              data-testid="buildingId-select"
              label={t(
                'configuration:gateway.create.deviceLocationField.building',
              )}
              disabled={site === '0' || loading}
              value={building || '0'}
              onChange={handleChangeBuilding}
              fullWidth
              options={buildingsOptions}
              error={building === '0' && error}
            />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <Autocomplete
              id="storeyId"
              data-testid="storeyId-select"
              label={t(
                'configuration:gateway.create.deviceLocationField.storey',
              )}
              disabled={building === '0' || loading}
              value={storey || '0'}
              onChange={handleChangeStorey}
              fullWidth
              options={storeysOptions}
            />
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <Autocomplete
              id="zoneId"
              data-testid="zoneId-select"
              label={t('configuration:gateway.create.deviceLocationField.zone')}
              disabled={storey === '0' || loading}
              value={zone || '0'}
              onChange={handleChangeZone}
              fullWidth
              options={zonesOptions}
              error={storey !== '0' && zone === '0' && error}
            />
          </Grid>
          <FormHelperText>{helperText}</FormHelperText>
        </>
      );
    },
  );

GatewayLocationField.displayName = 'GatewayLocationField';
