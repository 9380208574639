import React from 'react';
import { fieldToAutocomplete } from 'formik-mui';
import { FieldProps } from 'formik';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';

type AutocompleteFieldProps = FieldProps &
  Omit<AutocompleteProps, 'name' | 'value' | 'defaultValue'>;

const RenderInput = () => null;

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  options,
  ...props
}) => (
  <Autocomplete
    {...fieldToAutocomplete({
      ...props,
      // These two are just here, because `fieldToAutocomplete` does not allow
      // us to change its argument type. They are overwritten, by setting the
      // props directly to that component
      options: [],
      renderInput: RenderInput,
    })}
    options={options}
    error={
      props.form.touched[props.field.name] &&
      !!props.form.errors[props.field.name]
    }
    helperText={props.form.errors[props.field.name]}
  />
);
