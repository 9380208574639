import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Color } from '../../constants/Colors';

export enum Status {
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED',
  unknown = 'UNKNOWN',
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  base: {
    display: 'inline-block',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: Color.lightGrey,
  },
  UNKNOWN: {
    backgroundColor: Color.lightGrey,
  },
  CONNECTED: {
    backgroundColor: Color.ok,
  },
  DISCONNECTED: {
    backgroundColor: Color.error,
  },
});

interface StatusIndicatorProps {
  status: Status;
  tooltip?: (status: Status) => string;
  size?: number;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  tooltip,
  size = 8,
}) => {
  const { t } = useTranslation(['components', 'general', 'errors']);
  const classes = useStyles();

  const tooltipText = (() => {
    if (tooltip) return tooltip(status);
    switch (status) {
      case Status.connected:
        return t('components:statusIndicator.connected');
      case Status.disconnected:
        return t('components:statusIndicator.disconnected');
      default:
        return t('components:statusIndicator.unknown');
    }
  })();

  return (
    <div className={classes.root} data-testid={`status-indicator-${status}`}>
      <Tooltip arrow placement="top" title={tooltipText}>
        <div
          className={classNames(classes.base, classes[status])}
          style={{ width: size, height: size }}
        />
      </Tooltip>
    </div>
  );
};
