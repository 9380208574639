import { createTheme, ThemeOptions } from '@mui/material';
import { baseTheme } from './baseTheme';

export const overridings: ThemeOptions = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
  },
};

export default createTheme(overridings);
