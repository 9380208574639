import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { GatewayListContainer } from '../../../components/GatewayList';
import { MonitoringOverviewFieldDeviceListContainer } from './MonitoringOverviewFieldDeviceListContainer';
import { FieldDevice } from './MonitoringOverviewFieldDeviceList';
import { MonitoringOverviewDeviceDataContainer } from './MonitoringOverviewDeviceDataContainer';
import { MonitoringOverviewDeviceData } from './MonitoringOverviewDeviceData';

export const MonitoringOverviewContainer: React.FC = () => {
  const { t } = useTranslation(['monitoring', 'general']);
  const { setTitle, setIcon } = useHeader();

  const [gatewayId, setGatewayId] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<FieldDevice>();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.MONITORING, t),
    location: Entities.MONITORING.path,
  });
  useEffect(() => {
    setTitle({ main: t('monitoring:overview.title') });
    setIcon(Pages.DASHBOARD.icon);
  }, [setTitle, setIcon, t]);

  const handleSelectGateway = (id?: string) => {
    setGatewayId(id);
    setSelectedDevice(undefined);
  };
  const handleSelectDevice = (device?: FieldDevice) => {
    setSelectedDevice(device);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <GatewayListContainer
          selectedId={gatewayId}
          onSelect={handleSelectGateway}
        />
      </Grid>
      <Grid item xs={12}>
        <MonitoringOverviewFieldDeviceListContainer
          gatewayId={gatewayId}
          onSelectDevice={handleSelectDevice}
        />
      </Grid>
      <Grid item xs={12}>
        {selectedDevice ? (
          <MonitoringOverviewDeviceDataContainer device={selectedDevice} />
        ) : (
          <MonitoringOverviewDeviceData />
        )}
      </Grid>
    </Grid>
  );
};
