import React from 'react';
import MuiTextField from '@mui/material/TextField';
import {
  fieldToTextField,
  TextFieldProps as FormikTextFieldProps,
} from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { makeOptional } from './helpers';

const useStyles = makeStyles({
  field: {
    minHeight: '70px',
  },
});

export type TextFieldProps = FormikTextFieldProps & { optional?: boolean };

export const TextField: React.FC<TextFieldProps> = ({ optional, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);

  const {
    field: { name },
    label,
    className,
    InputLabelProps,
  } = props;
  const optionalLabel = makeOptional(t, label);

  return (
    <MuiTextField
      id={name}
      fullWidth
      type="text"
      {...fieldToTextField(props)}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      className={classNames(className, classes.field)}
      label={optional ? optionalLabel : label}
    />
  );
};
