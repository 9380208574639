import { TFunction } from 'react-i18next';
import {
  DeploymentStatus,
  GatewayStatus,
  ScanStatus,
} from '../../../api/bacnet/common';

export const translateScanStatus = (
  status: ScanStatus | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'started':
      return t('deployments:common.scanStatus.started');
    case 'completed':
      return t('deployments:common.scanStatus.completed');
    case 'failed':
      return t('deployments:common.scanStatus.failed');
    case 'timedout':
      return t('deployments:common.scanStatus.timedout');
    default:
      return t('deployments:common.scanStatus.notStarted');
  }
};
export const translateGatewayStatus = (
  status: GatewayStatus | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'idle':
      return t('deployments:common.gatewayStatus.idle');
    case 'scanning_devices':
      return t('deployments:common.gatewayStatus.scanning_devices');
    case 'scanning_objects':
      return t('deployments:common.gatewayStatus.scanning_objects');
    case 'offline':
      return t('deployments:common.gatewayStatus.offline');
    default:
      return t('deployments:common.gatewayStatus.unknown');
  }
};
export const translateDeploymentStatus = (
  status: DeploymentStatus | undefined,
  t: TFunction,
) => {
  switch (status) {
    case 'created':
      return t('deployments:common.deploymentStatus.created');
    case 'deploying':
      return t('deployments:common.deploymentStatus.deploying');
    case 'deployed':
      return t('deployments:common.deploymentStatus.deployed');
    case 'failed':
      return t('deployments:common.deploymentStatus.failed');
    default:
      return t('deployments:common.deploymentStatus.unknown');
  }
};
