import React, { useState } from 'react';
import { FormHelperText, InputLabel, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { makeOptional } from '../Fields';

interface DataTypeInputDateTimeProps {
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInputDateTime: React.FC<DataTypeInputDateTimeProps> = ({
  disabled,
  value,
  id,
  name,
  label,
  className,
  onChange,
  autoFocus = true,
  error,
  helperText,
  optional,
}) => {
  const { t } = useTranslation(['general']);
  const [dateValue, setDateValue] = useState<Date | null>(
    value ? new Date(value) : null,
  );

  const handleDateChange = (d: unknown) => {
    if (!d || String(d) === 'Invalid Date') return;
    const date = d as Date | null;
    setDateValue(date);
    onChange(date ? date.toISOString() : '');
  };

  return (
    <>
      <InputLabel shrink htmlFor={id}>
        {optional ? makeOptional(t, label) : label}
      </InputLabel>
      <DateTimePicker
        cancelText={t('general:buttons.cancel')}
        okText={t('general:picker.submit')}
        clearText={t('general:picker.clear')}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            name={name}
            className={className}
            fullWidth
            // format="dd-MM-yyyy HH:mm"
            error={error}
          />
        )}
        disabled={disabled}
        ampm={false}
        autoFocus={autoFocus}
        value={dateValue}
        onChange={handleDateChange}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  );
};
