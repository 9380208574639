import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ErrorAlert } from '../Alerts';
import {
  ConnectionStates,
  useGatewayListQuery,
} from '../../__generated__/types';
import { ErrorMessage } from '../Errors';
import { GatewayList, Gateway, Type } from './GatewayList';
import { usePrepareRequest } from '../../hooks/useRequest';
import {
  getLatestSuccessfulDeployment,
  getDeploymentMappings,
} from '../../api/bacnet';
import { byName } from '../../utils/sort';
import { Status } from '../StatusIndicator';

export { Type };

type State = Record<number, number>;
type Action = {
  idx: number;
  count: number;
};
const reducer = (state: State, { idx, count }: Action): State => ({
  ...state,
  [idx]: count,
});

const getStatus = (value?: ConnectionStates | null): Status => {
  switch (value) {
    case ConnectionStates.Connected:
      return Status.connected;
    case ConnectionStates.Disconnected:
      return Status.disconnected;
    default:
      return Status.unknown;
  }
};

export type GatewayListContainerProps = {
  selectedId: string | undefined;
  onSelect: (id?: string) => void;
  onAdd?: () => void;
};

export const GatewayListContainer: React.FC<GatewayListContainerProps> = ({
  selectedId,
  onSelect,
  onAdd,
}) => {
  const { t } = useTranslation(['general', 'errors']);
  const [searchParams, setSearchParams] = useSearchParams();
  const gatewayIdFromQuery = searchParams.get('gatewayId') || undefined;
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [mappingCounts, dispatch] = useReducer(reducer, {});
  const pollTimeout = useRef<NodeJS.Timeout>();

  const { loading, error, data, refetch } = useGatewayListQuery();

  const [
    callGetLasSuccessfulDeployment,
    { error: errorLatestSuccessfulDeployment },
  ] = usePrepareRequest(getLatestSuccessfulDeployment);
  const [callGetMappings, { error: errorMappings }] = usePrepareRequest(
    getDeploymentMappings,
  );

  useEffect(() => {
    const allgateways: Gateway[] = (data?.devices || [])
      .map((device) => ({
        id: device?.id || '-1',
        name: device?.name || '-',
        description: device?.description || '-',
        model: device?.deviceModel?.name || '-',
        connectionState: getStatus(device?.connectionState),
        capabilitiesCount:
          device?.deviceModel?.deviceModelCapabilities?.length || 0,
        childCount: device?.inverseParentDevice?.length || 0,
        childCapabilitiesCount: device?.inverseParentDevice?.reduce(
          (count, child) =>
            count + child.deviceModel.deviceModelCapabilities.length,
          0,
        ),
      }))
      .filter((device) => device.id !== '-1')
      .sort(byName);

    setGateways(allgateways);

    allgateways.forEach((d, idx) => {
      callGetLasSuccessfulDeployment(d.id)
        .then((result) => {
          const deployment = result.response?.data;
          if (!deployment) return null;
          return callGetMappings(d.id, deployment.id);
        })
        .then((result) => {
          const count = result?.response?.data.length || 0;
          dispatch({ idx, count });
          return result;
        })
        .catch((e) => console.warn(e));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    pollTimeout.current = setInterval(refetch, 10000);
    return () => {
      const timeout = pollTimeout.current;
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (id?: string) => {
    if (selectedId === id) return;
    if (id) {
      searchParams.set('gatewayId', id);
    } else {
      searchParams.delete('gatewayId');
    }
    setSearchParams(searchParams);
    onSelect(id);
  };

  if (error) {
    return <ErrorMessage error={error} message={t('errors:gqlError.query')} />;
  }

  const gatewaysWithMappingCount = gateways.map((gw, idx) => ({
    ...gw,
    mappingCount: mappingCounts[idx],
  }));
  return (
    <>
      <GatewayList
        loading={loading}
        gateways={gatewaysWithMappingCount}
        selectedId={selectedId || gatewayIdFromQuery}
        onSelect={handleSelect}
        onAdd={onAdd}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorLatestSuccessfulDeployment?.responseCode !==
            'UNKNOWN_GATEWAY' &&
            errorLatestSuccessfulDeployment?.responseCode !== 'NOT_FOUND' &&
            errorLatestSuccessfulDeployment) ||
          errorMappings
        }
      />
    </>
  );
};
