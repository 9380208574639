import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../components/Alerts';
import {
  ConfigurationOverviewConfig,
  Config,
} from './ConfigurationOverviewConfig';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { getGatewayConfigFile, getGatewayConfig } from '../../../api/bacnet';

type ConfigurationOverviewConfigContainerProps = {
  gatewayId?: string;
};
export const ConfigurationOverviewConfigContainer: React.FC<
  ConfigurationOverviewConfigContainerProps
> = ({ gatewayId }) => {
  const { t } = useTranslation(['configuration', 'general', 'errors']);
  const [config, setConfig] = useState<Config>();
  const [callGetConfig, { loading: loadingGetConfig, error: errorGetConfig }] =
    usePrepareRequest(getGatewayConfig);

  const [
    callGetConfigFile,
    { loading: loadingGetConfigFile, error: errorGetConfigFile },
  ] = usePrepareRequest(getGatewayConfigFile);

  useEffect(() => {
    if (gatewayId) {
      callGetConfig(gatewayId)
        .then((cfg) => {
          setConfig(cfg?.response?.data);
          return cfg;
        })
        .catch((e) => console.warn('Error getting config', e));
    } else {
      setConfig(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  const handleDownload = async () => {
    if (gatewayId) {
      const configs = await callGetConfigFile(gatewayId);
      if (!configs?.response) return;
      const blob = configs?.response;
      saveAs(blob, `mqtt1-${gatewayId}.cfg`);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ConfigurationOverviewConfig
            loading={loadingGetConfig || loadingGetConfigFile}
            config={config}
            onDownload={handleDownload}
          />
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorGetConfig || errorGetConfigFile}
      />
    </>
  );
};
