import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Loading } from '../../../components/Loading';
import { translateScanStatus } from '../common/statusTranslation';
import { ScanStatus } from '../../../api/bacnet/common';

type DeviceScanTableProps = {
  loading?: boolean;
  numOfDevices?: number;
  status?: ScanStatus;
  startedAt?: string;
  finishedAt?: string;
  error?: null | string;
};

export const DeviceScanTable: React.FC<DeviceScanTableProps> = ({
  loading,
  numOfDevices,
  status,
  startedAt,
  finishedAt,
  error,
}) => {
  const { t } = useTranslation(['discovery', 'general']);

  const renderValue = (v?: number | string) => v || '-';
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {t('discovery:deviceScanTable.header')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Loading />
            </TableCell>
          </TableRow>
        ) : (
          <>
            <TableRow>
              <TableCell>
                {t('discovery:deviceScanTable.numOfDevices')}
              </TableCell>
              <TableCell align="right">{renderValue(numOfDevices)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('discovery:deviceScanTable.status')}</TableCell>
              <TableCell align="right">
                {translateScanStatus(status, t)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('discovery:deviceScanTable.startedAt')}</TableCell>
              <TableCell align="right">{renderValue(startedAt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('discovery:deviceScanTable.finishedAt')}</TableCell>
              <TableCell align="right">{renderValue(finishedAt)}</TableCell>
            </TableRow>
            {error && (
              <TableRow>
                <TableCell>{t('discovery:deviceScanTable.error')}</TableCell>
                <TableCell align="right">{renderValue(error)}</TableCell>
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};
