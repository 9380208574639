/**
 * This is the name of the unit that is Not Applicable and should be used for assigning
 * a capability to a device model which has a boolean data type. We can't reference
 * that unit by its id, because it's not really the same across the environments
 *
 * Note that this can become a part of the `contexts` one day.
 */
export const NA_UNIT_NAME = 'No Unit';

export const OFFLINE_THRESHOLD = 3;
