import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { Layout } from './components/Layout';
import { LayoutSimple } from './components/LayoutSimple';
import { Dashboard } from './features/dashboard/Dashboard';
import { NotFound, MissingPermissions } from './features/errorScreens';
import { Forbidden } from './features/auth/forbidden';
import { ConfigurationRoutes } from './features/configuration/ConfigurationRoutes';
import { DeploymentsRoutes } from './features/deployments/DeploymentsRoutes';
import { ModelEditorRoutes } from './features/modelEditor/ModelEditorRoutes';
import { MonitoringRoutes } from './features/monitoring/MonitoringRoutes';

const defaultLayout = [
  '/configuration',
  '/deployments',
  '/monitoring',
  '/model-editor',
];

const App: React.FC = () => {
  const location = useLocation();

  const isDefaultLayout =
    location.pathname === '/' ||
    defaultLayout.some((route) => location.pathname.startsWith(route));

  const LayoutComponent = isDefaultLayout ? Layout : LayoutSimple;

  return (
    <LayoutComponent>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route
          path="/configuration/*"
          element={<ProtectedRoute element={<ConfigurationRoutes />} />}
        />
        <Route
          path="/deployments/*"
          element={<ProtectedRoute element={<DeploymentsRoutes />} />}
        />
        <Route
          path="/monitoring/*"
          element={<ProtectedRoute element={<MonitoringRoutes />} />}
        />
        <Route
          path="/model-editor/*"
          element={<ProtectedRoute element={<ModelEditorRoutes />} />}
        />
        <Route path="/missing-permissions" element={<MissingPermissions />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </LayoutComponent>
  );
};

export default App;
