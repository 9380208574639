import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from '../../constants/UI';
import { PageHeader } from '../PageHeader';
import { useSidebar } from '../../contexts/sidebar-context';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  content: {
    padding: 50,
    color: Color.black,
    flexGrow: 1,
    marginLeft: SIDEBAR_CLOSED_WIDTH - 1,
    transition: (theme: Theme) =>
      theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  narrowContent: {
    marginLeft: SIDEBAR_OPENED_WIDTH,
    transition: (theme: Theme) =>
      theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  root: {
    display: 'flex',
  },
  simpleContent: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: Color.contentBackground,
  },
  plainContent: {
    padding: '64px 0 0 0',
    height: '100vh',
  },
});

interface LayoutProps {
  simple?: boolean;
  plain?: boolean;
  closedSidebar?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  simple,
  plain,
  closedSidebar,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { open, setOpen } = useSidebar();

  const handleCollapseClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (closedSidebar) {
      setOpen(false);
    }
  }, [closedSidebar, setOpen]);

  return (
    <div className={classes.root}>
      {!simple && (
        <Header onCollapseClick={handleCollapseClick} sidebarOpen={open} />
      )}
      {!simple && <Sidebar open={open} />}
      <div
        className={classNames(
          simple ? classes.simpleContent : classes.content,
          !simple && open && classes.narrowContent,
          plain && classes.plainContent,
        )}
      >
        {!simple && !plain && <PageHeader />}
        {children}
      </div>
    </div>
  );
};
