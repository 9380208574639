import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { saveAs } from 'file-saver';
import { usePrepareRequest } from '../../../hooks/useRequest';
import * as API from '../../../api/bacnet';
import { WorkbenchCard, GatewayDetails } from './WorkbenchCard';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { ErrorAlert } from '../../../components/Alerts';

type WorkbenchCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayDetails?: GatewayDetails;
  refreshGetGatewayDetails: () => void;
};
export const WorkbenchCardContainer: React.FC<WorkbenchCardContainerProps> = ({
  loading,
  gatewayId,
  gatewayDetails,
  refreshGetGatewayDetails,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const navigate = useNavigate();

  const [
    callDownloadMappings,
    { loading: loadingDownloadMappings, error: errorDownloadMappings },
  ] = usePrepareRequest(API.downloadMapping, {
    onCompleted: () => refreshGetGatewayDetails(),
  });
  const [
    callDeployMappings,
    { loading: loadingDeployMappings, error: errorDeployMappings },
  ] = usePrepareRequest(API.deployMapping, {
    onCompleted: () => refreshGetGatewayDetails(),
  });

  const handleEditMappings = () => {
    navigate(entityLocation(Entities.DEPLOYMENTS, `current/${gatewayId}`));
  };

  const handleDiscovery = async () => {
    if (!gatewayId) return;
    navigate(entityLocation(Entities.DEPLOYMENTS, `discovery/${gatewayId}`));
  };

  const handleDownload = async () => {
    if (!gatewayId) return;
    const mappings = await callDownloadMappings(gatewayId);
    if (!mappings?.response) return;
    const blob = mappings?.response;
    saveAs(blob, `gatewayConfig-${gatewayId}.zip`);
  };
  const handleDeploy = async () => {
    if (!gatewayId) return;
    callDeployMappings(gatewayId);
  };

  return (
    <>
      <WorkbenchCard
        loading={loading || loadingDownloadMappings || loadingDeployMappings}
        gatewayId={gatewayId}
        gatewayDetails={gatewayDetails}
        onDiscovery={handleDiscovery}
        onEditMappings={handleEditMappings}
        onDownload={handleDownload}
        onDeploy={handleDeploy}
      />

      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorDownloadMappings || errorDeployMappings}
      />
    </>
  );
};
