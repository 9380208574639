import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Loading } from '../../../components/Loading';
import { translateScanStatus } from './statusTranslation';

export type DeviceScanStats = null | {
  status?: 'started' | 'completed' | 'failed' | 'timedout';
  startedAt?: string;
  finishedAt?: string;
  numOfDevices?: number;
};
export type DeviceScanStatsTableProps = DeviceScanStats & {
  loading?: boolean;
};

export const DeviceScanStatsTable: React.FC<DeviceScanStatsTableProps> = ({
  loading,
  status,
  startedAt,
  finishedAt,
  numOfDevices,
}) => {
  const { t } = useTranslation(['deployments', 'general']);

  const scanStartedAt = startedAt ? new Date(startedAt).toLocaleString() : '-';
  const scanFinishedAt = finishedAt
    ? new Date(finishedAt).toLocaleString()
    : '-';
  const scanNumOfObjects = !finishedAt ? '-' : numOfDevices;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {t('deployments:common.deviceScanStatsTable.header')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Loading />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {status && (
              <TableRow>
                <TableCell>
                  {t('deployments:common.deviceScanStatsTable.status')}
                </TableCell>
                <TableCell align="right">
                  {translateScanStatus(status, t)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                {t('deployments:common.deviceScanStatsTable.numOfDevices')}
              </TableCell>
              <TableCell align="right">{scanNumOfObjects}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.deviceScanStatsTable.startedAt')}
              </TableCell>
              <TableCell align="right">{scanStartedAt}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {t('deployments:common.deviceScanStatsTable.finishedAt')}
              </TableCell>
              <TableCell align="right">{scanFinishedAt}</TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};
