import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Theme } from '@mui/material';
import { Auth } from '@kp/react-sdk';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import App from './App';
import { BreadcrumbProvider } from './contexts/breadcrumb-context';
import './utils/i18n';
import { GlobalLoading } from './components/GlobalLoading';
import { GraphQLProvider } from './contexts/graphql-context';
import { ThemeProvider } from './components/ThemeProvider';
import { HeaderProvider } from './contexts/header-context';
import { reactPlugin } from './AppInsights';
import { ErrorBoundary } from './components/Errors';
import { AUTH_URI, CLIENT_ID } from './utils/env';
import { requiredPermissions } from './constants/Permissions';
import { SettingsProvider } from './contexts/settings-provider';
import { SidebarProvider } from './contexts/sidebar-context';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Suspense fallback={<GlobalLoading />}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ErrorBoundary>
            <Auth
              clientId={CLIENT_ID}
              url={AUTH_URI}
              forbiddenUrl="/forbidden"
              missingPermissionsUrl="/missing-permissions"
              requiredPermissions={requiredPermissions}
              LoadingComponent={GlobalLoading}
            >
              <SettingsProvider>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <BreadcrumbProvider>
                    <Router>
                      <GraphQLProvider>
                        <HeaderProvider>
                          <SidebarProvider>
                            <App />
                          </SidebarProvider>
                        </HeaderProvider>
                      </GraphQLProvider>
                    </Router>
                  </BreadcrumbProvider>
                </LocalizationProvider>
              </SettingsProvider>
            </Auth>
          </ErrorBoundary>
        </AppInsightsContext.Provider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
