import React, { ChangeEvent, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { Device, ObjectScanTable } from './ObjectScanTable';
import { EmptyBanner } from '../../../components/EmptyBanner';
import { BasicConfirmAlert } from '../../../components/Alerts/BasicConfirmAlert';
import { GatewayStatus } from '../../../api/bacnet/common';

const useStyles = makeStyles({
  cardContent: {
    minHeight: 200,
  },
  banner: {
    margin: 'auto',
  },
  button: {
    margin: 8,
    marginBottom: 16,
  },
});

export type ObjectDiscoveryCardProps = {
  loading?: boolean;
  gatewayStatus?: GatewayStatus;
  devices?: Device[];
  onStartScan: (devices: string[]) => void;
  onSelectFile: (devices: string[], content: string) => void;
  onDeleteObjectScan: (deviceId: string) => void;
};

export const ObjectDiscoveryCard: React.FC<ObjectDiscoveryCardProps> = ({
  loading,
  devices,
  gatewayStatus,
  onStartScan,
  onSelectFile,
  onDeleteObjectScan,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['discovery', 'general']);

  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [confirmDeleteId, setConfirmDeleteId] = useState<string>();

  const handleStartScan = () => {
    onStartScan(selectedDevices);
  };

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const content = await fileList?.[0]?.text();
    if (content) {
      onSelectFile(selectedDevices, content);
    }
  };

  const confirmDelete = (deviceId: string) => setConfirmDeleteId(deviceId);
  const handleCancelDelete = () => setConfirmDeleteId(undefined);
  const handleDelete = () => {
    if (confirmDeleteId) onDeleteObjectScan(confirmDeleteId);
    setConfirmDeleteId(undefined);
  };

  const isIdle = gatewayStatus === 'idle';
  const isScanningDevices = gatewayStatus === 'scanning_devices';

  return (
    <>
      <FancyCard
        title={t('discovery:objectDiscoveryCard.title')}
        loading={loading}
      >
        <Grid container spacing={2} className={classes.cardContent}>
          {!loading && !devices && (
            <EmptyBanner
              className={classes.banner}
              description={t('discovery:objectDiscoveryCard.noDevices')}
            />
          )}
          {!loading && isScanningDevices && (
            <EmptyBanner
              className={classes.banner}
              description={t('discovery:objectDiscoveryCard.scanningDevices')}
            />
          )}
          {devices && !isScanningDevices && (
            <>
              <Grid item xs={12} id="device scan">
                <ObjectScanTable
                  loading={loading}
                  devices={devices}
                  onSelect={setSelectedDevices}
                  onDelete={confirmDelete}
                />
              </Grid>
              <Grid item xs={12} id="actions">
                <Button
                  className={classes.button}
                  onClick={handleStartScan}
                  color="primary"
                  size="large"
                  aria-label="restart-scan-button"
                  variant="outlined"
                  disabled={!isIdle || !selectedDevices.length}
                >
                  {t('discovery:objectDiscoveryCard.restartScan')}
                </Button>
                <label htmlFor={'file-upload-objects'}>
                  <input
                    accept="text/xml"
                    id={'file-upload-objects'}
                    data-testid={'file-upload-objects'}
                    name={'file-upload-objects'}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleSelectFile}
                  />
                  <Button
                    className={classes.button}
                    color="secondary"
                    size="large"
                    aria-label="upload-scan-button"
                    variant="outlined"
                    component="span"
                    disabled={!isIdle || !selectedDevices.length}
                  >
                    {t('discovery:objectDiscoveryCard.uploadScan')}
                  </Button>
                </label>
              </Grid>
            </>
          )}
        </Grid>
      </FancyCard>
      <BasicConfirmAlert
        open={!!confirmDeleteId}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        title={t('discovery:objectDiscoveryCard.deleteScanResultAlert.title')}
        message={t(
          'discovery:objectDiscoveryCard.deleteScanResultAlert.message',
        )}
      />
    </>
  );
};
