import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import * as API from '../api/bacnet';
import { RequestError } from '../api/utils/RequestError';
import { usePrepareRequest } from './useRequest';

export type UseDevices = {
  devices: API.Devices | undefined;
  loading: boolean;
  error: RequestError | undefined;
  refresh: () => void;
};

export const useDevices = (gatewayId?: string): UseDevices => {
  const [devices, setDevices] = useState<API.Devices>();
  const gatewayDetailsPollTimeout = useRef<NodeJS.Timeout>();

  const [callGetDevices, { loading, error }] = usePrepareRequest(
    API.getDevices,
    {
      onCompleted: (result) => {
        setDevices(result?.data);
      },
    },
  );
  const [pollGetDevices] = usePrepareRequest(API.getDevices, {
    onCompleted: (result) => {
      setDevices(result?.data);
    },
  });
  useInterval(() => {
    if (gatewayId) pollGetDevices(gatewayId);
  }, 10000);

  useEffect(() => {
    setDevices(undefined);
    if (gatewayDetailsPollTimeout.current) {
      clearTimeout(gatewayDetailsPollTimeout.current);
    }
    if (gatewayId) {
      callGetDevices(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  const refresh = () => {
    if (gatewayId) callGetDevices(gatewayId);
  };

  return {
    devices,
    loading,
    error,
    refresh,
  };
};
