import React from 'react';
import { FieldProps } from 'formik';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  fieldWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  tooltipContent: {
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

type TooltipProps = {
  content: string;
  type?: 'info' | 'warning';
};
export type FieldWithTooltipProps = FieldProps & {
  innerComponent: React.FC;
  tooltip: TooltipProps;
};

export const FieldWithTooltip: React.FC<FieldWithTooltipProps> = ({
  tooltip: { content, type },
  innerComponent: Component,
  ...props
}) => {
  const classes = useStyles();

  const Icon =
    type === 'warning' ? (
      <Icons.Warning htmlColor={Color.warning} data-testid="warning-icon" />
    ) : (
      <Icons.Info color="action" data-testid="info-icon" />
    );

  return (
    <div className={classes.fieldWithTooltip}>
      <Component {...props} />
      <Tooltip
        arrow
        title={<div className={classes.tooltipContent}>{content}</div>}
        className={classes.tooltip}
        placement="right"
      >
        {Icon}
      </Tooltip>
    </div>
  );
};
