import React, { useContext, createContext, useState } from 'react';
import type { ElementType } from 'react';

export interface Title {
  main?: string;
  sub?: string;
}
interface HeaderContextValue {
  title: Title;
  icon: ElementType | undefined;
  loading: boolean;
  setIcon: (icon: ElementType) => void;
  setTitle: (title: Title) => void;
  setLoading: (loading: boolean) => void;
}

const HeaderContext = createContext<HeaderContextValue>({
  title: {},
  icon: undefined,
  loading: false,
  setIcon: () => {
    // this is empty on purpose
  },
  setTitle: () => {
    // this is empty on purpose
  },
  setLoading: () => {
    // this is empty on purpose
  },
});

export const useHeader = (): HeaderContextValue => useContext(HeaderContext);

export const HeaderProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState<Title>({});
  const [icon, setIcon] = useState<ElementType>();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <HeaderContext.Provider
      value={{ title, setTitle, icon, setIcon, loading, setLoading }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
