import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionsBar } from '../../../../components/ActionsBar';
import {
  GatewayFormData,
  GatewayFormInfo,
  getGatewayFormInfoSchema,
} from './GatewayFormInfo';

import {
  DeviceAttributesForm,
  AttributeSet,
  getDeviceAttributesFormSchema,
} from '../../../../components/DeviceAttributesForm';
import {
  GatewayFormLocation,
  GatewayFormLocationProps,
  GatewayFormLocationData,
} from './GatewayFormLocation';
import { PlacementKind } from '../../../../__generated__/types';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: '8px',
  },
  section: {
    '& + &': {
      marginTop: '24px',
    },
  },
});

export type GatewayCreateData = GatewayFormData &
  GatewayFormLocationData & {
    attributeSets: AttributeSet[];
  };

interface GatewayCreateProps {
  loading?: boolean;
  allSites: GatewayFormLocationProps['sites'];
  allGatewayModels: Array<{
    id: string;
    name: string;
    deviceModelAttributeSets: AttributeSet[];
  }>;
  placementId?: string;
  onSubmit: (device: GatewayCreateData) => void;
  onDiscard: () => void;
}

export const GatewayCreate: React.FC<GatewayCreateProps> = ({
  loading,
  allSites,
  allGatewayModels,
  placementId,
  onSubmit,
  onDiscard,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);

  const validationSchema = getGatewayFormInfoSchema(t).concat(
    getDeviceAttributesFormSchema(t),
  );

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        deviceModelId: '0',
        locationId: placementId || '0',
        attributeSets: [] as AttributeSet[],
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        submitForm,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldError,
        setFieldTouched,
      }: FormikProps<GatewayCreateData>) => {
        const handleModelSelection = (value: string) => {
          const selectedModel = allGatewayModels.find((dm) => dm.id === value);
          const selectedAttributeSets = selectedModel?.deviceModelAttributeSets;
          setFieldValue('deviceModelId', value);
          setFieldValue(
            'attributeSets',
            selectedAttributeSets?.map((attributeSet) => ({
              ...attributeSet,
              attributes: attributeSet.attributes.map((attribute) => ({
                ...attribute,
                enabled: attribute.required,
              })),
            })),
          );
          setFieldError('attributeSets', undefined);
          setFieldTouched('attributeSets', false);
        };

        return (
          <>
            <ActionsBar>
              <Button
                onClick={onDiscard}
                color="primary"
                size="large"
                className={classes.actionButton}
                aria-label="discard-button"
              >
                {t('general:buttons.discard')}
              </Button>
              <Button
                onClick={submitForm}
                className={classes.actionButton}
                color="secondary"
                variant="outlined"
                size="large"
                aria-label="save-button"
                disabled={loading}
              >
                {t('general:buttons.save')}
              </Button>
            </ActionsBar>
            <div className={classes.section}>
              <Grid container spacing={3} alignItems="stretch" direction="row">
                <Grid item sm={12} md={8}>
                  <GatewayFormInfo
                    type="create"
                    loading={loading}
                    onSelectModel={handleModelSelection}
                    allDeviceModels={allGatewayModels}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <GatewayFormLocation
                    sites={allSites}
                    loading={loading}
                    setFieldValue={setFieldValue}
                    locationType={
                      placementId !== '0' ? PlacementKind.Zone : undefined
                    }
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </div>
            {values.attributeSets && values.attributeSets?.length > 0 && (
              <div className={classes.section}>
                <DeviceAttributesForm
                  attributeSets={values.attributeSets}
                  onChange={setFieldValue}
                  loading={loading}
                  touched={touched}
                  errors={errors}
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
