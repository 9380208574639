import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '72px',
  },
  content: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'auto',
    backgroundClip: 'padding-box',
    border: 'none',
    borderRadius: '0',
    outline: '0',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const NotFound: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);

  return (
    <Paper className={classes.root}>
      <div className={classes.content}>{t('general:errors.notFound')}</div>
    </Paper>
  );
};
