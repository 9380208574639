import React from 'react';
import { TFunction } from 'i18next';

export const optional = (label: string): string => `${label} fields.optional`;

export const makeOptional = (
  t: TFunction,
  translation: React.ReactNode,
): JSX.Element => (
  <>
    {translation}
    <span aria-hidden> {t('general:fields.optional')}</span>
  </>
);
