declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    ENV_GRAPHQL_URI: string;
    ENV_AUTH_URI: string;
    ENV_USER_SERVICE_URI: string;
    ENV_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
    ENV_PORTAL_APP_URI: string;
    ENV_COMMISSIONING_SERVICE_URI: string;
    ENV_ADMIN_APP_URI: string;
    ENV_BUILDING_INSIGHTS_APP_URI: string;
  }
}

export const GRAPHQL_URI = window.ENV_GRAPHQL_URI;
export const AUTH_URI = window.ENV_AUTH_URI;
export const USER_SERVICE_URI = window.ENV_USER_SERVICE_URI;
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  window.ENV_APPLICATIONINSIGHTS_CONNECTION_STRING;
export const PORTAL_APP_URI = window.ENV_PORTAL_APP_URI;
export const COMMISSIONING_SERVICE_URI = window.ENV_COMMISSIONING_SERVICE_URI;
export const ADMIN_APP_URI = window.ENV_ADMIN_APP_URI;
export const BUILDING_INSIGHTS_APP_URI = window.ENV_BUILDING_INSIGHTS_APP_URI;

// same for all environments
export const CLIENT_ID = 'kxp-edge-commissioning-app';
