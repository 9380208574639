import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  SelectChangeEvent,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from '@mui/material';

const useStyles = makeStyles({
  deviceDropdown: {
    width: 250,
  },
});

export type ScanDataDevice = {
  id: string;
  name: string;
};

export type DeviceDropdownProps = {
  disabled?: boolean;
  devices: Array<ScanDataDevice>;
  selectedDeviceIds: string[];
  onChange: (selectedDeviceId: string[]) => void;
};
export const DeviceDropdown: React.FC<DeviceDropdownProps> = ({
  disabled,
  devices,
  selectedDeviceIds,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);
  const [filterDeviceIds, setFilterDeviceIds] =
    useState<string[]>(selectedDeviceIds);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    // On autofill we get a stringified value.
    if (typeof value === 'string') {
      setFilterDeviceIds([value]);
      return;
    }
    setFilterDeviceIds(value);
  };

  const handleClose = () => {
    onChange(filterDeviceIds);
  };
  const emptyLabel: string = t(
    'deployments:common.scanResult.deviceDropdown.emptyLabel',
  );
  const multipleLabel: string = t(
    'deployments:common.scanResult.deviceDropdown.multipleLabel',
  );
  return (
    <FormControl variant="standard" className={classes.deviceDropdown}>
      <Select
        disabled={disabled}
        multiple
        renderValue={(selected) => {
          if (!selected.length) return emptyLabel;
          if (selected.length > 1) return multipleLabel;

          return devices
            .filter((d) => selected.includes(d.id))
            .map((d) => d.name)
            .join(', ');
        }}
        value={filterDeviceIds}
        onChange={handleChange}
        onClose={handleClose}
        displayEmpty={true}
      >
        {devices.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={filterDeviceIds.indexOf(id) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
