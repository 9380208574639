import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionsBar } from '../../../../components/ActionsBar';
import { FancyCard } from '../../../../components/FancyCard';
import { FieldWithTooltip, TextField } from '../../../../components/Fields';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: '8px',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
});

export type DeviceModelData = {
  deviceModelName: string;
  deviceTypeId: string;
};

type DeviceModelFormProps = DeviceModelData & {
  loading?: boolean;
  id: string;
  onSubmit: (deviceModel: DeviceModelData) => void;
  onDiscard: () => void;
};

export const DeviceModelForm: React.FC<DeviceModelFormProps> = ({
  deviceModelName,
  deviceTypeId,
  onSubmit,
  onDiscard,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deviceModels', 'general']);

  const handleSubmit = (data: DeviceModelData) => {
    onSubmit(data);
  };

  const validationSchema = Yup.object().shape({
    deviceModelName: Yup.string().required(t('general:errors.required')),
  });

  const deviceModelData: DeviceModelData = {
    deviceModelName,
    deviceTypeId,
  };

  return (
    <Formik
      initialValues={deviceModelData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        submitForm,
      }: FormikProps<DeviceModelData>) => (
        <>
          <ActionsBar>
            <Button
              onClick={onDiscard}
              color="primary"
              size="large"
              className={classes.actionButton}
              aria-label="discard-button"
            >
              {t('general:buttons.discard')}
            </Button>
            <Button
              onClick={submitForm}
              className={classes.actionButton}
              color="secondary"
              variant="outlined"
              size="large"
              aria-label="save-button"
              disabled={loading}
            >
              {t('general:buttons.save')}
            </Button>
          </ActionsBar>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="stretch" direction="row">
              <Grid item xs={12}>
                <FancyCard
                  title={t('modeleditor:deviceModel.create.title')}
                  loading={loading}
                >
                  <Grid
                    container
                    spacing={1}
                    className={classes.cardContent}
                    direction="column"
                  >
                    <Grid item xs={12} md={6}>
                      <Field
                        disabled={loading}
                        id="deviceModelName"
                        name="deviceModelName"
                        label={t(
                          'modeleditor:deviceModel.create.deviceModelName',
                        )}
                        component={FieldWithTooltip}
                        innerComponent={TextField}
                        tooltip={{
                          content: t(
                            'modeleditor:deviceModel.create.deviceModelNameTooltip',
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </FancyCard>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};
