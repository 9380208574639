import React, { useState } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import {
  DeviceScanStats,
  DeviceScanStatsTable,
} from '../common/DeviceScanStatsTable';
import {
  ObjectScansStats,
  ObjectScanStatsTable,
} from '../common/ObjectScanStatsTable';
import { MappingStats, MappingStatsTable } from '../common/MappingStatsTable';
import { FancyCard } from '../../../components/FancyCard';
import { BasicConfirmAlert } from '../../../components/Alerts/BasicConfirmAlert';
import { DeploymentStatus } from '../../../api/bacnet/common';

const useStyles = makeStyles({
  card: {
    marginBottom: 24,
  },
});

export type Deployment = {
  id: string;
  gatewayId: string;
  status: DeploymentStatus;
  deviceScan: DeviceScanStats;
  objectScans: ObjectScansStats;
  mapping: MappingStats;
  errorMessage?: string;
  createdAt: string;
  deployedAt: string;
};

export type DeploymentListCardProps = {
  loadingDelete?: boolean;
  loadingRestore?: boolean;
  deployment: Deployment;
  onRestoreMappings: () => void;
  onViewDetails: () => void;
  onDelete: () => void;
};

export const DeploymentListCard: React.FC<DeploymentListCardProps> = ({
  loadingDelete,
  loadingRestore,
  deployment,
  onRestoreMappings,
  onViewDetails,
  onDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);
  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const [shouldConfirmRestore, setShouldConfirmRestore] = useState(false);

  const confirmRestore = () => setShouldConfirmRestore(true);
  const handleCancelRestore = () => setShouldConfirmRestore(false);
  const handleRestore = () => {
    setShouldConfirmRestore(false);
    onRestoreMappings();
  };

  const confirmDelete = () => setShouldConfirmDelete(true);
  const handleCancelDelete = () => setShouldConfirmDelete(false);
  const handleDelete = () => {
    setShouldConfirmDelete(false);
    onDelete();
  };

  const canRestore = !!deployment.mapping?.total;
  const deviceScanResult = deployment?.deviceScan;
  const objectScanResult = deployment?.objectScans;

  return (
    <>
      <FancyCard
        className={classes.card}
        title={new Date(deployment.deployedAt).toLocaleString()}
        actions={
          <>
            <IconButton
              aria-label={`restore-mappings-button`}
              data-testid={`restore-mappings-button`}
              onClick={confirmRestore}
              size="medium"
              color="primary"
              disabled={!canRestore || loadingRestore}
            >
              <Tooltip
                arrow
                title={
                  t('deployments:deploymentList.card.restoreMappings') || ''
                }
                placement="bottom"
              >
                <Icons.RestorePage fontSize="medium" />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-label="view-details-button"
              data-testid="view-details-button"
              onClick={onViewDetails}
              size="medium"
              color="primary"
            >
              <Tooltip
                arrow
                title={t('deployments:deploymentList.card.viewDetails') || ''}
                placement="bottom"
              >
                <Icons.FindInPage fontSize="medium" />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-label="delete-button"
              data-testid="delete-button"
              onClick={confirmDelete}
              size="medium"
              color="primary"
              disabled={loadingDelete}
            >
              <Tooltip
                arrow
                title={t('deployments:deploymentList.card.delete') || ''}
                placement="bottom"
              >
                <Icons.Delete fontSize="medium" />
              </Tooltip>
            </IconButton>
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <DeviceScanStatsTable
              status={deviceScanResult?.status}
              startedAt={deviceScanResult?.startedAt}
              finishedAt={deviceScanResult?.finishedAt}
              numOfDevices={deviceScanResult?.numOfDevices}
            />
          </Grid>
          <Grid item xs={12} lg={4} id="scan column">
            <ObjectScanStatsTable objectScans={objectScanResult} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <MappingStatsTable stats={deployment.mapping} />
          </Grid>
        </Grid>
      </FancyCard>
      <BasicConfirmAlert
        open={shouldConfirmRestore}
        onCancel={handleCancelRestore}
        onConfirm={handleRestore}
        title={t('deployments:deploymentList.card.restoreMappingsAlert.title')}
        message={t(
          'deployments:deploymentList.card.restoreMappingsAlert.message',
        )}
      />
      <BasicConfirmAlert
        open={shouldConfirmDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        title={t('deployments:deploymentList.card.deleteDeploymentAlert.title')}
        message={t(
          'deployments:deploymentList.card.deleteDeploymentAlert.message',
        )}
      />
    </>
  );
};
