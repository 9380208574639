import urljoin from 'url-join';
import { COMMISSIONING_SERVICE_URI } from '../../utils/env';

export type BACnetObject = {
  objectIdentifier: string;
  objectName?: string;
  presentValue?: string;
  description?: string;
  eventState?: string;
  reliability?: string;
  outOfService?: string;
  units?: string;
  covIncrement?: string;
  inactiveText?: string;
  activeText?: string;
  minimumOffTime?: string;
  minimumOnTime?: string;
  alarmValue?: string;
  timeDelay?: string;
  notificationClass?: string;
  highLimit?: string;
  lowLimit?: string;
  deadband?: string;
  limitEnable?: string;
  eventEnable?: string;
  notifyType?: string;
};
export type EnrichedBACnetObject = {
  'X-object-type-is-mappable'?: boolean;
  'X-object-type'?: string;
  'X-unit'?: {
    id: string;
    uri?: string;
    name: string;
    unitSymbol: string;
  };
};
export type BACnetDevice = {
  objectIdentifier: string;
  deviceAddressBinding?: string;
  objectName: string;
  systemStatus?: string;
  vendorName?: string;
  vendorIdentifier?: string;
  modelName?: string;
  firmwareRevision?: string;
  applicationSoftwareVersion?: string;
  location?: string;
  description?: string;
  protocolVersion?: string;
  protocolRevision?: string;
  protocolServicesSupported?: string;
  protocolObjectTypesSupported?: string;
  maxApduLengthAccepted?: string;
  segmentationSupported?: string;
  maxSegmentsAccepted?: string;
  localTime?: string;
  localDate?: string;
  utcOffset?: string;
  daylightSavingsStatus?: string;
  apduSegmentTimeout?: string;
  apduTimeout?: string;
  numberOfApduRetries?: string;
  maxMaster?: string;
  maxInfoFrames?: string;
  databaseRevision?: string;
  lastRestoreTime?: string;
  backupFailureTimeout?: string;
  backupPreparationTime?: string;
  restoreCompletionTime?: string;
  restorePreparationTime?: string;
  backupAndRestoreState?: string;
  slaveProxyEnable?: string;
  autoSlaveDiscovery?: string;
  lastRestartReason?: string;
  timeOfDeviceRestart?: string;
  object?: Array<BACnetObject & EnrichedBACnetObject>;
};

export type GatewayConfigEntity = {
  host: string;
  clientId: string;
  user: string;
  port: string;
  password: string;
  primaryAuthKey: string;
  primaryConnectionString: string;
  secondaryAuthKey: string;
  secondaryConnectionString: string;
};

export type ScanResultDataEntity = {
  unit: {
    device: BACnetDevice[];
  };
};

export type MappingStatsEntity = {
  total?: number;
  new?: number;
  changed?: number;
  removed?: number;
};
export type ScanStatus = 'started' | 'completed' | 'failed' | 'timedout';
export type DeviceScanStatsEntity = {
  id: string;
  status: ScanStatus;
  numOfDevices?: number;
  errorMessage?: string;
  createdAt: string;
  startedAt: string;
  finishedAt?: string;
};
export type ObjectScanStatsEntity = {
  id: string;
  deviceId: string;
  status: ScanStatus;
  numOfObjects?: number;
  errorMessage?: string;
  createdAt: string;
  startedAt: string;
  finishedAt?: string;
};
export type ScanStatsEntity = {
  id: string;
  status: ScanStatus;
  numOfObjects?: number;
  errorMessage?: string;
  createdAt: string;
  startedAt: string;
  finishedAt?: string;
};
export type ObjectScanResultEntity = Array<
  ObjectScanStatsEntity & {
    numOfObjects: number;
    scanResult: ScanResultDataEntity;
    createdAt: string;
    finishedAt: string;
  }
>;
export type DeviceScanResultEntity = DeviceScanStatsEntity & {
  numOfDevices: number;
  scanResult: ScanResultDataEntity;
  finishedAt: string;
};

export type DeploymentDeviceScanStatsEntity = DeviceScanStatsEntity & {
  numOfDevices: number;
  finishedAt: string;
};
export type DeploymentObjectScanStatsEntity = ObjectScanStatsEntity & {
  numOfObjects: number;
  finishedAt: string;
};

export type DeploymentStatus = 'created' | 'deploying' | 'deployed' | 'failed';
export type DeploymentEntity = {
  id: string;
  gatewayId: string;
  status: DeploymentStatus;
  deviceScan: DeploymentDeviceScanStatsEntity | null;
  objectScans: Array<DeploymentObjectScanStatsEntity>;
  mapping: MappingStatsEntity;
  errorMessage?: string;
  createdAt: string;
  deployedAt: string;
};

export type GatewayStatus =
  | 'idle'
  | 'scanning_devices'
  | 'scanning_objects'
  | 'deploying'
  | 'offline';
export type GatewayDetailsEntity = {
  status: GatewayStatus;
  deploymentError: string | null;
  deviceScan: DeviceScanStatsEntity | null;
  objectScans: Array<ObjectScanStatsEntity>;
  mapping: MappingStatsEntity | null;
  errorMessage?: string;
};

export type Mapping = {
  bacnetDeviceId: string;
  bacnetObjectId: string;
  mappedDeviceId: string;
  mappedCapabilityId: string;
};

export type MappingsEntity = Array<Mapping>;

export type DeviceScanEntity = {
  numOfDevices: number;
  status: ScanStatus;
  startedAt: string;
  finishedAt?: string;
  error: null | string;
};

export type DevicesEntity = Array<{
  id: string;
  scan: null | {
    status: ScanStatus;
    startedAt: string;
    finishedAt?: string;
    numOfObjects: number;
    error: null | string;
  };
  details?: {
    objectIdentifier: string;
    objectName: string;
    vendorName: string;
    modelName: string;
    firmwareRevision: string;
    applicationSoftwareVersion: string;
    location: string;
    description: string;
  };
}>;

export enum ErrorCodes {
  NO_PROVISION_FOUND = 'NO_PROVISION_FOUND',
}

export const url = (segment: string): string =>
  urljoin(COMMISSIONING_SERVICE_URI, segment);
