import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { DeploymentsOverviewContainer } from './overview/DeploymentsOverviewContainer';
import { DeploymentsDetailsContainer } from './details/DeploymentsDetailsContainer';
import { DeploymentsEditContainer } from './edit/DeploymentsEditContainer';
import { DeviceCreateContainer } from './device/create/DeviceCreateContainer';
import { NotFound } from '../errorScreens';
import { MappingListContainer } from './mappingList/MappingListContainer';
import { DeploymentListContainer } from './deploymentList/DeploymentListContainer';
import { DiscoveryOverviewContainer } from './discovery/DiscoveryOverviewContainer';

const Icon = Pages.DEPLOYMENTS.icon;

export const DeploymentsRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<DeploymentsOverviewContainer />} />}
      />
      <Route
        path="/current/:gatewayId"
        element={<ProtectedRoute element={<DeploymentsEditContainer />} />}
      />
      <Route
        path="/current/:gatewayId/newDevice"
        element={<ProtectedRoute element={<DeviceCreateContainer />} />}
      />
      <Route
        path="/current/:gatewayId/mappings"
        element={<ProtectedRoute element={<MappingListContainer />} />}
      />
      <Route
        path="/list/:gatewayId"
        element={<ProtectedRoute element={<DeploymentListContainer />} />}
      />
      <Route
        path="/list/:gatewayId/:deploymentId"
        element={<ProtectedRoute element={<DeploymentsDetailsContainer />} />}
      />
      <Route
        path="/list/:gatewayId/:deploymentId/mappings"
        element={<ProtectedRoute element={<MappingListContainer />} />}
      />
      <Route
        path="/discovery/:gatewayId"
        element={<ProtectedRoute element={<DiscoveryOverviewContainer />} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
