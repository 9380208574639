import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../utils/helpers';

type Action = {
  label: string;
  onAction: () => void;
};
export type ErrorAlertProps = {
  title: string;
  message?: string;
  error?: Error | ApolloError;
  actions?: Action[];
  showOk?: boolean;
};
export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  title,
  message,
  error,
  actions,
  showOk = true,
}) => {
  const { t } = useTranslation(['general']);

  const [errorShow, setErrorShow] = useState(!!error);
  useEffect(() => {
    setErrorShow(!!error);
  }, [error]);

  return (
    <Dialog
      open={errorShow}
      onClose={actions?.length ? undefined : () => setErrorShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getErrorMessage({ error, message }, t('general:error'))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions?.map(
          ({ label, onAction }) =>
            label &&
            onAction && (
              <Button key={label} onClick={onAction}>
                {label}
              </Button>
            ),
        )}
        {showOk && (
          <Button onClick={() => setErrorShow(false)} color="primary">
            {t('general:buttons.ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
