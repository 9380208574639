import { useEffect, useRef, useState, useCallback } from 'react';
import { RequestError } from '../api/utils/RequestError';

interface RequestState<T> {
  response?: T;
  loading: boolean;
  error?: RequestError;
}

interface RefreshableRequestState<T> extends RequestState<T> {
  refresh: () => void;
}

export interface Response<T> {
  data?: T;
  error?: RequestError;
}

export const useRequest = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...params: any) => Promise<any>,
  Q = ReturnType<T>,
  R = Q extends Promise<infer F> ? F : Q,
  M = R extends Response<infer Z> ? Z : R,
>(
  requestFn: T,
  ...params: Parameters<T>
): RefreshableRequestState<M> => {
  const [state, setState] = useState<RequestState<M>>({
    loading: true,
  });

  // This keeps the state of the component using the hook. In case it is not mounted
  // we don't modify the state
  const mounted = useRef(true);

  const request = () => {
    setState((currentState) => ({ ...currentState, loading: true }));
    requestFn(...params)
      .then(({ error, data }: Response<M>) => {
        const newState = { response: data, loading: false, error };
        if (mounted.current) {
          setState(newState);
        }
        return newState;
      })
      .catch((error: Error) => {
        if (!mounted.current) {
          return;
        }
        setState({ loading: false, error });
        console.warn(error);
      });
  };

  useEffect(() => {
    mounted.current = true;
    request();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, params);

  return { ...state, refresh: request };
};

interface PreparedRequestOptions<T> {
  onCompleted?: (data: T | undefined) => void;
  onError?: (error: Error) => void;
}

export const usePrepareRequest = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...params: any) => Promise<any>,
  Q = ReturnType<T>,
  R = Q extends Promise<infer F> ? F : Q,
  M = R extends Response<infer Z> ? Z : R,
>(
  requestFn: T,
  { onCompleted, onError }: PreparedRequestOptions<M> = {},
): [
  (...params: Parameters<T>) => Promise<RequestState<M>>,
  RequestState<M>,
] => {
  const [state, setState] = useState<RequestState<M>>({ loading: false });

  // This keeps the state of the component using the hook. In case it is not mounted
  // we don't modify the state
  const mounted = useRef(true);

  const action = useCallback(
    (...params: Parameters<T>) => {
      setState({ loading: true });
      return requestFn(...params)
        .then(({ error, data }: Response<M>) => {
          if (error) {
            throw error;
          }
          if (mounted.current) {
            setState({ response: data, loading: false, error });
            if (onCompleted) {
              onCompleted(data);
            }
          }
          return { response: data, loading: false, error };
        })
        .catch((error: Error) => {
          if (!mounted.current) {
            return { loading: false, error };
          }
          if (onError) {
            onError(error);
          }
          setState({ loading: false, error });
          console.warn(error);
          return { loading: false, error };
        });
    },
    [requestFn, onCompleted, onError],
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  return [action, state];
};
