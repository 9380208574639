import React from 'react';

export const Logo: React.FC = () => {
  return (
    <svg
      width="200"
      height="24"
      viewBox="0 0 200 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M166.513 21.7131H32V23.9999H166.513V21.7131Z" fill="#F07D00" />
      <path
        d="M39.1547 1.24536L35.4189 8.35479V1.24536H32V17.1397H35.4189V8.80762L39.3585 17.1397H43.2302L38.7019 8.49064L42.8 1.24536H39.1547Z"
        fill="#003C64"
      />
      <path
        d="M44.8151 7.87933L43.4792 7.56235V5.45669H48.0528V17.1397H44.8151V7.87933ZM46.4453 4.0982C47.5094 4.0982 48.3925 3.23782 48.3925 2.15103C48.3925 1.06424 47.5321 0.203857 46.4226 0.203857C45.3811 0.203857 44.4981 1.06424 44.4981 2.17367C44.4981 3.23782 45.3359 4.0982 46.4453 4.0982Z"
        fill="#003C64"
      />
      <path
        d="M56.385 10.1208H53.3737C53.4643 8.01519 53.8492 7.49443 54.8907 7.49443C56.1133 7.49443 56.3397 8.2416 56.385 10.1208ZM59.6454 12.3171V11.3888C59.6454 7.08688 58.2643 5.16235 54.7548 5.16235C51.6982 5.16235 49.8643 6.83783 49.8643 11.2529C49.8643 15.6454 51.6303 17.4567 55.5246 17.4567C57.2001 17.4567 58.5586 16.868 59.419 16.0529L58.0152 13.902C57.3133 14.4454 56.5661 14.8982 55.4567 14.8982C54.0303 14.8982 53.4643 14.4454 53.3511 12.3171H59.6454Z"
        fill="#003C64"
      />
      <path
        d="M67.7283 11.2077C67.7283 14.1738 67.4113 14.853 66.1434 14.853C65.4641 14.853 64.966 14.5587 64.6038 14.2417V8.42281C65.034 8.06055 65.5094 7.7662 66.2566 7.7662C67.434 7.74356 67.7283 8.33224 67.7283 11.2077ZM71.1698 11.0266C71.1698 6.453 69.7434 5.16243 67.2755 5.16243C66.0981 5.16243 65.0792 5.7964 64.6038 6.24923V0.407715H61.3887V17.1398H64.0151L64.2868 16.0756C64.8981 16.7775 65.9623 17.4568 67.2075 17.4568C69.834 17.4341 71.1698 15.9398 71.1698 11.0266Z"
        fill="#003C64"
      />
      <path
        d="M78.1207 14.6039C77.7584 14.9208 77.3282 15.2152 76.6716 15.2152C75.8339 15.2152 75.5396 14.8076 75.5396 13.6303C75.5396 12.3624 75.8792 11.8416 77.9622 11.8416H78.1207V14.6039ZM81.3358 17.1397V8.94349C81.3358 6.20387 80.0679 5.16235 77.1245 5.16235C75.2679 5.16235 73.8867 5.70575 72.5962 6.58877L73.7509 8.78499C74.6339 8.26424 75.6754 7.78877 76.7848 7.78877C77.8716 7.78877 78.0981 8.28688 78.0981 9.46424V9.89443H77.3962C73.932 9.89443 72.3245 10.9812 72.3245 13.7208C72.3245 16.0303 73.3207 17.4793 75.766 17.4793C77.0339 17.4793 77.8716 16.9359 78.4603 16.2567C78.6188 16.7095 78.7547 17.185 78.7773 17.185C78.7999 17.1397 81.3358 17.1397 81.3358 17.1397Z"
        fill="#003C64"
      />
      <path
        d="M88.0153 17.4339C89.5775 17.4339 90.687 16.9132 91.5247 16.0981L90.1662 13.9471C89.5775 14.4679 89.1473 14.8075 88.219 14.8075C86.8832 14.8075 86.4756 14.1056 86.4756 11.1622C86.4756 8.21883 86.9285 7.72072 88.1285 7.72072C88.8983 7.72072 89.3964 8.03769 89.9171 8.58109L91.502 6.47543C90.5285 5.56977 89.3964 5.11694 87.8794 5.11694C84.6869 5.11694 83.0115 6.88298 83.0115 11.2075C83.0115 15.4868 84.619 17.4339 88.0153 17.4339Z"
        fill="#003C64"
      />
      <path
        d="M92.7925 0.407715H96.0076V17.1398H92.7925V0.407715ZM99.3359 17.1398H102.845L99.2906 10.2341L102.189 5.45678H98.5887L96.0302 10.5058L99.3359 17.1398Z"
        fill="#003C64"
      />
      <path
        d="M126.008 6.38499C126.008 8.0831 125.351 8.53593 123.97 8.53593H122.498V4.12084H124.106C125.374 4.12084 126.008 4.55102 126.008 6.38499ZM129.517 6.13593C129.517 2.40008 127.479 1.24536 123.743 1.24536H119.079V17.1397H122.498V11.3208H124.015C128.43 11.2982 129.517 9.2831 129.517 6.13593Z"
        fill="#003C64"
      />
      <path
        d="M136.83 10.1208H133.819C133.91 8.01519 134.295 7.49443 135.336 7.49443C136.559 7.49443 136.785 8.2416 136.83 10.1208ZM140.113 12.3171V11.3888C140.113 7.08688 138.732 5.16235 135.223 5.16235C132.166 5.16235 130.332 6.83783 130.332 11.2529C130.332 15.6454 132.098 17.4567 135.993 17.4567C137.668 17.4567 139.027 16.868 139.887 16.0529L138.483 13.902C137.781 14.4454 137.034 14.8982 135.925 14.8982C134.498 14.8982 133.932 14.4454 133.819 12.3171H140.113Z"
        fill="#003C64"
      />
      <path
        d="M140.996 7.90181H142.196V14.5131C142.196 16.7546 143.51 17.4339 145.479 17.4339C146.566 17.4339 147.223 17.1622 147.721 16.9358L147.291 14.7395C147.087 14.8075 146.77 14.9886 146.294 14.9886C145.728 14.9886 145.434 14.7622 145.434 13.7433V7.90181H147.585V5.45653H145.434V2.0603L142.219 2.6037V5.45653H141.019V7.90181H140.996Z"
        fill="#003C64"
      />
      <path
        d="M155.102 10.1208H152.091C152.181 8.01519 152.566 7.49443 153.608 7.49443C154.83 7.49443 155.057 8.2416 155.102 10.1208ZM158.362 12.3171V11.3888C158.362 7.08688 156.981 5.16235 153.472 5.16235C150.415 5.16235 148.581 6.83783 148.581 11.2529C148.581 15.6454 150.347 17.4567 154.242 17.4567C155.917 17.4567 157.276 16.868 158.136 16.0529L156.732 13.902C156.03 14.4454 155.283 14.8982 154.174 14.8982C152.747 14.8982 152.181 14.4454 152.068 12.3171H158.362Z"
        fill="#003C64"
      />
      <path
        d="M160.106 17.1398H163.321V8.83035C163.819 8.46809 164.226 8.28696 165.019 8.28696C165.472 8.28696 165.879 8.42281 166.174 8.60394L166.74 5.38884C166.536 5.34356 166.174 5.27563 165.834 5.27563C164.725 5.27563 163.728 5.81904 163.026 6.81526L162.732 5.47941H160.106V17.1398Z"
        fill="#003C64"
      />
      <path
        d="M117.155 14.9434H116.951C116.113 14.966 115.728 14.8302 115.298 14.4226C114.959 14.1056 114.347 13.3811 114.347 13.3811C114.868 12.2038 115.23 11.0264 115.23 7.63018V7.13206H112.876V7.65281C112.876 9.69055 112.785 10.7321 112.604 11.3887C112.106 10.8 111.517 10.1207 110.815 9.30565C110.815 9.30565 109.819 8.12829 109.615 7.90187C108.234 6.31697 107.351 5.25282 107.351 4.05282C107.351 3.30565 107.51 2.64905 109.004 2.64905C110.249 2.64905 110.657 3.10187 110.657 4.07546V5.41131H113.125C113.125 5.41131 113.125 4.00754 113.125 3.9849C113.125 3.01131 112.785 2.12829 112.151 1.51697C111.449 0.815086 110.408 0.430176 109.162 0.430176H108.8C106.604 0.430176 104.861 1.53961 104.861 3.9849C104.861 5.45659 105.404 6.38489 106.4 7.67546C104.544 8.71697 103.615 10.2566 103.615 12.2943C103.615 15.2604 105.676 17.1622 108.868 17.1622C110.702 17.1622 112.083 16.6415 113.079 15.5547C113.713 16.2566 113.985 16.4604 114.279 16.6415C114.868 17.0038 115.411 17.1622 116 17.1849C116.113 17.1849 116.702 17.1849 117.11 17.1849L117.155 14.9434ZM109.072 14.8755C107.713 14.8755 106.128 14.4226 106.128 12.249C106.128 10.8906 106.649 9.98489 107.804 9.30565L111.585 13.766C110.974 14.5132 110.204 14.8755 109.072 14.8755Z"
        fill="#003C64"
      />
    </svg>
  );
};
