import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FancyCard } from '../../../../components/FancyCard';
import {
  AutocompleteField,
  FieldWithTooltip,
  TextField,
  DescriptionField,
} from '../../../../components/Fields';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  cardContent: {
    padding: '22px 16px 10px 16px',
  },
  field: {
    minHeight: '70px',
  },
});

export interface DeviceFormData {
  name: string;
  description?: string;
  fieldDeviceId?: string;
  parentDeviceId?: string;
  deviceModelId: string;
}

type DeviceFormInfoProps = {
  gateway?: { id: string; name: string };
  allDeviceModels: Array<{
    id: string;
    name: string;
  }>;
  onSelectModel?: (deviceModelId: string) => void;
  onChangeName?: (value: string) => void;
  validateFieldDeviceId: (input: {
    fieldDeviceId?: string;
    parentDeviceId?: string;
  }) => void;
  loading?: boolean;
  type: 'edit' | 'create';
};

export const getDeviceFormInfoSchema = (
  t: TFunction,
  valid: boolean,
): Yup.SchemaOf<DeviceFormData> =>
  Yup.object().shape({
    name: Yup.string().required(t('general:errors.required')),
    description: Yup.string().optional(),
    deviceModelId: Yup.string()
      .required(t('general:errors.required'))
      .notOneOf(['0'], t('deployments:device.create.selectError')),
    parentDeviceId: Yup.string()
      .required(t('general:errors.required'))
      .notOneOf(['0'], t('deployments:device.create.selectError'))
      .test('parentDeviceId', 'notUniqueError', (value, { createError }) => {
        return !valid
          ? createError({
              path: 'fieldDeviceId',
              message: t('deployments:device.create.notUniqueError'),
            })
          : true;
      }),
    fieldDeviceId: Yup.string()
      .required(t('general:errors.required'))
      .test(
        'fieldDeviceId',
        t('deployments:device.create.notUniqueError'),
        (value) => !value || valid,
      ),
  });

export const DeviceFormInfo: React.FC<DeviceFormInfoProps> = ({
  loading,
  onSelectModel,
  onChangeName,
  gateway,
  allDeviceModels,
  validateFieldDeviceId,
  type,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);

  const handleSelectModel = useCallback(
    (event: React.ChangeEventHandler<unknown>, value: string | null) => {
      if (onSelectModel) {
        onSelectModel(value || '0');
      }
    },
    [onSelectModel],
  );
  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const { value } = event.target;
    onChangeName && onChangeName(value);
  };

  const handleValidateFieldDeviceId: (
    id: 'fieldDeviceId',
  ) => React.ChangeEventHandler<HTMLInputElement> =
    (id: string) => (event, value?: string) => {
      validateFieldDeviceId({
        [id]: value || event.target.value,
      });
    };

  const deviceModelOptions = useMemo(
    () =>
      allDeviceModels
        .map(({ id, name: label }) => ({
          id,
          label,
        }))
        .concat([
          {
            id: '0',
            label: t('deployments:device.create.form.selectDeviceModel'),
          },
        ]),
    [allDeviceModels, t],
  );

  return (
    <FancyCard
      title={t('deployments:device.create.form.title')}
      className={type === 'create' ? classes.root : undefined}
      loading={loading}
    >
      <Grid container spacing={1} className={classes.cardContent}>
        <Grid item xs={12} md={4}>
          <Field
            disabled={loading}
            id="name"
            name="name"
            label={t('deployments:device.create.form.name')}
            component={TextField}
            onBlur={handleChangeName}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Field
            disabled={loading}
            id="description"
            name="description"
            component={DescriptionField}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Field
            disabled={type === 'edit' || loading}
            id="deviceModelId"
            name="deviceModelId"
            data-testid="device-model-select"
            className={classes.field}
            options={deviceModelOptions}
            component={FieldWithTooltip}
            innerComponent={AutocompleteField}
            onChange={handleSelectModel}
            fullWidth
            label={t('deployments:device.create.form.deviceModel')}
            tooltip={{
              content: t('deployments:device.create.form.deviceModelTooltip'),
              type: 'warning',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            disabled={loading}
            id="fieldDeviceId"
            name="fieldDeviceId"
            label={t('deployments:device.create.form.fieldDeviceId')}
            component={FieldWithTooltip}
            innerComponent={TextField}
            onChange={handleValidateFieldDeviceId('fieldDeviceId')}
            tooltip={{
              content: t('deployments:device.create.form.fieldDeviceIdTooltip'),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            disabled
            id="parentDevice"
            name="parentDevice"
            label={t('deployments:device.create.form.parentDevice')}
            component={TextField}
          />
        </Grid>
      </Grid>
    </FancyCard>
  );
};
