import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { createRangeString } from '../../../utils/helpers';

const useStyles = makeStyles({
  tooltipContent: {
    padding: 0,
    fontSize: '14px',
    lineHeight: '16px',
  },
  tooltipItem: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
});

type Capability = {
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
  assigned?: {
    bacnetDeviceId: string;
    bacnetObjectId: string;
  };
};

type CapabilityInfoIconProps = { capability: Capability };
export const CapabilityInfoIcon: React.FC<CapabilityInfoIconProps> = ({
  capability,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div className={classes.tooltipContent}>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('deployments:common.deviceCapabilities.range')}:
            </div>
            <div className={classes.tooltipItem}>
              {createRangeString(capability)}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('deployments:common.deviceCapabilities.unit')}:
            </div>
            <div className={classes.tooltipItem}>{capability.unitName}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.tooltipItem}>
              {t('deployments:common.deviceCapabilities.assigned')}:
            </div>
            <div className={classes.tooltipItem}>
              {capability.assigned
                ? `${capability.assigned?.bacnetDeviceId}, ${capability.assigned?.bacnetObjectId}`
                : '-'}
            </div>
          </div>
        </div>
      }
    >
      <Icons.Info fontSize="medium" color="action" />
    </Tooltip>
  );
};
