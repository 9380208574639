import React from 'react';
import { Field } from 'formik';
import { makeStyles } from '@mui/styles';
import { Checkbox } from '@mui/material';
import { DataTypes } from '../../__generated__/types';
import { DataTypeInput } from '../DataTypeInput';

export interface AttributeData {
  id: string;
  dataType: DataTypes | null;
  name: string;
  value?: string;
  required: boolean;
  enabled?: boolean;
}

type DeviceAttributeFieldProps = AttributeData & {
  fieldId: string;
  onChange: (id: string, value: string | boolean) => void;
  loading?: boolean;
  touched?: boolean;
  error?: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {},
  field: {
    flexGrow: 1,
  },
});

export const DeviceAttributeField: React.FC<DeviceAttributeFieldProps> = ({
  fieldId,
  name,
  value,
  required,
  enabled,
  dataType,
  onChange,
  loading,
  touched,
  error,
}) => {
  const classes = useStyles();

  const handleChangeValue = (v: string) => {
    onChange(`${fieldId}.value`, v);
  };

  const handleChangeEnabled = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(`${fieldId}.enabled`, checked);
  };

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <Field
          component={DataTypeInput}
          disabled={loading || !enabled}
          id={`${fieldId}.value`}
          name={`${fieldId}.value`}
          label={name}
          dataType={dataType}
          value={value}
          onChange={handleChangeValue}
          autoFocus={false}
          error={touched && Boolean(error)}
          helperText={(touched && error) || ' '}
          optional={!required}
        />
      </div>
      <div className={classes.checkbox}>
        <Field
          component={Checkbox}
          color="primary"
          type="checkbox"
          size="small"
          inputProps={{ 'data-testid': `${fieldId}-checkbox` }}
          onChange={handleChangeEnabled}
          disabled={required}
          checked={enabled}
          name={`${fieldId}.enabled`}
        />
      </div>
    </div>
  );
};
