import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import { RequestError } from '../api/utils/RequestError';
import { Location } from '../__generated__/types';

export const createRangeString = (
  capability?: {
    unitSymbol?: string;
    technicalMin?: number;
    technicalMax?: number;
  } | null,
): string => {
  if (!capability) return '-';

  const symbol = capability.unitSymbol || '';

  if (
    capability.technicalMin !== undefined &&
    capability.technicalMax === undefined
  )
    return `> ${capability.technicalMin}${symbol}`;
  if (
    capability.technicalMin === undefined &&
    capability.technicalMax !== undefined
  )
    return `< ${capability.technicalMax}${symbol}`;

  if (
    capability.technicalMin !== undefined &&
    capability.technicalMax !== undefined
  )
    return `${capability.technicalMin}${symbol} - ${capability.technicalMax}${symbol}`;

  return '-';
};

export type Address = Pick<
  Location,
  'street' | 'no' | 'zip' | 'city' | 'country'
> | null;
export const createAddressString = (address?: Address): string => {
  if (!address) return '';

  let result = '';
  if (address.street) result += address.street;
  if (address.no) result += ` ${address.no}`;
  if (result.length) result += ',';
  if (address.zip) result += ` ${address.zip}`;
  if (address.city) result += ` ${address.city}`;
  if (result.length && !result.endsWith(',')) result += ',';
  if (address.country) result += ` ${address.country}`;
  if (result.endsWith(',')) result = result.slice(0, -1);
  return result.trim();
};

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

type ErrorInput = {
  error?: Error | ApolloError | RequestError;
  message?: string;
};

export const getErrorMessage = (
  { error, message }: ErrorInput,
  defaultMessage: string,
): string => {
  const info = message ? `${message}: ` : '';
  if (error) {
    if (typeof error === 'string') {
      return `${info}${error}`;
    }
    if ('responseMessage' in error && error.responseMessage) {
      return `${info}${error.responseMessage}`;
    }
    if ('message' in error && error.message) {
      return `${info}${error.message}`;
    }
  }
  return message || defaultMessage;
};

export const formatDate = (value?: string | number | null): string => {
  return value ? format(new Date(value), 'dd-MM-yyyy HH:mm') : '';
};
