import { Permissions } from '@kp/react-sdk';

export const requiredPermissions = [
  Permissions.CommissioningRead,
  Permissions.CommissioningWrite,
  Permissions.BuildingsRead,
  Permissions.StoreysRead,
  Permissions.ZonesRead,
  Permissions.DevicesRead,
  Permissions.DevicesWrite,
  Permissions.DeviceDataRead,
  Permissions.DeviceModelsRead,
  Permissions.DeviceModelsWrite,
  Permissions.AttributesRead,
  Permissions.CapabilitiesRead,
  Permissions.UnitsRead,
];
