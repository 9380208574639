import type { TFunction } from 'i18next';
import type { ElementType } from 'react';
import * as Icons from '@mui/icons-material';
import { Permissions } from '@kp/react-sdk';

export interface Page {
  label: (t: TFunction) => string;
  icon: ElementType;
  path: string;
  childPaths?: string[];
  permission?: Permissions;
}

export const DASHBOARD: Page = {
  label: (t) => t('general:page.dashboard'),
  icon: Icons.Dashboard,
  path: '/',
};

export const CONFIGURATION: Page = {
  label: (t) => t('general:page.configuration'),
  icon: Icons.Settings,
  path: '/configuration',
};

export const DEPLOYMENTS: Page = {
  label: (t) => t('general:page.deployments'),
  icon: Icons.DeviceHub,
  path: '/deployments',
};

export const MONTIORING: Page = {
  label: (t) => t('general:page.monitoring'),
  icon: Icons.MonitorHeart,
  path: '/monitoring',
};

export const MODELEDITOR: Page = {
  label: (t) => t('general:page.modelEditor'),
  icon: Icons.AutoFixHigh,
  path: '/model-editor',
};
