import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { ErrorAlert } from '../../../components/Alerts';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { GatewayListContainer } from '../../../components/GatewayList';
import * as API from '../../../api/bacnet';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { ScanResultCard } from './ScanResultCard';
import { entityLocation } from '../../../utils/entity';

export const ModelEditorOverviewContainer: React.FC = () => {
  const { t } = useTranslation(['modeleditor', 'general']);
  const { setTitle, setIcon } = useHeader();
  const navigate = useNavigate();

  const [gatewayId, setGatewayId] = useState<string>();
  const [gatewayDetails, setGatewayDetails] = useState<API.GatewayDetails>();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.MODELEDITOR, t),
    location: Entities.MODELEDITOR.path,
  });
  useEffect(() => {
    setTitle({ main: t('modeleditor:select.title') });
    setIcon(Pages.DASHBOARD.icon);
  }, [setTitle, setIcon, t]);

  const [
    callGetGatewayDetails,
    { loading: loadingGetGatewayDetails, error: errorGetGatewayDetails },
  ] = usePrepareRequest(API.getGatewayDetails, {
    onCompleted: (result) => {
      setGatewayDetails(result?.data);
    },
  });

  const handleSelectGateway = (id?: string) => {
    setGatewayId(id);
    setGatewayDetails(undefined);
    if (id) callGetGatewayDetails(id);
  };

  const handleContinue = () => {
    navigate(entityLocation(Entities.MODELEDITOR, `edit/${gatewayId}`));
  };
  const hasScanResult =
    gatewayDetails?.deviceScan?.status === 'completed' &&
    gatewayDetails?.deviceScan?.numOfDevices &&
    gatewayDetails?.deviceScan?.numOfDevices > 0 &&
    gatewayDetails?.objectScans.some(
      (scan) =>
        scan.status === 'completed' &&
        scan.numOfObjects &&
        scan.numOfObjects > 0,
    );
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GatewayListContainer
            selectedId={gatewayId}
            onSelect={handleSelectGateway}
          />
        </Grid>
        <Grid item xs={12}>
          <ScanResultCard
            loading={loadingGetGatewayDetails}
            status={gatewayDetails?.deviceScan?.status}
            startedAt={gatewayDetails?.deviceScan?.startedAt}
            finishedAt={gatewayDetails?.deviceScan?.finishedAt}
            numOfObjects={gatewayDetails?.deviceScan?.numOfDevices}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={handleContinue}
            disabled={!gatewayId || !hasScanResult || loadingGetGatewayDetails}
          >
            {t('general:buttons.continue')}
          </Button>
        </Grid>
      </Grid>
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorGetGatewayDetails}
      />
    </>
  );
};
