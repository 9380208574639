export enum ThemeColor {
  lightOrange = '#ffb566',
  primaryOrange = '#f07d00',
  darkOrange = '#994f00',
  lightBlue = '#007acc',
  primaryBlue = '#003c64',
  darkBlue = '#001f33',
}

export enum CustomColor {
  headerBackground = '#09233A',
  pageHeaderBackground = 'rgba(0,0,0,.03)',
  border = '#dfdfdf',
  sidebarShadow = 'rgba(0,0,0,.08)',
  sidebarBorder = '#d8d8d8',
  contentBackground = '#243B4E',
  inputBackground = '#f8f8f8',
  separator = '#afafaf',
  copyright = '#9f9f9f',
  link = '#282d37',
  ok = '#0db82c',
  error = '#f44336',
  warning = '#ff8300',
  white = '#FFFFFF',
  lightGrey = '#bfbfbf',
  mediumGrey = '#989898',
  black = '#000000',
  selectedRow = 'rgba(240, 125, 0, 0.08)',
  assignedRow = 'rgba(0, 240, 125, 0.08)',
  currentRow = 'rgba(0, 125, 240, 0.08)',
}

export const Color = {
  ...ThemeColor,
  ...CustomColor,
};
export type ColorType = typeof Color;
