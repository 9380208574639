import React from 'react';
import { makeStyles } from '@mui/styles';
import { Color } from '../../constants/Colors';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: Color.contentBackground,
  },
});

export const LayoutSimple: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
