import React, { useState } from 'react';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  GridEnrichedColDef,
  GridFooter,
  GridRowModel,
  GridFooterContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { ActionsBar } from '../../../components/ActionsBar';
import { DataGrid } from '../../../components/DataGrid';
import { BasicConfirmAlert } from '../../../components/Alerts/BasicConfirmAlert';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: '8px',
  },
});

export type Mapping = {
  deviceName: string;
  mappedDeviceId: string;
  fieldDeviceId: string;
  fieldSelector: string;
  capabilityName: string;
  mappedCapabilityId: string;
  bacnetDeviceId: string;
  bacnetObjectId: string;
  active?: boolean;
};

interface MappingListProps {
  loading?: boolean;
  mappings: Mapping[];
  onBack: () => void;
  onDeleteAll?: () => void;
}

const columnDetails: Partial<GridEnrichedColDef> = {
  flex: 1,
};
export const MappingList: React.FC<MappingListProps> = ({
  loading,
  mappings,
  onBack,
  onDeleteAll,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);

  const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false);
  const confirmDelete = () => setShouldConfirmDelete(true);
  const handleCancelDelete = () => setShouldConfirmDelete(false);
  const handleDelete = () => {
    setShouldConfirmDelete(false);
    onDeleteAll && onDeleteAll();
  };

  const columns = [
    {
      field: 'deviceName',
      headerName: t('deployments:mappingList.headers.device'),
      ...columnDetails,
    },
    {
      field: 'mappedDeviceId',
      headerName: t('deployments:mappingList.headers.deviceId'),
      ...columnDetails,
    },
    {
      field: 'fieldDeviceId',
      headerName: t('deployments:mappingList.headers.fieldDeviceId'),
      ...columnDetails,
    },
    {
      field: 'capabilityName',
      headerName: t('deployments:mappingList.headers.capability'),
      ...columnDetails,
    },
    {
      field: 'mappedCapabilityId',
      headerName: t('deployments:mappingList.headers.capabilityId'),
      ...columnDetails,
    },
    {
      field: 'fieldSelector',
      headerName: t('deployments:mappingList.headers.fieldSelector'),
      ...columnDetails,
    },
    {
      field: 'bacnetDeviceId',
      headerName: t('deployments:mappingList.headers.bacnetDeviceId'),
      ...columnDetails,
    },
    {
      field: 'bacnetObjectId',
      headerName: t('deployments:mappingList.headers.bacnetObjectId'),
      ...columnDetails,
    },
  ];

  const rows = mappings.map(
    (el, id) =>
      ({
        id,
        ...el,
      } as GridRowModel),
  );

  return (
    <>
      <ActionsBar>
        <Button
          onClick={onBack}
          color="primary"
          size="large"
          className={classes.actionButton}
          aria-label="back-button"
        >
          {t('general:buttons.back')}
        </Button>
      </ActionsBar>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12}>
          <FancyCard
            loading={loading}
            title={t('deployments:mappingList.title')}
            actions={
              onDeleteAll && (
                <IconButton
                  aria-label="delete-button"
                  data-testid="delete-button"
                  onClick={confirmDelete}
                  size="medium"
                  color="primary"
                  disabled={!mappings.length}
                >
                  <Tooltip
                    arrow
                    title={t('deployments:mappingList.deleteAll') || ''}
                    placement="bottom"
                  >
                    <Icons.Delete fontSize="medium" />
                  </Tooltip>
                </IconButton>
              )
            }
          >
            <DataGrid
              height={330}
              components={{
                Toolbar: () => (
                  <GridFooterContainer>
                    <GridToolbarFilterButton />
                    <GridFooter />
                  </GridFooterContainer>
                ),
              }}
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              hideFooter
            />
          </FancyCard>
        </Grid>
      </Grid>
      <BasicConfirmAlert
        open={shouldConfirmDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        title={t('deployments:mappingList.deleteAllMappingsAlert.title')}
        message={t('deployments:mappingList.deleteAllMappingsAlert.message')}
      />
    </>
  );
};
