import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import {
  DataTypes,
  DeviceCreateAttributeValueType,
  useDeviceCreateFieldDeviceModelsQuery,
  useDeviceCreateFieldDeviceIdExistsLazyQuery,
  useDeviceCreateGatewayQuery,
  useDeviceCreateWithPlacementMutation,
  PlacementKind,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import {
  DeviceCreate,
  DeviceCreateData,
  ValidationState,
} from './DeviceCreate';

export const DeviceCreateContainer: React.FC = () => {
  const { t } = useTranslation(['deployments', 'general']);
  const navigate = useNavigate();
  const location = useLocation();
  const { gatewayId = '0' } = useParams();
  const { setTitle, setLoading } = useHeader();
  const [newFieldDeviceId, setNewFieldDeviceId] = useState<string>();
  const [newParentDeviceId, setNewParentDeviceId] = useState<string>();
  const [createError, setCreateError] = useState<Error>();

  const {
    loading: loadingDeviceModelsAndSites,
    error: errorDeviceModelsAndSites,
    data: dataDeviceModelsAndSites,
  } = useDeviceCreateFieldDeviceModelsQuery();
  const deviceModels = dataDeviceModelsAndSites?.deviceModels;

  const {
    loading: loadingGateway,
    error: errorGateway,
    data: dataGateway,
  } = useDeviceCreateGatewayQuery({
    variables: { gatewayId },
  });

  const gateway = dataGateway?.device || undefined;
  const placement = dataGateway?.placementOfDevice;

  const [
    validateFieldDeviceId,
    {
      called: calledFDExists,
      loading: loadingFDExists,
      error: errorFDExists,
      data: dataFDExists,
    },
  ] = useDeviceCreateFieldDeviceIdExistsLazyQuery();

  const allDeviceModels = (deviceModels || []).map((dm) => ({
    id: dm.id,
    name: dm.name,
    deviceModelAttributeSets: dm.deviceModelAttributeSets.map(
      (attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        attributes: attributeSet.mappingAttributeDeviceModelAttributeSets.map(
          (attribute) => ({
            id: attribute.attribute.id,
            name: attribute.attribute.name,
            required: attribute.attribute.required || false,
            dataType: attribute.attribute.dataType,
            value:
              attribute.attribute.required &&
              attribute.attribute.dataType === DataTypes.Bool
                ? 'false'
                : '',
          }),
        ),
      }),
    ),
  }));

  const hierarchy = gateway
    ? makeHierarchyBreadcrumb(
        [
          {
            type: Entities.DEPLOYMENTS,
            id: `current/${gatewayId}`,
            name: gateway?.name,
          },
          {
            type: Entities.DEPLOYMENTS,
            id: 'new',
            name: t('deployments:device.create.breadcrumb'),
          },
        ],
        t,
      )
    : [];
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('deployments:device.create.breadcrumb') });
    setLoading(loadingDeviceModelsAndSites || loadingGateway);
  }, [setTitle, t, setLoading, loadingDeviceModelsAndSites, loadingGateway]);

  const navigateBack = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };
  const [create, { loading: loadingCreate, error: errorCreate }] =
    useDeviceCreateWithPlacementMutation({
      onCompleted: navigateBack,
    });
  const handleFieldDeviceIdValidation = (
    fieldDeviceId: string,
    parentDeviceId: string,
  ) => {
    setNewFieldDeviceId(fieldDeviceId);
    setNewParentDeviceId(parentDeviceId);
    if (fieldDeviceId && parentDeviceId) {
      validateFieldDeviceId({
        variables: {
          fieldDeviceId,
          parentDeviceId,
        },
      });
    }
  };

  const handleCreate = (inputData: DeviceCreateData) => {
    if (!gateway) {
      setCreateError(new Error(`Can't create Device. Missing Gateway Id.`));
      return;
    }
    if (!placement?.buildingId) {
      setCreateError(new Error(`Can't create Device. Missing Building Id.`));
      return;
    }
    if (!placement?.mappingPlacementType) {
      setCreateError(new Error(`Can't create Device. Missing Placement Type.`));
      return;
    }

    const placementKind = placement.mappingPlacementType;
    if (placementKind === PlacementKind.Zone && !placement?.zoneId) {
      setCreateError(new Error(`Can't create Device. Missing Zone Id.`));
      return;
    }

    const placementId =
      placementKind === PlacementKind.Building
        ? placement.buildingId
        : placement.zoneId || '';
    const placementType =
      placementKind === PlacementKind.Building
        ? PlacementKind.Building
        : PlacementKind.Zone;

    const attributeValues: DeviceCreateAttributeValueType[] =
      inputData.attributeSets
        .map((set) =>
          set.attributes
            .filter((attribute) => attribute.required || attribute.value !== '')
            .map((attribute) => ({
              attributeId: attribute.id,
              value: attribute.value || '',
            })),
        )
        .flat();
    create({
      variables: {
        device: {
          name: inputData.name,
          description: inputData.description,
          deviceModelId: inputData.deviceModelId,
          fieldDeviceId: inputData.fieldDeviceId,
          parentDeviceId: gatewayId,
        },
        placementId,
        placementType,
        attributeValues,
      },
    }).catch(console.warn);
  };

  let fieldDeviceIdValidationState: ValidationState = 'VALID';
  if (calledFDExists && dataFDExists && dataFDExists?.devices?.length > 0)
    fieldDeviceIdValidationState = 'INVALID';
  if (!newFieldDeviceId || !newParentDeviceId)
    fieldDeviceIdValidationState = 'VALID';
  if (!calledFDExists || loadingFDExists)
    fieldDeviceIdValidationState = 'LOADING';
  if (errorFDExists) {
    fieldDeviceIdValidationState = 'ERROR';
  }

  return (
    <>
      <DeviceCreate
        loading={loadingGateway || loadingDeviceModelsAndSites || loadingCreate}
        gateway={gateway}
        allDeviceModels={allDeviceModels}
        validateFieldDeviceId={handleFieldDeviceIdValidation}
        fieldDeviceIdValidationState={fieldDeviceIdValidationState}
        onSubmit={handleCreate}
        onDiscard={navigateBack}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          errorGateway ||
          errorDeviceModelsAndSites ||
          errorCreate ||
          createError
        }
      />
    </>
  );
};
