import React, { useState } from 'react';
import {
  DialogContentText,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ConfirmAlert } from '../../../components/Alerts';

const useStyles = makeStyles({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
});

export type Action = 'deploy' | 'download';
type DeployAlertProps = {
  onConfirm: (action: Action) => void;
  onCancel: () => void;
  open: boolean;
};

export const DeployAlert: React.FC<DeployAlertProps> = ({
  onConfirm,
  onCancel,
  open,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const [action, setAction] = useState<Action | undefined>();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as Action);
  };

  const handleCancel = () => {
    setAction(undefined);
    onCancel();
  };
  const handleClose = () => {
    action && onConfirm(action);
    setAction(undefined);
  };

  const radioOptions = [
    {
      value: 'deploy',
      label: t('deployments:overview.deployAlert.deployRadio'),
    },
    {
      value: 'download',
      label: t('deployments:overview.deployAlert.downloadRadio'),
    },
  ];

  return (
    <ConfirmAlert
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleCancel}
      onConfirm={handleClose}
      confirmButton={t('general:buttons.ok')}
      cancelButton={t('general:buttons.cancel')}
      closeButton
      options={{ confirmDisabled: !action }}
      title={t('deployments:overview.deployAlert.title')}
      message={t('deployments:overview.deployAlert.message')}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <Divider className={classes.divider} />
      <div className={classes.divider} />
      <DialogContentText>
        {t('deployments:overview.deployAlert.warning')}
      </DialogContentText>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup name="radio-buttons-group" onChange={handleChange}>
            {radioOptions.map((o) => (
              <DialogContentText key={o.value}>
                <FormControlLabel control={<Radio />} {...o} />
              </DialogContentText>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </ConfirmAlert>
  );
};
