import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';

const DashboardIcon = Pages.DASHBOARD.icon;

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Dashboard: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['dashboard', 'general']);
  const { setTitle, setIcon } = useHeader();

  useEffect(() => {
    setTitle({ main: t('dashboard:title') });
    setIcon(DashboardIcon);
  }, [setTitle, setIcon, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader title={t('dashboard:cards.welcome.title')} />
          <CardContent>
            <Typography gutterBottom variant="body1" component="p">
              {t('dashboard:cards.welcome.text')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
