import { ApolloError } from '@apollo/client';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { RequestError } from '../../api/utils/RequestError';
import { getErrorMessage } from '../../utils/helpers';

const useStyles = makeStyles({
  errorMessage: {
    color: 'white',
    backgroundColor: 'red',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 50,
  },
});

export type ErrorMessageProps = {
  error?: Error | ApolloError | RequestError;
  message?: string;
};
export const ErrorMessage: React.FC<ErrorMessageProps> = (input) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);

  return (
    <div className={classes.errorMessage} aria-label="error-message">
      {getErrorMessage(input, t('general:error'))}
    </div>
  );
};
