import { TFunction } from 'i18next';
import * as Entities from '../constants/Entities';
import { BreadcrumbLink } from '../contexts/breadcrumb-context';
import { entityLocation } from './entity';

/**
 * Gets the breadcrumb label for each entity
 *
 * @param entity  The entity's type to get the label for
 * @param t       The translate function from i18next
 */
export const entityBreadcrumbLabel = (
  entity: Entities.Entity,
  t: TFunction,
): string => {
  switch (entity) {
    case Entities.CONFIGURATION:
      return t('general:breadcrumb.configuration');
    case Entities.DEPLOYMENTS:
      return t('general:breadcrumb.deployments');
    case Entities.MONITORING:
      return t('general:breadcrumb.monitoring');
    case Entities.MODELEDITOR:
      return t('general:breadcrumb.modelEditor');
    default:
      throw new Error('Entity is not supported');
  }
};

export const entityBreadcrumbPrefix = (
  entity: Entities.Entity,
  t: TFunction,
): BreadcrumbLink[] => {
  switch (entity) {
    case Entities.CONFIGURATION:
      return [
        {
          title: entityBreadcrumbLabel(Entities.CONFIGURATION, t),
          location: Entities.CONFIGURATION.path,
        },
      ];
    case Entities.DEPLOYMENTS:
      return [
        {
          title: entityBreadcrumbLabel(Entities.DEPLOYMENTS, t),
          location: Entities.DEPLOYMENTS.path,
        },
      ];
    case Entities.MONITORING:
      return [
        {
          title: entityBreadcrumbLabel(Entities.MONITORING, t),
          location: Entities.MONITORING.path,
        },
      ];
    case Entities.MODELEDITOR:
      return [
        {
          title: entityBreadcrumbLabel(Entities.MODELEDITOR, t),
          location: Entities.MODELEDITOR.path,
        },
      ];
    default:
      return [];
  }
};

interface BreadcrumbEntities {
  type: Entities.Entity;
  id: string;
  name: string;
}

/**
 * Constructs the breadcrumb hierarchy for an entity.
 *
 * This function's output should be used directly with `useBreadcrumb` hook.
 *
 * It will always follow the entity relationships and throw an error if they are not
 * matched :
 *
 * ```
 * import * as Entities from "../constants/Entities.ts";
 *
 * makeHierarchyBreadcrumb( [
 *   { type: Entities.ZONE, id: "2", name: "My Zone" },
 *   { type: Entities.DEVICE, id: "1", name: "My Sensor" },
 * ], t);
 *
 * // [
 * //   { title: "My Zone", location: "/zones/2" },
 * //   { title: "My Sensor", location: "/devices/1" },
 * // ]
 * ```
 *
 * ```
 *
 * @param entities          The entities to get the breadcrumb for
 * @param t                 The translate function from i18next library
 */

export const makeHierarchyBreadcrumb = (
  entities: BreadcrumbEntities[],
  t: TFunction,
): BreadcrumbLink[] => {
  if (!entities || entities.length === 0) {
    throw new Error('You need to specify at least one entity');
  }

  return entities.reduce<BreadcrumbLink[]>(
    (links, { type, id, name }, index) => {
      const previousEntity = entities[index - 1];
      if (
        type.parent &&
        previousEntity &&
        previousEntity.type !== type.parent
      ) {
        throw new Error(
          `Entity ${type.debugKey} should have a ${type.parent.debugKey} defined before`,
        );
      }

      const linksWithPrefix = previousEntity
        ? links
        : links.concat(entityBreadcrumbPrefix(type, t));

      return linksWithPrefix.concat([
        { title: name, location: entityLocation(type, id) },
      ]);
    },
    [],
  );
};
