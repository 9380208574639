import React, { useState } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { EmptyBanner } from '../../../components/EmptyBanner';
import {
  DeviceScanStats,
  DeviceScanStatsTable,
} from '../common/DeviceScanStatsTable';
import { MappingStats, MappingStatsTable } from '../common/MappingStatsTable';
import { DeploymentStatusCard } from './DeploymentStatusCard';
import { Loading } from '../../../components/Loading';
import { BasicConfirmAlert } from '../../../components/Alerts/BasicConfirmAlert';
import {
  ObjectScansStats,
  ObjectScanStatsTable,
} from '../common/ObjectScanStatsTable';
import { DeploymentStatus, GatewayStatus } from '../../../api/bacnet/common';
import { LoadingBanner } from '../../../components/LoadingBanner';

const useStyles = makeStyles({
  cardContent: {
    minHeight: 272,
  },
  banner: {
    margin: 'auto',
  },
});

type Deployment = {
  id: string;
  status: DeploymentStatus;
  deviceScan: DeviceScanStats;
  objectScans: ObjectScansStats;
  mapping: MappingStats;
  deployedAt: string;
};

export type DeploymentCardProps = {
  loading?: boolean;
  gatewayStatus?: GatewayStatus;
  gatewayId?: string;
  deployment?: Deployment;
  onViewAllDeployments: () => void;
  onViewDetails: () => void;
  onRestoreMappings: () => void;
};

export const DeploymentCard: React.FC<DeploymentCardProps> = ({
  loading,
  gatewayId,
  gatewayStatus,
  deployment,
  onViewDetails,
  onViewAllDeployments,
  onRestoreMappings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['deployments', 'general']);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const confirmRestoreMappings = () => setShouldConfirm(true);
  const handleCancel = () => setShouldConfirm(false);
  const handleRestoreMappings = () => {
    setShouldConfirm(false);
    onRestoreMappings();
  };

  const isIdle = gatewayStatus === 'idle';
  const isDeploying = gatewayStatus === 'deploying';
  const isOffline = gatewayStatus === 'offline';
  const deviceScanResult = deployment?.deviceScan;
  const objectScanResult = deployment?.objectScans;

  return (
    <>
      <FancyCard
        title={t('deployments:overview.deploymentCard.title')}
        loading={loading}
        actions={
          gatewayId && (
            <>
              <IconButton
                aria-label={`restore-mappings-button`}
                data-testid={`restore-mappings-button`}
                onClick={confirmRestoreMappings}
                size="medium"
                color="primary"
                disabled={!deployment || !isIdle}
              >
                <Tooltip
                  arrow
                  title={
                    t('deployments:overview.deploymentCard.restoreMappings') ||
                    ''
                  }
                  placement="bottom"
                >
                  <Icons.RestorePage fontSize="medium" />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="view-details-button"
                data-testid="view-details-button"
                onClick={onViewDetails}
                size="medium"
                color="primary"
                disabled={!deployment || isDeploying || isOffline}
              >
                <Tooltip
                  arrow
                  title={
                    t('deployments:overview.deploymentCard.viewDetails') || ''
                  }
                  placement="bottom"
                >
                  <Icons.FindInPage fontSize="medium" />
                </Tooltip>
              </IconButton>
              <IconButton
                aria-label="view-all-deployments-button"
                data-testid="view-all-deployments-button"
                onClick={onViewAllDeployments}
                size="medium"
                color="primary"
                disabled={!deployment || isDeploying || isOffline}
              >
                <Tooltip
                  arrow
                  title={
                    t(
                      'deployments:overview.deploymentCard.viewAllDeployments',
                    ) || ''
                  }
                  placement="bottom"
                >
                  <Icons.FormatListBulleted fontSize="medium" />
                </Tooltip>
              </IconButton>
            </>
          )
        }
      >
        <Grid container spacing={2} className={classes.cardContent}>
          {isDeploying && (
            <LoadingBanner
              className={classes.banner}
              description={t('deployments:overview.deploymentCard.isDeploying')}
            />
          )}
          {!isDeploying && loading && <Loading className={classes.banner} />}
          {!isDeploying && !loading && !gatewayId && (
            <EmptyBanner
              className={classes.banner}
              description={t('deployments:overview.deploymentCard.noGateway')}
            />
          )}
          {!isDeploying && !loading && gatewayId && !deployment && (
            <EmptyBanner
              className={classes.banner}
              description={t(
                'deployments:overview.deploymentCard.noDeployment',
              )}
            />
          )}
          {!isDeploying && !loading && gatewayId && deployment && (
            <>
              <Grid item xs={12} lg={4}>
                <DeviceScanStatsTable
                  status={deviceScanResult?.status}
                  startedAt={deviceScanResult?.startedAt}
                  finishedAt={deviceScanResult?.finishedAt}
                  numOfDevices={deviceScanResult?.numOfDevices}
                />
              </Grid>
              <Grid item xs={12} lg={4} id="scan column">
                <ObjectScanStatsTable objectScans={objectScanResult} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <MappingStatsTable stats={deployment?.mapping} />
              </Grid>
              <Grid item xs={12} lg={12}>
                <DeploymentStatusCard
                  status={deployment.status}
                  deployedAt={deployment.deployedAt}
                />
              </Grid>
            </>
          )}
        </Grid>
      </FancyCard>
      <BasicConfirmAlert
        open={shouldConfirm}
        onCancel={handleCancel}
        onConfirm={handleRestoreMappings}
        title={t(
          'deployments:overview.deploymentCard.restoreMappingsAlert.title',
        )}
        message={t(
          'deployments:overview.deploymentCard.restoreMappingsAlert.message',
        )}
      />
    </>
  );
};
