import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataTypeInputDateTime } from './DataTypeInputDateTime';
import { DataTypeInputDate } from './DataTypeInputDate';
import { DataTypeInputString } from './DataTypeInputString';
import { DataTypeInputInteger } from './DataTypeInputInteger';
import { DataTypeInputDecimal } from './DataTypeInputDecimal';
import { DataTypeInputBoolean } from './DataTypeInputBoolean';
import { DataTypeInputDouble } from './DataTypeInputDouble';
import { DataTypeInputFloat } from './DataTypeInputFloat';
import { DataTypes } from '../../__generated__/types';
import { makeOptional } from '../Fields';

interface DataTypeInputProps {
  dataType: DataTypes | null;
  disabled?: boolean;
  value: string;
  id: string;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

export const DataTypeInput: React.FC<DataTypeInputProps> = ({
  dataType,
  disabled,
  value,
  id,
  name,
  label,
  className,
  onChange,
  autoFocus = true,
  error,
  helperText,
  optional,
}) => {
  const { t } = useTranslation(['general']);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    onChange(event.target.value);

  switch (dataType) {
    case DataTypes.Date:
      return (
        <DataTypeInputDate
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Datetime:
      return (
        <DataTypeInputDateTime
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.String:
    case DataTypes.NotSpecified:
      return (
        <DataTypeInputString
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Integer:
      return (
        <DataTypeInputInteger
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Decimal:
      return (
        <DataTypeInputDecimal
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Double:
      return (
        <DataTypeInputDouble
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Float:
      return (
        <DataTypeInputFloat
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    case DataTypes.Bool:
      return (
        <DataTypeInputBoolean
          value={value}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          optional={optional}
        />
      );
    default:
      return (
        <TextField
          fullWidth
          disabled={disabled}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          defaultValue={value}
          id={id}
          name={name}
          label={optional ? makeOptional(t, label) : label}
          inputProps={{ className }}
          onChange={handleChange}
        />
      );
  }
};
