import urljoin from 'url-join';
import { USER_SERVICE_URI } from '../../utils/env';

export enum Language {
  en = 'en',
  de = 'de',
}
export interface UserSettingsEntity {
  language: Language;
}

export const url = (segment: string): string =>
  urljoin(USER_SERVICE_URI, segment);
