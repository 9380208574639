import React from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../Alerts';

export const ErrorBoundary: React.FC = ({ children }) => {
  const { t } = useTranslation(['general']);
  const appInsights = useAppInsightsContext();

  const ErrorFallback: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
  }) => {
    return (
      <ErrorAlert
        title={t('general:errors.unknownError.title')}
        message={t('general:errors.unknownError.message')}
        error={error}
        actions={[
          {
            label: t('general:errors.unknownError.toDashboard'),
            onAction: () => window.location.replace('/'),
          },
          {
            label: t('general:errors.unknownError.retry'),
            onAction: resetErrorBoundary,
          },
        ]}
        showOk={false}
      />
    );
  };

  const reportError = (
    error: Error,
    info: {
      componentStack: string;
    },
  ): void => {
    appInsights.trackException &&
      appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    console.warn(error);
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
      {children}
    </ReactErrorBoundary>
  );
};
