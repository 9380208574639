import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  GridRowModel,
  GridFooterContainer,
  GridToolbarFilterButton,
  GridFooter,
  GridFilterModel,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { FancyCard } from '../../../components/FancyCard';
import { Capability as ModelCapability } from './ModelEditorEditModelCapabilities';
import { DataTypes } from '../../../__generated__/types';
import { DataGrid } from '../../../components/DataGrid';
import { AssignButton } from './AssignButton';

const useStyles = makeStyles({
  card: {
    height: 440,
  },
});

const isMappableFilterModel = {
  items: [
    {
      columnField: 'isMappable',
      id: 2,
      operatorValue: 'is',
      value: 'true',
    },
  ],
};

export type Capability = {
  id: string;
  name: string;
  dataType: DataTypes;
  description?: string;
};

export type ScanData = {
  deviceIdentifier?: string;
  deviceName?: string;
  objectTypeName?: string;
  objectIdentifier?: string;
  objectName?: string;
  description?: string;
  unitName?: string;
  lowLimit?: string;
  highLimit?: string;
  units?: string;
};

type ModelEditorScanProps = {
  loading?: boolean;
  scanData: ScanData[];
  filterCapability?: ModelCapability;
  allCapabilities: Capability[];
  onSelectScanDate?: (capability: Capability, data: ScanData) => void;
};
export const ModelEditorScan: React.FC<ModelEditorScanProps> = ({
  loading,
  scanData,
  filterCapability,
  allCapabilities,
  onSelectScanDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['modeleditor', 'general']);
  const [activeFilterModel, setActiveFilterModel] = useState<GridFilterModel>(
    isMappableFilterModel,
  );

  useEffect(() => {
    const baseItems = (activeFilterModel?.items || []).filter(
      (item) => item.id !== 1,
    );
    const gridFilterModel = filterCapability?.unitName
      ? {
          items: [
            ...baseItems,
            {
              id: 1,
              columnField: 'unitName',
              operatorValue: 'contains',
              value: filterCapability.unitName,
            },
          ],
        }
      : { items: baseItems };
    setActiveFilterModel(gridFilterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCapability]);

  const handleCreateCapability =
    (data: ScanData) => (capability: Capability) => {
      onSelectScanDate && onSelectScanDate(capability, data);
    };

  const columns: GridColumns = [
    {
      field: 'createCapability',
      headerName: t('modeleditor:edit.scan.headers.createCapability'),
      description: t(
        'modeleditor:edit.scan.headers.createCapabilityDescription',
      ),
      renderCell: (params: GridRenderCellParams) => (
        <AssignButton
          allCapabilities={params.row.allCapabilities}
          onCreateCapability={params.row.onCreateCapability}
        />
      ),
      width: 50,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
    },
    {
      field: 'description',
      headerName: t('modeleditor:edit.scan.headers.description'),
      description: t('modeleditor:edit.scan.headers.descriptionDescription'),
      flex: 1,
    },
    {
      field: 'deviceIdentifier',
      headerName: t('modeleditor:edit.scan.headers.deviceIdentifier'),
      description: t(
        'modeleditor:edit.scan.headers.deviceIdentifierDescription',
      ),
      hide: true,
      flex: 1,
    },
    {
      field: 'deviceName',
      headerName: t('modeleditor:edit.scan.headers.deviceName'),
      description: t('modeleditor:edit.scan.headers.deviceNameDescription'),
      flex: 1,
    },
    {
      field: 'objectTypeName',
      headerName: t('modeleditor:edit.scan.headers.objectTypeName'),
      description: t('modeleditor:edit.scan.headers.objectTypeNameDescription'),
      flex: 1,
    },
    {
      field: 'objectName',
      headerName: t('modeleditor:edit.scan.headers.objectName'),
      description: t('modeleditor:edit.scan.headers.objectNameDescription'),
      flex: 1,
    },
    {
      field: 'objectIdentifier',
      headerName: t('modeleditor:edit.scan.headers.objectIdentifier'),
      description: t(
        'modeleditor:edit.scan.headers.objectIdentifierDescription',
      ),
      hide: true,
      flex: 1,
    },
    {
      field: 'presentValue',
      headerName: t('deployments:common.scanResult.headers.presentValue'),
      description: t(
        'deployments:common.scanResult.headers.presentValueDescription',
      ),
      align: 'right',
      flex: 1,
    },
    {
      field: 'unitName',
      headerName: t('modeleditor:edit.scan.headers.unitName'),
      description: t('modeleditor:edit.scan.headers.unitNameDescription'),
      flex: 1,
    },
    {
      field: 'lowLimit',
      headerName: t('modeleditor:edit.scan.headers.lowLimit'),
      description: t('modeleditor:edit.scan.headers.lowLimitDescription'),
      type: 'number',
      hide: true,
      flex: 1,
    },
    {
      field: 'highLimit',
      headerName: t('modeleditor:edit.scan.headers.highLimit'),
      description: t('modeleditor:edit.scan.headers.highLimitDescription'),
      type: 'number',
      hide: true,
      flex: 1,
    },
    {
      field: 'isMappable',
      headerName: t('deployments:common.scanResult.headers.isMappable'),
      description: t(
        'deployments:common.scanResult.headers.isMappableDescription',
      ),
      type: 'boolean',
      hide: true,
      flex: 1,
    },
  ];

  const rows =
    scanData.map(
      (el, id) =>
        ({
          id,
          ...el,
          unitName: el.unitName || el.units || '-',
          allCapabilities,
          onCreateCapability: onSelectScanDate && handleCreateCapability(el),
        } as GridRowModel),
    ) || [];

  return (
    <FancyCard
      title={t('modeleditor:edit.scan.title')}
      className={classes.card}
    >
      <DataGrid
        loading={loading}
        height={360}
        filterModel={activeFilterModel}
        components={{
          Toolbar: () => (
            <GridFooterContainer>
              <GridToolbarFilterButton />
              <GridFooter />
            </GridFooterContainer>
          ),
        }}
        rows={rows}
        columns={columns}
        onFilterModelChange={(model) => setActiveFilterModel(model)}
        disableSelectionOnClick
        hideFooter
      />
    </FancyCard>
  );
};
