import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { APPLICATIONINSIGHTS_CONNECTION_STRING } from './utils/env';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights: ApplicationInsights | Record<string, never> =
  APPLICATIONINSIGHTS_CONNECTION_STRING
    ? new ApplicationInsights({
        config: {
          connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
        },
      })
    : {};

if (appInsights.loadAppInsights) {
  appInsights.loadAppInsights();
}
export { reactPlugin, appInsights };
