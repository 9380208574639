import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import * as Pages from '../../constants/Pages';
import { useHeader } from '../../contexts/header-context';
import { MonitoringOverviewContainer } from './overview/MonitoringOverviewContainer';
import { NotFound } from '../errorScreens';

const Icon = Pages.DEPLOYMENTS.icon;

export const MonitoringRoutes: React.FC = () => {
  const { setIcon } = useHeader();

  useEffect(() => {
    setIcon(Icon);
  }, [setIcon]);

  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute element={<MonitoringOverviewContainer />} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
