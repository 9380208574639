import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  DialogTitle as MaterialDialogTitle,
  DialogTitleProps as MaterialDialogTitleProps,
  IconButton,
  Theme,
  useTheme,
} from '@mui/material';
import * as Icons from '@mui/icons-material';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: (theme: Theme) => theme.spacing(1),
    top: (theme: Theme) => theme.spacing(1),
  },
});

export type ConfirmAlertProps = { closeButton?: boolean; onClose: () => void };

type DialogTitleProps = MaterialDialogTitleProps & {
  closeButton: boolean;
  onClose: () => void;
};

export const DialogTitle: React.FC<DialogTitleProps> = ({
  children,
  closeButton,
  onClose,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MaterialDialogTitle {...other}>
      {children}
      {closeButton && (
        <IconButton
          aria-label="close-button"
          data-testid="close-button"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Icons.Close />
        </IconButton>
      )}
    </MaterialDialogTitle>
  );
};
