import React from 'react';
import {
  TextField,
  Autocomplete as Component,
  AutocompleteProps as ComponentProps,
} from '@mui/material';

interface AutocompleteOption {
  id: number | string;
  label: string;
}

export interface AutocompleteProps
  extends Omit<
    ComponentProps<number | string, false, true, false>,
    'options' | 'getOptionLabel' | 'renderInput'
  > {
  label?: string;
  options: AutocompleteOption[];
  error?: boolean;
  helperText?: React.ReactNode;
}

/**
 * Autocomplete
 *
 * Pre-configured autocomplete to be used in our forms. It's a simple wrapper around
 * Material UI Lab's Autocomplete.
 *
 * It uses pre-define sane configured options
 *
 * @param label      The label of the field to be set
 * @param options    The options to be used
 * @param error      The error state of the underlying input field
 * @param helperText The helper text of the underlying input field
 * @param props      The rest of the props to pass to the Autocomplete
 */
export const Autocomplete = React.forwardRef<unknown, AutocompleteProps>(
  ({ label, options, error, helperText, ...props }, ref) => (
    <Component
      {...props}
      ref={ref}
      options={options.map((option) => option.id)}
      autoHighlight
      disableClearable
      getOptionLabel={(id) =>
        options.find((option) => option.id === id)?.label || ''
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          InputLabelProps={{ shrink: true }}
          helperText={helperText}
        />
      )}
    />
  ),
);

Autocomplete.displayName = 'Autocomplete';
