import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { ErrorAlert } from '../../../../components/Alerts';
import * as Entities from '../../../../constants/Entities';
import {
  DataTypes,
  DeviceCreateAttributeValueType,
  useGatewayCreateGatewayModelsQuery,
  useGatewayCreateWithPlacementMutation,
  PlacementKind,
} from '../../../../__generated__/types';
import { useBreadcrumb } from '../../../../contexts/breadcrumb-context';
import { makeHierarchyBreadcrumb } from '../../../../utils/breadcrumb';
import { useHeader } from '../../../../contexts/header-context';
import { GatewayCreate, GatewayCreateData } from './GatewayCreate';

export const GatewayCreateContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['configuration', 'general']);
  const location = useLocation();
  const { setTitle, setLoading } = useHeader();

  const {
    loading: loadingDeviceModelsAndSites,
    error: errorDeviceModelsAndSites,
    data: dataDeviceModelsAndSites,
  } = useGatewayCreateGatewayModelsQuery();
  const gatewayModels = dataDeviceModelsAndSites?.deviceModels;
  const allSites = dataDeviceModelsAndSites?.sites || [];

  const allGatewayModels = (gatewayModels || []).map((dm) => ({
    id: dm.id,
    name: dm.name,
    deviceModelAttributeSets: dm.deviceModelAttributeSets.map(
      (attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        attributes: attributeSet.mappingAttributeDeviceModelAttributeSets.map(
          (attribute) => ({
            id: attribute.attribute.id,
            name: attribute.attribute.name,
            required: attribute.attribute.required || false,
            dataType: attribute.attribute.dataType,
            value:
              attribute.attribute.required &&
              attribute.attribute.dataType === DataTypes.Bool
                ? 'false'
                : '',
          }),
        ),
      }),
    ),
  }));

  const hierarchy = makeHierarchyBreadcrumb(
    [
      {
        type: Entities.CONFIGURATION,
        id: 'newGateway',
        name: t('configuration:gateway.create.breadcrumb'),
      },
    ],
    t,
  );
  useBreadcrumb(hierarchy);

  useEffect(() => {
    setTitle({ main: t('configuration:gateway.create.breadcrumb') });
    setLoading(loadingDeviceModelsAndSites);
  }, [setTitle, t, setLoading, loadingDeviceModelsAndSites]);

  const navigateBack = () => {
    navigate(
      location.pathname.substring(0, location.pathname.lastIndexOf('/')),
    );
  };
  const [create, { loading: loadingCreate, error: errorCreate }] =
    useGatewayCreateWithPlacementMutation({
      onCompleted: navigateBack,
    });

  const handleCreate = (inputData: GatewayCreateData) => {
    const attributeValues: DeviceCreateAttributeValueType[] =
      inputData.attributeSets
        .map((set) =>
          set.attributes
            .filter((attribute) => attribute.required || attribute.value !== '')
            .map((attribute) => ({
              attributeId: attribute.id,
              value: attribute.value || '',
            })),
        )
        .flat();
    create({
      variables: {
        device: {
          name: inputData.name,
          description: inputData.description,
          deviceModelId: inputData.deviceModelId,
        },
        placementId: inputData.locationId,
        placementType: inputData.locationType || PlacementKind.Zone,
        attributeValues,
      },
    }).catch(console.warn);
  };

  return (
    <>
      <GatewayCreate
        loading={loadingDeviceModelsAndSites || loadingCreate}
        allSites={allSites}
        allGatewayModels={allGatewayModels}
        onSubmit={handleCreate}
        onDiscard={navigateBack}
      />
      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={errorDeviceModelsAndSites || errorCreate}
      />
    </>
  );
};
