import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import { url, UserSettingsEntity } from './common';
import { EntityResponse } from '../common';

export type UserSettings = UserSettingsEntity;

export const getUserSettings = async (): Promise<
  Response<EntityResponse<UserSettings>>
> => {
  return request<EntityResponse<UserSettings>>(
    'GET',
    url('/users/me/settings'),
  );
};
