import React from 'react';
import { IconButton, Menu, MenuItem, MenuList, Tooltip } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { DataTypes } from '../../../__generated__/types';

const useStyles = makeStyles({
  dense: {
    padding: 0,
  },
  list: {
    padding: 0,
    maxHeight: 200,
  },
  tooltipContent: {
    display: 'flex',
    padding: 0,
    fontSize: '14px',
    lineHeight: '16px',
    flexDirection: 'column',
  },
  tooltipItem: {
    padding: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
});

type Capability = {
  id: string;
  name: string;
  dataType: DataTypes;
  description?: string;
};

type AssignButtonProps = {
  allCapabilities: Capability[];
  onCreateCapability?: (capability: Capability) => void;
};

export const AssignButton: React.FC<AssignButtonProps> = ({
  allCapabilities,
  onCreateCapability,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['modeleditor', 'general']);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // ToDo: Filter Capabilities by matching datatypes needs unit -> datatype .filter(c=>c.dataType)
  const relevantCapabilities = allCapabilities;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleCreateCapability = (capability: Capability) => () => {
    onCreateCapability && onCreateCapability(capability);
    handleClose();
  };

  if (!onCreateCapability) return null;
  return (
    <>
      <IconButton
        aria-label="assign-button"
        data-testid="assign-button"
        onClick={handleClick}
        className={classes.dense}
      >
        <Icons.AddBox fontSize="medium" color="action" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        classes={{
          root: classes.dense,
          list: classes.list,
        }}
      >
        <MenuList dense>
          {relevantCapabilities.map((c) => (
            <Tooltip
              key={c.id}
              arrow
              placement="right"
              title={
                (c.description || c.dataType) && (
                  <div className={classes.tooltipContent}>
                    {c.description && (
                      <div className={classes.row}>
                        <div className={classes.tooltipItem}>
                          {c.description}
                        </div>
                      </div>
                    )}
                    {c.dataType && (
                      <div className={classes.row}>
                        <div className={classes.tooltipItem}>
                          {t('modeleditor:edit.scan.capabilities.dataType')}
                        </div>
                        <div className={classes.tooltipItem}>{c.dataType}</div>
                      </div>
                    )}
                  </div>
                )
              }
            >
              <MenuItem onClick={handleCreateCapability(c)}>{c.name}</MenuItem>
            </Tooltip>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};
