import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton } from '@mui/material';
import classNames from 'classnames';
import { FancyCard } from '../../../components/FancyCard';
import { Color } from '../../../constants/Colors';
import { CapabilityInfoIcon } from './CapabilityInfoIcon';

const useStyles = makeStyles({
  card: {
    height: 440,
  },
  list: {
    height: 360,
    overflow: 'auto',
    fontSize: '14px',
  },
  empty: {
    padding: '16px',
  },
  active: {
    backgroundColor: Color.selectedRow,
  },
  assigned: {
    backgroundColor: Color.assignedRow,
  },
  listButton: {
    margin: 2,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
});

type Mapping = {
  bacnetDeviceId: string;
  bacnetObjectId: string;
};
export type Capability = {
  id: string;
  name: string;
  fieldSelector: string;
  technicalMin?: number;
  technicalMax?: number;
  unitName: string;
  unitSymbol: string;
  active?: boolean;
  assigned?: Mapping;
};

interface DeviceCapabilitiesProps {
  loading?: boolean;
  capabilities?: Capability[];
  selectedCapabilityId?: string;
  onSelectCapability: (capability?: Capability) => void;
}

export const DeviceCapabilities: React.FC<DeviceCapabilitiesProps> = ({
  loading,
  capabilities,
  selectedCapabilityId,
  onSelectCapability,
}) => {
  const { t } = useTranslation(['deployments', 'general']);
  const classes = useStyles();

  const handleSelectCapability = (capability: Capability) => () => {
    const newSelectedCapability =
      capability.id === selectedCapabilityId ? undefined : capability;
    onSelectCapability(newSelectedCapability);
  };

  return (
    <FancyCard
      title={t('deployments:common.deviceCapabilities.title')}
      loading={loading}
      className={classes.card}
    >
      <List className={classes.list}>
        {capabilities?.length ? (
          capabilities.map((capability) => (
            <ListItemButton
              key={capability.id}
              className={classNames(
                classes.listButton,
                capability.assigned && !capability.active && classes.assigned,
                capability.active && classes.active,
              )}
              onClick={handleSelectCapability(capability)}
            >
              <ListItem className={classes.row}>
                {capability.name}
                <CapabilityInfoIcon capability={capability} />
              </ListItem>
            </ListItemButton>
          ))
        ) : (
          <div className={classes.empty}>
            {t('deployments:common.deviceCapabilities.empty')}
          </div>
        )}
      </List>
    </FancyCard>
  );
};
