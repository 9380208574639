import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import {
  GridEnrichedColDef,
  GridFooter,
  GridRowModel,
  GridFooterContainer,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  GridSelectionModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import { DataGrid } from '../../../components/DataGrid';
import { formatDate } from '../../../utils/helpers';
import { ScanStatus } from '../../../api/bacnet/common';
import { translateScanStatus } from '../common/statusTranslation';

export type Device = {
  id: string;
  scan: null | {
    status: ScanStatus;
    startedAt: string;
    finishedAt?: string;
    numOfObjects: number;
    error: null | string;
  };
  details?: {
    objectIdentifier: string;
    objectName: string;
    vendorName: string;
    modelName: string;
    firmwareRevision: string;
    applicationSoftwareVersion: string;
    location: string;
    description: string;
  };
};

export type ObjectScanTableProps = {
  loading?: boolean;
  devices?: Device[];
  onSelect: (deviceIds: string[]) => void;
  onDelete: (deviceId: string) => void;
};

const columnDetails: Partial<GridEnrichedColDef> = {
  flex: 1,
};

export const ObjectScanTable: React.FC<ObjectScanTableProps> = ({
  loading,
  devices,
  onSelect,
  onDelete,
}) => {
  const { t } = useTranslation(['discovery', 'general']);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelection = (selection: GridSelectionModel) => {
    setSelectionModel(selection);
    const deviceIds = selection.map((d) => d.toString());
    onSelect(deviceIds);
  };

  const handleDelete =
    (deviceId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onDelete(deviceId);
    };

  const columns = [
    {
      field: 'objectName',
      headerName: t('discovery:objectScanTable.headers.device'),
      ...columnDetails,
    },
    {
      field: 'objectIdentifier',
      headerName: t('discovery:objectScanTable.headers.deviceId'),
      ...columnDetails,
    },
    {
      field: 'modelName',
      headerName: t('discovery:objectScanTable.headers.modelName'),
      ...columnDetails,
    },
    {
      field: 'description',
      headerName: t('discovery:objectScanTable.headers.description'),
      ...columnDetails,
    },
    {
      field: 'startedAt',
      headerName: t('discovery:objectScanTable.headers.scanStartedAt'),
      ...columnDetails,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? formatDate(String(params.value)) : '-',
    },
    {
      field: 'finishedAt',
      headerName: t('discovery:objectScanTable.headers.scanFinishedAt'),
      ...columnDetails,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? formatDate(String(params.value)) : '-',
    },
    {
      field: 'status',
      headerName: t('discovery:objectScanTable.headers.scanStatus'),
      ...columnDetails,
      valueFormatter: (params: GridValueFormatterParams) =>
        translateScanStatus(
          params.value ? (params.value as ScanStatus) : undefined,
          t,
        ),
    },
    {
      field: 'numOfObjects',
      headerName: t('discovery:objectScanTable.headers.numOfObjects'),
      ...columnDetails,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => {
        if (!params.id) return params.value;
        const status = params.api.getCellValue(params.id, 'status');
        const isCompleted = status === 'completed';
        return isCompleted ? params.value : '-';
      },
    },
    {
      field: 'delete',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => {
        const isCompleted = params.row.status === 'completed';
        if (!isCompleted) return null;
        return (
          <IconButton
            aria-label="delete-button"
            data-testid="delete-button"
            onClick={handleDelete(params.row.deviceId)}
          >
            <Icons.Delete fontSize="small" />
          </IconButton>
        );
      },
      width: 50,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
    },
  ];
  const rows = useMemo(
    () =>
      loading || !devices
        ? []
        : devices.map(
            (device) =>
              ({
                ...device.details,
                ...(device.scan || {}),
                id: device.id,
              } as GridRowModel),
          ),
    [devices, loading],
  );

  return (
    <DataGrid
      loading={loading}
      components={{
        Toolbar: () => (
          <GridFooterContainer>
            <GridToolbarFilterButton />
            <GridFooter />
          </GridFooterContainer>
        ),
      }}
      rows={rows}
      columns={columns}
      onSelectionModelChange={handleSelection}
      selectionModel={selectionModel}
      hideFooter
      checkboxSelection
    />
  );
};
