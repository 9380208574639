import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { usePrepareRequest } from '../../../hooks/useRequest';
import * as API from '../../../api/bacnet';
import { DeploymentCard } from './DeploymentCard';
import { entityLocation } from '../../../utils/entity';
import * as Entities from '../../../constants/Entities';
import { ErrorAlert } from '../../../components/Alerts';
import { GatewayStatus } from '../../../api/bacnet/common';

type DeploymentCardContainerProps = {
  loading?: boolean;
  gatewayId?: string;
  gatewayStatus?: GatewayStatus;
  refreshGetGatewayDetails: () => void;
};

export const DeploymentCardContainer: React.FC<
  DeploymentCardContainerProps
> = ({ loading, gatewayId, refreshGetGatewayDetails, gatewayStatus }) => {
  const { t } = useTranslation(['deployments', 'general']);
  const navigate = useNavigate();

  const [deployment, setDeployment] = useState<API.Deployment>();

  const [
    callGetLatestSuccessfulDeployment,
    { error: errorGetLatestSuccessfulDeployment },
  ] = usePrepareRequest(API.getLatestSuccessfulDeployment, {
    onCompleted: (result) => {
      setDeployment(result?.data);
    },
  });

  const [
    callRestoreMappings,
    { loading: loadingRestoreMappings, error: errorRestoreMappings },
  ] = usePrepareRequest(API.restoreMappingsFromDeployment, {
    onCompleted: () => refreshGetGatewayDetails(),
  });

  useEffect(() => {
    setDeployment(undefined);
    if (gatewayId) {
      callGetLatestSuccessfulDeployment(gatewayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId, gatewayStatus]);

  const handleViewAllDeployments = () => {
    navigate(entityLocation(Entities.DEPLOYMENTS, `list/${gatewayId}`));
  };
  const handleViewDetails = () => {
    navigate(
      entityLocation(
        Entities.DEPLOYMENTS,
        `list/${gatewayId}/${deployment?.id}`,
      ),
    );
  };

  const handleRestoreMappings = () => {
    if (gatewayId && deployment) callRestoreMappings(gatewayId, deployment.id);
  };

  return (
    <>
      <DeploymentCard
        loading={loading || loadingRestoreMappings}
        gatewayStatus={gatewayStatus}
        gatewayId={gatewayId}
        deployment={deployment}
        onViewAllDeployments={handleViewAllDeployments}
        onViewDetails={handleViewDetails}
        onRestoreMappings={handleRestoreMappings}
      />

      <ErrorAlert
        title={t('general:errorAlert.title')}
        message={t('general:errorAlert.message')}
        error={
          (errorGetLatestSuccessfulDeployment?.responseCode !== 'NOT_FOUND' &&
            errorGetLatestSuccessfulDeployment) ||
          errorRestoreMappings
        }
      />
    </>
  );
};
