import { TFunction } from 'i18next';
import { Page } from './Pages';
import * as Pages from './Pages';

export const DEFAULT_PAGE_SIZE = 15;
export const SIDEBAR_OPENED_WIDTH = 280;
export const SIDEBAR_CLOSED_WIDTH = 75;

interface SidebarSection {
  id: string;
  label: (t: TFunction) => string;
  items: Page[];
}

export const SIDEBAR_SECTIONS: SidebarSection[] = [
  {
    id: 'essentials',
    label: (t) => t('general:sidebar.section.essentials'),
    items: [Pages.DASHBOARD],
  },
  {
    id: 'device',
    label: (t) => t('general:sidebar.section.device'),
    items: [Pages.CONFIGURATION, Pages.DEPLOYMENTS, Pages.MONTIORING],
  },
  {
    id: 'tools',
    label: (t) => t('general:sidebar.section.tools'),
    items: [Pages.MODELEDITOR],
  },
];
