import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import * as Entities from '../../../constants/Entities';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { entityBreadcrumbLabel } from '../../../utils/breadcrumb';
import { useHeader } from '../../../contexts/header-context';
import * as Pages from '../../../constants/Pages';
import { GatewayListContainer } from '../../../components/GatewayList';
import { ConfigurationOverviewConfigContainer } from './ConfigurationOverviewConfigContainer';

export const ConfigurationOverviewContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['configuration', 'general']);
  const { setTitle, setIcon } = useHeader();

  const [gatewayId, setGatewayId] = useState<string>();

  useBreadcrumb({
    title: entityBreadcrumbLabel(Entities.CONFIGURATION, t),
    location: Entities.CONFIGURATION.path,
  });
  useEffect(() => {
    setTitle({ main: t('configuration:select.title') });
    setIcon(Pages.DASHBOARD.icon);
  }, [setTitle, setIcon, t]);

  const handleSelectGateway = (id?: string) => {
    setGatewayId(id);
  };
  const handleAddGateway = () => {
    navigate(`newGateway`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <GatewayListContainer
          selectedId={gatewayId}
          onSelect={handleSelectGateway}
          onAdd={handleAddGateway}
        />
      </Grid>
      <Grid item xs={12}>
        <ConfigurationOverviewConfigContainer gatewayId={gatewayId} />
      </Grid>
    </Grid>
  );
};
