import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export type MappingStats = {
  total?: number;
  new?: number;
  changed?: number;
  removed?: number;
} | null;
export type MappingStatsTableProps = {
  stats?: MappingStats;
};

export const MappingStatsTable: React.FC<MappingStatsTableProps> = ({
  stats,
}) => {
  const { t } = useTranslation(['deployments', 'general']);

  const renderValue = (v?: number) => (v || v === 0 ? v : '-');
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {t('deployments:common.mappingTable.header')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('deployments:common.mappingTable.total')}</TableCell>
          <TableCell align="right">{renderValue(stats?.total)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('deployments:common.mappingTable.new')}</TableCell>
          <TableCell align="right">{renderValue(stats?.new)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('deployments:common.mappingTable.changed')}</TableCell>
          <TableCell align="right">{renderValue(stats?.changed)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('deployments:common.mappingTable.removed')}</TableCell>
          <TableCell align="right">{renderValue(stats?.removed)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
