import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { Loading } from '../Loading';

const useStyles = makeStyles({
  banner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loading: {
    width: 50,
    height: 50,
    marginTop: '12px',
    marginBottom: '12px',
  },
});

interface LoadingBannerProps {
  className?: string;
  description: string;
}

export const LoadingBanner: React.FC<LoadingBannerProps> = ({
  className,
  description,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.banner, className)}>
      <Loading className={classes.loading} />
      <Typography variant="subtitle1">{description}</Typography>
    </div>
  );
};
