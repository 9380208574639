export interface Entity {
  debugKey: string;
  path: string;
  parent?: Entity;
}

export const CONFIGURATION: Entity = {
  debugKey: 'Configuration',
  path: `/configuration`,
};
export const DEPLOYMENTS: Entity = {
  debugKey: 'Deployments',
  path: `/deployments`,
};
export const MONITORING: Entity = {
  debugKey: 'Monitoring',
  path: `/Monitoring`,
};
export const MODELEDITOR: Entity = {
  debugKey: 'Model Editor',
  path: `/model-editor`,
};
